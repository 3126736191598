<template lang="pug">
.application-customer-history.animated.fadeIn
  customer-history.mt-4(store-module  = 'applications',
                        history-state = 'applicationCustomerHistory')
</template>

<script>
import CustomerHistory from '@/views/customers/CustomerHistory'

export default {
  name: 'application-customer-history',

  components: { CustomerHistory }
}
</script>

<style scoped>

</style>
