<template lang="pug">
.application-customer-comment.card
  .card-header {{ $t('customerComment') }}
  .card-body
    p(v-for = 'customerComment in application.customerComments') {{ customerComment.comment }}
    p(v-if = 'application.customerComments.length === 0') {{ $t('noComment') }}
</template>


<script>
import { mapState } from 'vuex'

export default {
  name: 'application-customer-comment',

  i18nOptions: {},

  computed: mapState('applications', ['application'])
}
</script>


<i18n>
en:
  customerComment: "Client's comment"
  noComment:       "No comment."

et:
  customerComment: "Client's comment"
  noComment:       "No comment."

ru:
  customerComment: "Client's comment"
  noComment:       "No comment."
</i18n>
