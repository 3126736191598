<template lang = "pug">
.application-offer-terms-data.card.border-right-none
  .card-header.title-blok-form.title-text-form {{ $t('applicationData') }}
  .card-body.card-body--btm-border.botm
    form.form-horizontal(@submit.prevent = 'onSubmit')
        fi-data-field(
          :label = '$t("income")'
        )
          //- span.text-end {{ offerTermsData.applicationIncome | money }}
          span.text-end {{ getSumApplicationIncome | money }}
        fi-data-field(
          :label = '$t("obligations")'
        )
          //- span.text-end {{ offerTermsData.applicationObligations | money }}
          span.text-end {{ getSumApplicationObligations | money }}
        fi-data-field(
          :label = '$t("expences")'
        )
          //- span.text-end {{ offerTermsData.applicationExpenses | money }}
          span.text-end {{ getSumApplicationExpenses| money }}
        fi-data-field(
          :label = '$t("obligationsIncomeRatio")'
        )
          span.text-end {{ offerTermsData.applicationObligationsIncomeRatio + ' %' }}
  .card-body.card-body--btm-border.botm
    form.form-horizontal(@submit.prevent = 'onSubmit')
        fi-data-field(
          :label = '$t("originalAppliedAmount")'
        )
          span.text-end {{ offerTermsData.originalAppliedAmount | money  }}
        fi-data-field(
          :label = '$t("period")'
        )
          span.text-end {{ offerTermsData.originalAppliedPeriod }}
        fi-data-field(
          :label = '$t("monthlyPayment")'
        )
          span.text-end {{ offerTermsData.originalAppliedMonthlyPayment | money}}
  <!----------------------------------------------------------------------------------------------------------->
  .card-header.title-blok-form.title-text-form {{ $t('offeredLoan') }}
  .card-body
    form.form-horizontal(@submit.prevent = 'onSubmit')
      template(v-if = 'editing')
        fi-data-field(
          :label = '$t("creditAmount")'
        )
          fi-money-input.form-control.form-control-sm.text-position-end(v-model = 'offerTermsData.creditAmount')
        fi-data-field(
          :label = '$t("period")'
        )
          input.form-control.form-control-sm.text-position-end(v-model = 'offerTermsData.loanLength')
        fi-data-field(
          :label = '$t("monthlyPayment")'
        )
          span.text-end {{ offerTermsData.monthlyPayment | money }}
        fi-form-field(
          v-if   = '!feeDisabled(agreementFee)'
          :label = '$t("agreementFee")'
          :state = '!$v.offerTermsData.agreementFee.$error'
        )
          template(#error)
            .form-row
              .col
              .col
                .error-message(v-if = '!$v.offerTermsData.agreementFee.required') {{ $t('common:required') }}
                .error-message(v-if = '!$v.offerTermsData.agreementFee.gte') {{ $t('gte', { value: $v.offerTermsData.agreementFee.$params.gte.value }) }}
                .error-message(v-if = '!$v.offerTermsData.agreementFee.lte') {{ $t('lte', { value: $v.offerTermsData.agreementFee.$params.lte.value }) }}
          .form-row
            .col: fi-select(
              v-model.number = 'offerTermsData.agreementFeeMethodId'
              :options       = 'agreementFeeCalculationTypes'
              :disabled      = '!agreementFee.feeOverride'
              sm
              required
            )
            .col
              percent-input.form-control.form-control-sm(
                v-if           = 'percentageAgreementFee'
                v-model.number = 'offerTermsData.agreementFee'
                :class         = '{ "is-invalid": $v.offerTermsData.agreementFee.$error }'
                :disabled      = '!feeEditable(agreementFee)'
              )
              fi-money-input.form-control.form-control-sm(
                v-else
                v-model.number = 'offerTermsData.agreementFee'
                :class         = '{ "is-invalid": $v.offerTermsData.agreementFee.$error }'
                :disabled      = '!feeEditable(agreementFee)'
              )

        fi-form-field(
          v-if   = '!feeDisabled(commitmentFee)'
          :label = '$t("commitmentFee")'
          :state = '!$v.offerTermsData.commitmentFee.$error'
        )
          template(#error)
            .form-row
              .col
              .col
                .error-message(v-if = '!$v.offerTermsData.commitmentFee.required') {{ $t('common:required') }}
                .error-message(v-if = '!$v.offerTermsData.commitmentFee.gte') {{ $t('gte', { value: $v.offerTermsData.commitmentFee.$params.gte.value }) }}
                .error-message(v-if = '!$v.offerTermsData.commitmentFee.lte') {{ $t('lte', { value: $v.offerTermsData.commitmentFee.$params.lte.value }) }}
          .form-row
            .col: fi-select(
              v-model.number = 'offerTermsData.commitmentFeeMethodId'
              :options       = 'commitmentFeeCalculationTypes'
              :disabled      = '!commitmentFee.feeOverride'
              sm
              required
            )
            .col
              percent-input.form-control.form-control-sm(
                v-if           = 'percentageCommitmentFee'
                v-model.number = 'offerTermsData.commitmentFee'
                :class         = '{ "is-invalid": $v.offerTermsData.commitmentFee.$error }'
                :disabled      = '!feeEditable(commitmentFee)'
              )
              fi-money-input.form-control.form-control-sm(
                v-else
                v-model.number = 'offerTermsData.commitmentFee'
                :class         = '{ "is-invalid": $v.offerTermsData.commitmentFee.$error }'
                :disabled      = '!feeEditable(commitmentFee)'
              )

        fi-form-field(
          :label = '$t("interestRate")'
          :state = '!$v.offerTermsData.interestRate.$error'
        )
          template(#error)
            .error-message(v-if = '!$v.offerTermsData.interestRate.required') {{ $t('common:required') }}
            .error-message(v-if = '!$v.offerTermsData.interestRate.gte') {{ $t('gte', { value: applicationProduct.interestRateMin }) }}
            .error-message(v-if = '!$v.offerTermsData.interestRate.lte') {{ $t('lte', { value: applicationProduct.interestRateMax }) }}
          .form-row
            .col: percent-input.form-control.form-control-sm(
              v-model.number = 'offerTermsData.interestRate'
              :class         = '{ "is-invalid": $v.offerTermsData.interestRate.$error }'
              :disabled      = 'applicationProduct.interestRateMin === applicationProduct.interestRateMax'
            )
            .col: fi-select(
              v-model   = 'variableInterestIdGetter'
              :options  = 'interestRatesDataValues'
              :disabled = '!interestRatesData.length'
              :required = 'true'
              sm
            )
            .col: percent-input.form-control.form-control-sm(
              v-model.number = 'variableInterestRate'
              type           = 'text'
              disabled
              :decimalLimit  = 4
            )
            .col: div.cell-center {{ 'Σ ' }} {{ (offerTermsData.interestRate + variableInterestRate) || offerTermsData.interestRate | percent }}

        fi-form-field(
          :label = '$t("penaltyRate")'
          :state = '!$v.offerTermsData.penaltyInterestRate.$error'
        )
          template(#error)
            .error-message(v-if = '!$v.offerTermsData.penaltyInterestRate.required') {{ $t('common:required') }}
            .error-message(v-if = '!$v.offerTermsData.penaltyInterestRate.gte') {{ $t('gte', { value: applicationProduct.penaltyInterestRateMin }) }}
            .error-message(v-if = '!$v.offerTermsData.penaltyInterestRate.lte') {{ $t('lte', { value: applicationProduct.penaltyInterestRateMax }) }}
          percent-input.form-control.form-control-sm(
            v-model.number = 'offerTermsData.penaltyInterestRate'
            :class         = '{ "is-invalid": $v.offerTermsData.penaltyInterestRate.$error }'
            :disabled      = 'applicationProduct.penaltyInterestRateMin === applicationProduct.penaltyInterestRateMax'
          )

        fi-form-field(:label = '$t("specialTerms")')
          fi-rich-text-editor(v-model.trim   = 'offerTermsData.specialTerms')
      template(v-else)
        fi-data-field(
          :label = '$t("creditAmount")'
        )
          span.text-end {{ offerTermsData.creditAmount | money }}
        fi-data-field(
          :label = '$t("period")'
        )
          span.text-end {{ offerTermsData.loanLength }}
        fi-data-field(
          :label = '$t("monthlyPayment")'
        )
          span.text-end {{ offerTermsData.monthlyPayment | money }}
        fi-data-field(
          v-if   = '!feeDisabled(agreementFee)'
          :label = '$t("agreementFee")'
        )
          template(v-if = 'percentageAgreementFee')
            .form-row
             .col {{ offerTermsData.agreementFee | percent }}
             .col {{ agreementFee.actualAmount | money }}
          template(v-else) {{ offerTermsData.agreementFee | money }}
        fi-data-field(
          v-if   = '!feeDisabled(commitmentFee)'
          :label = '$t("commitmentFee")'
        )
          template(v-if = 'percentageCommitmentFee')
            .form-row
              .col {{ offerTermsData.commitmentFee | percent }}
              .col {{ commitmentFee.actualAmount | money }}
          template(v-else) {{ offerTermsData.commitmentFee | money }}
        fi-data-field(:label = '$t("interestRate")')
          .form-row
            .col {{ application.interestRate | percent }}
            .col {{ variableInterestName }}
            .col {{ application.variableInterestRate || 0 | percent('0.000 %') }}
            .col {{ 'Σ ' }} {{ (application.interestRate + application.variableInterestRate) || application.interestRate | percent('0.000 %') }}
        fi-data-field(:label = '$t("penaltyRate")') {{ offerTermsData.penaltyInterestRate | percent }}
        fi-data-field(:label = '$t("specialTerms")'): span(v-html = 'sanitizedText')

      .form-text.alert.alert-danger.animated.fadeIn(v-if = 'error') {{ error }}

      template(v-if = 'editing')
        button.btn.btn-primary(
          type      = 'submit'
          :disabled = 'saving') {{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'toggleEdit'
        ) {{ $t('common:cancel') }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiRichTextEditor from '@/components/FiRichTextEditor'
import FiSelect from '@/components/FiSelect'
import PercentInput from '@/components/PercentInput'
import { feeDisabledMixin, feeEditableMixin } from '@/mixins'
import { rules, validation, validators } from '@/validation'
import Template from '@/views/admin/templates/Template'
import pick from 'lodash/pick'
import merge from 'lodash/merge'
import sanitizeHtml from 'sanitize-html'
import { mapActions, mapGetters, mapState } from 'vuex'
import { calculationTypes, convertFee, convertFeeRange, feePercentage } from '@/helpers'
import { UpdateTypes } from '@/models/application/CApplication.ts'

export default {
  name: 'application-offer-terms-data',

  components: {
    Template,
    FiRichTextEditor,
    FiSelect,
    FiDataField,
    FiFormField,
    PercentInput,
    FiMoneyInput
  },

  mixins: [
    validation,
    feeDisabledMixin,
    feeEditableMixin
  ],

  props: {
    editing: {
      type: Boolean,
      required: true
    },
    application: {
      type: Object,
      required: true
    },
    applicationProduct: {
      type: Object,
      required: true
    },
    productFees: {
      type: Object,
      required: true
    },
    feeConfigs: {
      type: Object,
      required: true
    },
    financial: {
      type: Array,
      default: () => []
    }
  },

  i18nOptions: {},

  data () {
    return {
      error: null,
      interestRateName: '',
      interestRatesData: [],
      variableInterestRate: null,
      offerTermsData: {
        administrationFee: null,
        administrationFeeMethodId: '',
        agreementFee: null,
        agreementFeeMethodId: '',
        applicationExpenses: null,
        applicationIncome: null,
        applicationObligations: null,
        applicationObligationsIncomeRatio: null,
        commitmentFee: null,
        commitmentFeeMethodId: '',
        creditAmount: null,
        interestRate: null,
        loanLength: null,
        monthlyPayment: null,
        obligationsIncomeRatio: null,
        originalAppliedAmount: null,
        originalAppliedMonthlyPayment: null,
        originalAppliedPeriod: null,
        penaltyInterestRate: null,
        processingFee: null,
        processingFeeMethodId: '',
        rightOfRecourse: null,
        specialTerms: null,
        variableInterestId: null
      },
      offerTermsDataVerified: {
        creditAmountMax: null,
        monthlyExpenses: null,
        monthlyIncome: null,
        monthlyObligations: null,
        monthlyPaymentMax: null,
        obligationIncomeRatio: null,
        periodMax: null
      }
    }
  },

  computed: {
    ...mapGetters('settings', ['settingByKey']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'classifierList']),
    ...mapState('interests', ['interestRates', 'variableInterests']),
    saving () {
      return this.$vueLoading.isLoading(`application:${this.application.id}:offerTerms:save`)
    },
    agreementFee () {
      return {
        ...this.productFees?.AGREEMENT,
        actualAmount: convertFee({
          feeValue: this.application.agreementFee,
          isPercentage: !this.percentageAgreementFee,
          amountValue: this.application.creditAmount
        })
      } ?? {}
    },
    commitmentFee () {
      return {
        ...this.productFees?.COMMITMENT,
        actualAmount: convertFee({
          feeValue: this.application.commitmentFee,
          isPercentage: !this.percentageCommitmentFee,
          amountValue: this.application.creditAmount
        })
      } ?? {}
    },

    getSumApplicationIncome () {
      let sum = 0
      this.financial.forEach((current) => {
        if (current.financialDataType === 'CUSTOMER_INCOME') {
          if (current?.financialDataAmount >= 0) {
            sum = sum + current.financialDataAmount
          }
        }
      })
      return sum
    },
    getSumApplicationObligations () {
      let sum = 0
      this.financial.forEach((current) => {
        if (current.financialDataType === 'CUSTOMER_LIABILITIES') {
          if (current?.financialDataAmount >= 0) {
            sum = sum + current.financialDataAmount
          }
        }
      })
      return sum
    },
    getSumApplicationExpenses () {
      let sum = 0
      this.financial.forEach((current) => {
        if (current.financialDataType === 'CUSTOMER_EXPENSES') {
          if (current?.financialDataAmount >= 0) {
            sum = sum + current.financialDataAmount
          }
        }
      })
      return sum
    },
    agreementFeeCalcType () {
      return this.classifierById('feeCalculationType', this.offerTermsData.agreementFeeMethodId || this.application.agreementFeeMethodId).code
    },
    percentageAgreementFee () {
      return feePercentage(this.agreementFeeCalcType)
    },
    commitmentFeeCalcType () {
      return this.classifierById('feeCalculationType', this.offerTermsData.commitmentFeeMethodId || this.application.commitmentFeeMethodId).code
    },
    percentageCommitmentFee () {
      return feePercentage(this.commitmentFeeCalcType)
    },
    agreementFeeCalculationTypes () {
      return calculationTypes(this.agreementFee, this.feeConfigs, this.classifierList('feeCalculationType'), true)
    },
    commitmentFeeCalculationTypes () {
      return calculationTypes(this.commitmentFee, this.feeConfigs, this.classifierList('feeCalculationType'), true)
    },
    convertedAgreementFeeRange () {
      return convertFeeRange({ ...this.agreementFee, feeCalculationType: this.agreementFeeCalcType }, this.application.creditAmount)
    },
    convertedCommitmentFeeRange () {
      return convertFeeRange({ ...this.commitmentFee, feeCalculationType: this.commitmentFeeCalcType }, this.application.creditAmount)
    },
    sanitizedText () {
      return sanitizeHtml(this.application.specialTerms || '', {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['u'])
      })
    },
    interestRatesDataValues () {
      if (this.interestRatesData.length) {
        return this.interestRatesData.map((rate) => rate.name)
      } else {
        return ['FIXED']
      }
    },
    variableInterestIdGetter: {
      get () {
        return this.interestRateName
      },
      set (value) {
        this.offerTermsData.variableInterestId = this.interestRatesData?.find((rate) => rate.name === value)?.id || null
        this.variableInterestRate = this.interestRates?.find((rate) => rate.variableInterest.id === this.offerTermsData.variableInterestId)?.rate || 0
        this.interestRateName = this.interestRates?.find((rate) => rate.name === value)?.name || value
      }
    },
    variableInterestName () {
      return this.application.variableInterestDto?.name || 'FIXED'
    }
  },

  watch: {
    percentageAgreementFee (val, oldVal) {
      if (typeof oldVal === 'undefined' || val === oldVal) {
        return
      }
      this.offerTermsData.agreementFee = convertFee({
        feeValue: this.offerTermsData.agreementFee,
        isPercentage: this.percentageAgreementFee,
        amountValue: this.application.creditAmount
      })
    },
    percentageCommitmentFee (val, oldVal) {
      if (typeof oldVal === 'undefined' || val === oldVal) {
        return
      }
      this.offerTermsData.commitmentFee = convertFee({
        feeValue: this.offerTermsData.commitmentFee,
        isPercentage: this.percentageCommitmentFee,
        amountValue: this.application.creditAmount
      })
    },
    editing (value) {
      if (value) {
        this.resetData()
      }
    }
  },

  validations () {
    return {
      offerTermsData: merge({}, pick(rules.loanRules, Object.keys(this.offerTermsData)), {
        agreementFee: {
          // required: validators.requiredUnless(function () {
          //   return (!this.feeDisabled(this.agreementFee))
          // }),
          required: (!this.feeDisabled(this.agreementFee)),
          gte: validators.gte(this.convertedAgreementFeeRange.feeMin),
          lte: validators.lte(this.convertedAgreementFeeRange.feeMax)
        },
        commitmentFee: {
          // required: validators.requiredUnless(
          //   (!this.feeDisabled(this.commitmentFee))
          // ),
          required: (!this.feeDisabled(this.commitmentFee)),
          gte: validators.gte(this.convertedCommitmentFeeRange.feeMin),
          lte: validators.lte(this.convertedCommitmentFeeRange.feeMax)
        },
        interestRate: {
          gte: validators.gte(this.applicationProduct.interestRateMin),
          lte: validators.lte(this.applicationProduct.interestRateMax)
        },
        penaltyInterestRate: {
          gte: validators.gte(this.applicationProduct.penaltyInterestRateMin),
          lte: validators.lte(this.applicationProduct.penaltyInterestRateMax)
        }
      })
    }
  },

  async created () {
    await this.loadVariableInterestsRate()
    await this.loadVariableInterests()
    this.interestRatesData = this.variableInterests.filter((rate) =>
      this.applicationProduct.productInterestDefinitionDtos.find((interestDefinition) =>
        interestDefinition.variableInterestId === rate.id
      )
    )
    this.offerTermsData = this.application.offerTerms.applicationData
    this.offerTermsDataVerified = this.application.offerTerms.verifiedData
    if (!this.applicationProduct.productInterestDefinitionDtos.length) {
      this.interestRatesData = this.variableInterests.filter((interest) => interest.name === 'FIXED')
      this.interestRateName = 'FIXED'
      this.offerTermsData.variableInterestId = this.interestRatesData[0].id || 7
      this.variableInterestRate = 0
    } else {
      this.interestRateName = this.variableInterestName
      this.offerTermsData.variableInterestId = this.application.variableInterestDto?.id
      this.variableInterestRate = this.application.variableInterestRate || 0
    }
  },

  methods: {
    ...mapActions('applications', ['updateApplication']),
    ...mapActions('interests', ['loadVariableInterestsRate', 'loadVariableInterests']),
    resetData () {
      this.offerTermsData = {
        ...this.offerTermsData,
        ...pick(this.application, Object.keys(this.offerTermsData))
      }
      this.$v.$reset()
      this.error = null
    },
    toggleEdit () {
      this.resetData()
      this.$emit('toggle-editing')
    },
    async onSubmit () {
      if (this.validate()) {
        try {
          await this.updateApplication({ id: this.application.id, applicationData: { applicationData: this.offerTermsData, verifiedData: this.application.offerTerms.verifiedData }, type: UpdateTypes.OfferTerms })
          this.toggleEdit()
        } catch (e) {
          this.error = e
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.title-text-form {
  font-weight: 700;
  background-color: #ffff;
  & /deep/ label {font-weight: 700;}
}
.title-blok-form {
  border-bottom: 2px solid #a4b7c1;
  border-top: 2px solid #a4b7c1;
}
.botm {
  border-bottom: none;
}
.border-right-none {
  border-right: none;
  :nth-child(4) {
    border-right: none
  }
}
.border-right-none > div {
  border-right: 1px solid #c2cfd6;
}

</style>

<i18n>
en:
  agreementFee:           "Agreement fee"
  commitmentFee:          "Commitment fee"
  interestRate:           "Interest rate"
  penaltyRate:            "Penalty rate"
  specialTerms:           "Special terms"
  applicationData:        "Application data"
  income:                 "Income"
  obligations:            "Obligations"
  expences:               "Expences"
  obligationsIncomeRatio: "Obligations Income ratio"
  originalAppliedAmount:  "Application loan amount"
  creditAmount:      "Offered loan amount"
  period:                 "Period"
  monthlyPayment:         "Monthly payment"
  gte:                    "Field value should be greater or equal to {{value}}"
  lte:                    "Field value should be less or equal to {{value}}"
  offeredLoan:            "Offered loan"
et:
  agreementFee:           "Agreement fee"
  commitmentFee:          "Commitment fee"
  interestRate:           "Interest rate"
  penaltyRate:            "Penalty rate"
  specialTerms:           "Special terms"
  applicationData:        "Application data"
  income:                 "Income"
  obligations:            "Obligations"
  expences:               "Expences"
  obligationsIncomeRatio: "Obligations Income ratio"
  originalAppliedAmount:  "Application loan amount"
  creditAmount:      "Offered loan amount"
  period:                 "Period"
  monthlyPayment:         "Monthly payment"
  gte:                    "Field value should be greater or equal to {{value}}"
  lte:                    "Field value should be less or equal to {{value}}"
  offeredLoan:            "Offered loan"
ru:
  agreementFee:           "Agreement fee"
  commitmentFee:          "Commitment fee"
  interestRate:           "Interest rate"
  penaltyRate:            "Penalty rate"
  specialTerms:           "Special terms"
  applicationData:        "Application data"
  income:                 "Income"
  obligations:            "Obligations"
  expences:               "Expences"
  obligationsIncomeRatio: "Obligations Income ratio"
  originalAppliedAmount:  "Application loan amount"
  creditAmount:      "Offered loan amount"
  period:                 "Period"
  monthlyPayment:         "Monthly payment"
  gte:                    "Field value should be greater or equal to {{value}}"
  lte:                    "Field value should be less or equal to {{value}}"
  offeredLoan:            "Offered loan"
</i18n>
