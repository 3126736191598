<template lang="pug">
.application-purchase-info-form-fields.row
  .col-lg-6
    template(v-if = 'editing')
      fi-form-field(
        :label = '$t("purchasedGood")'
        :state = '!applicationData.purchasedGood.$error'
      )
        template(#error)
          .error-message(v-if = '!applicationData.purchasedGood.required') {{ $t('common:required') }}

        input.form-control.form-control-sm(
          v-model.number = 'applicationData.purchasedGood.$model'
          type           = 'text'
          :class         = '{ "is-invalid": applicationData.purchasedGood.$error }'
        )


      fi-form-field(
        :label = '$t("cost")'
        :state = '!applicationData.purchasedGoodCost.$error'
      )
        template(#error)
          .error-message(v-if = '!applicationData.purchasedGoodCost.required') {{ $t('common:required') }}

        fi-money-input.form-control.form-control-sm(
          v-model.number = 'applicationData.purchasedGoodCost.$model'
          type           = 'text'
          :class         = '{ "is-invalid": applicationData.purchasedGoodCost.$error }'
        )

    template(v-else)
      fi-data-field(:label = '$t("purchasedGood")') {{ application.purchasedGood }}
      fi-data-field(:label = '$t("cost")') {{ application.purchasedGoodCost | money }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiDataField from '@/components/FiDataField'
import FiMoneyInput from '@/components/FiMoneyInput'

export default {
  name: 'application-purchase-info-form-fields',

  components: {
    FiFormField,
    FiDataField,
    FiMoneyInput
  },

  props: {
    editing: {
      type: Boolean,
      default: false
    },
    application: {
      type: Object,
      default: null
    },
    applicationData: {
      type: Object,
      default: null
    }
  },

  i18nOptions: {}
}
</script>


<i18n>
en:
  purchasedGood: "Purchased good"
  cost:          "Cost"
et:
  purchasedGood: "Purchased good"
  cost:          "Cost"
ru:
  purchasedGood: "Purchased good"
  cost:          "Cost"
</i18n>
