<template lang="pug">
.application-note.card.border-warning
  .card-body(v-if = 'editing')
    form.row(@submit.prevent = 'onSubmit')
      .form-group.col.animated.fadeIn
        fi-rich-text-editor(v-model.trim = 'commentData.comment')
      .col-auto(v-if='note')
        small.text-muted.mr-1
          router-link(:to = '{ name: "Customer", params: { id: note.createdBy } }') {{ note.createdByName }}
          br
          | {{ $t('common:formatDatetime', { value: note.createdAt }) }}
      .col-auto
        button.btn.btn-primary(
          type      = 'submit'
          :disabled = 'saving'
        )
          |{{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'toggleEdit'
        ) {{ $t('common:cancel') }}
  .card-body(v-else)
    .row
      template(v-if='note')
        .col(v-if       = 'reason')
          fi-data-field(
            :label     = '$t("reason")'
            label-cols = 1
          )
            .reason() {{ reason }}
          fi-data-field(
            :label     = '$t("comment")'
            label-cols = 1
          )
            .comment(v-html = 'sanitizedText')
        .col(v-else)
            p.comment(v-html = 'sanitizedText')
        .col-auto
          small.text-muted
            router-link(:to = '{ name: "Customer", params: { id: note.createdBy } }') {{ note.createdByName }}
            br
            | {{ $t('common:formatDatetime', { value: note.createdAt }) }}
      .col-auto(v-if = 'isEditable && note && userCheck')
        button.btn.btn-link.btn-sm(
          :title         = 'addEditLabel'
          @click.prevent = 'toggleEdit'
        )
          i.far.fa-edit.fa-lg(v-if = 'note')
          template(v-else) {{ $t('addNote') }}
        button.btn.btn-link.text-danger.btn-sm(
          v-if           = 'note'
          :title         = '$t("deleteNote")'
          v-fi-confirm   = '{ ok: deleteNote, message: $t("deleteConfirm") }'
        )
          i.far.fa-trash-alt.fa-lg
      .col-auto(v-else-if = 'isEditable && !note')
        button.btn.btn-link.btn-sm(
          :title         = 'addEditLabel'
          @click.prevent = 'toggleEdit'
        )
          i.far.fa-edit.fa-lg(v-if = 'note')
          template(v-else) {{ $t('addNote') }}
        button.btn.btn-link.text-danger.btn-sm(
          v-if           = 'note'
          :title         = '$t("deleteNote")'
          v-fi-confirm   = '{ ok: deleteNote, message: $t("deleteConfirm") }'
        )
          i.far.fa-trash-alt.fa-lg
</template>


<script>
import FiDataField from '@/components/FiDataField'
import FiRichTextEditor from '@/components/FiRichTextEditor'
import pick from 'lodash/pick'
import { mapActions, mapState } from 'vuex'
import sanitizeHtml from 'sanitize-html'

export default {
  name: 'application-note',

  components: {
    FiRichTextEditor,
    FiDataField
  },

  props: {
    note: {
      type: Object,
      default: null
    },
    roles: {
      type: Array,
      default: null
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    reason: {
      type: String,
      default: ''
    }
  },

  i18nOptions: {},

  data () {
    return {
      editing: false,
      commentData: {
        comment: ''
      }
    }
  },

  computed: {
    ...mapState('auth', ['user']),
    userCheck () {
      return this.note.createdBy === this.user.id
    },
    saving () {
      return this.$vueLoading.isLoading([
        'application:comment:save',
        'application:comment:edit',
        `application:${this.applicationId}:comment:${this.note?.id}:delete`
      ])
    },
    sanitizedText () {
      return sanitizeHtml((this.note && this.note.comment) || '', {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['u'])
      })
    },
    applicationId () {
      return this.$route.params.id
    },
    isNew () {
      return !this.note?.id
    },
    addEditLabel () {
      return this.isNew ? this.$t('editNote') : this.$t('addNote')
    }
  },

  methods: {
    ...mapActions('applications', ['createInternalComment', 'updateInternalComment', 'removeInternalComment']),
    toggleEdit () {
      if (!this.editing) {
        if (this.roles.find(({ name }) => name === 'ROLE_SUPER_USER' || name === 'ROLE_ADMINISTRATOR' || name === 'ROLE_ACCOUNT_MANAGER' || name === 'ROLE_CREDIT_MANAGER')) {
          this.resetData()
          this.editing = !this.editing
        } else {
          this.$toasted.error('Defined role do not allow to create the comment')
        }
      } else {
        this.editing = !this.editing
      }
    },
    resetData () {
      this.commentData = {
        ...this.commentData,
        ...pick(this.note, Object.keys(this.commentData))
      }
    },
    async onSubmit () {
      if (this.isNew) {
        await this.createInternalComment({ id: this.applicationId, commentData: this.commentData })
        this.$emit('update')
      } else {
        await this.updateInternalComment({ id: this.note.id, commentData: this.commentData })
      }
      this.editing = false
    },
    async deleteNote ({ done }) {
      await this.removeInternalComment({ id: this.applicationId, commentId: this.note.id })
      done()
    }
  }
}
</script>


<style scoped lang="scss">
p.comment {
  white-space: pre-line;
  & /deep/ table { border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden; }
  & /deep/ td {border: 1px solid; padding: 4px; width: 100%}
}
p.comment, .form-group { margin-bottom: 0; }
</style>


<i18n>
en:
  reason:        "Reason"
  comment:       "Comment"
  addNote:       "Add application note"
  editNote:      "Edit application note"
  deleteNote:    "Delete note"
  deleteConfirm: "Are you sure you want to delete this note?"
et:
  reason:        "Reason"
  comment:       "Comment"
  addNote:       "Add application note"
  editNote:      "Edit application note"
  deleteNote:    "Delete note"
  deleteConfirm: "Are you sure you want to delete this note?"
ru:
  reason:        "Reason"
  comment:       "Comment"
  addNote:       "Add application note"
  editNote:      "Edit application note"
  deleteNote:    "Delete note"
  deleteConfirm: "Are you sure you want to delete this note?"
</i18n>
