<template lang="pug">
  component(
    :is              = 'applicationNewComponent'
    :workflow-policy = 'workflowPolicy'
    :copy            = 'copy'
    :customer-id     = 'customerId'
  )
</template>


<script>
import WorkflowPolicy from '@/policies/WorkflowPolicy'
import { mapState } from 'vuex'

const ApplicationNewLoan = () => import('./ApplicationNewLoan')
const ApplicationNewFactoring = () => import('./ApplicationNewFactoring')

export default {
  name: 'application-new-page',

  props: {
    productType: {
      type: String,
      required: true
    },
    copy: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: Number,
      default: null
    }
  },

  computed: {
    ...mapState('classifiers', ['productTypes']),
    workflowPolicy () {
      return new WorkflowPolicy(this.productTypes[this.productType])
    },
    applicationNewComponent () {
      if (this.workflowPolicy.isFactoring) {
        return ApplicationNewFactoring
      }
      return ApplicationNewLoan
    }
  }
}
</script>
