import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { CApplicationCorporateAnnualReport } from '@/models/application/CApplicationCorporateAnnualReport'

export class CApplicationCorporateCustomer {
  @Type(() => CApplicationCorporateAnnualReport)
  @Expose()
  annualReports: CApplicationCorporateAnnualReport[] = []

  @Expose()
  companyName: string;

  @Expose()
  companyRegistrationNumber: string;

  @Expose()
  officialPhone: string;

  @Expose()
  officialEmail: string;

  @Expose()
  emtakId: number;

  @Expose()
  naceId: number;

  @Expose({ toClassOnly: true })
  @Transform(({ value }) => JSON.parse(value ?? '""'), { toClassOnly: true })
  boardMembers: [] = []

  @Expose({ toClassOnly: true })
  @Transform(({ value }) => JSON.parse(value ?? '""'), { toClassOnly: true })
  boardMembersScored: [] = []

  @Expose()
  contactEmail: string

  @Expose()
  contactFirstName: string

  @Expose()
  contactLastName: string

  @Expose()
  contactPhone: string

  @Expose()
  dateOfRegistration: Date

  @Expose()
  existingClient: boolean

  @Expose()
  currentPaymentDefaultAmount: number

  @Expose()
  currentPaymentDefaults: number

  @Expose()
  currentTaxDebtAmount: number

  @Expose()
  deferredTaxDebtAmount: number

  @Expose()
  financialYear: string

  @Expose({ toClassOnly: true })
  fullScoringData: string

  @Expose()
  historicalPaymentDefaults: number

  @Expose()
  historicalPaymentDefaultsAmount: number

  @Expose()
  historicalTaxDebtAmount: number

  @Expose()
  lastQuarterDefinition: string

  @Expose()
  legalForm: string

  @Expose()
  officialAnnouncements: string

  @Expose({ toClassOnly: true })
  @Transform(({ value }) => JSON.parse(value ?? '""'), { toClassOnly: true })
  owners: [] = []

  @Expose({ toClassOnly: true })
  @Transform(({ value }) => JSON.parse(value ?? '""'), { toClassOnly: true })
  ownersScored: [] = []

  @Expose()
  registeredCapital: number

  @Expose()
  representationForm: string

  @Expose()
  subsidiaries: string

  @Expose()
  taxesPaidLastQuarterAmount: number

  @Expose()
  turnoverOfLastQuarter: number

  @Expose()
  unsubmittedTaxReports: number

  @Expose()
  @Exclude({ toPlainOnly: true })
  customerId: number;

  @Expose()
  @Exclude({ toPlainOnly: true })
  sectorId: number;

  @Expose()
  @Exclude({ toPlainOnly: true })
  segmentId: number;

  @Expose()
  @Exclude({ toPlainOnly: true })
  countryId: number;

  @Expose()
  @Exclude({ toPlainOnly: true })
  countyId: number;

  @Expose()
  @Exclude({ toPlainOnly: true })
  city: string;

  @Expose()
  @Exclude({ toPlainOnly: true })
  fullAddress: string;

  @Expose()
  @Exclude({ toPlainOnly: true })
  postalCode: string;

  @Expose()
  @Exclude({ toPlainOnly: true })
  accountExternalId: string;
}
