import { CApplicationCorporateCustomer } from '@/models/application/CApplicationCorporateCustomer'
import { Expose, plainToClass, Transform, Type } from 'class-transformer'
import { CApplicationPrivateCustomer } from '@/models/application/CApplicationPrivateCustomer'
import { CApplicationAsset } from '@/models/application/CApplicationAsset'
import { CSeller } from '@/models/application/CSeller'
import { CThirdParty } from '@/models/application/CThirdParty'

export const enum UpdateTypes {
  ApplicationData = 'APPLICATION_DATA',
  OfferTerms = 'OFFER_TERMS',
  CorporateFinancial = 'CORPORATE_FINANCIAL_DATA',
  CorporateAnnualReports = 'CORPORATE_ANNUAL_REPORTS',
}

class CApplicationFields {
  @Expose() productId: number

  @Expose({ groups: ['leasing'] })
  @Type(() => CSeller)
  sellers: CSeller[] = []

  @Expose({ groups: ['factoring'] })
  @Type(() => CThirdParty)
  thirdParties: CThirdParty[] = []
}

class CFactoringInvoiceLengthInterestRate {
  @Expose() id?: number
  @Expose() invoiceLength?: number
  @Expose() periodInterestRate: number
  @Expose() yearlyInterestRate?: number
}

export class CApplicationDataFields {
  @Expose({ groups: ['factoring'] })
  advanceRate?: number

  @Expose()
  creditAmount: number

  @Expose({ groups: ['factoring'] })
  contractLimit: number

  @Expose({ groups: ['factoring'] })
  @Type(() => CFactoringInvoiceLengthInterestRate)
  invoiceLengthInterestRates: CFactoringInvoiceLengthInterestRate[]

  @Expose({ groups: ['factoring'] })
  rightOfRecourse: boolean = false


  @Expose({ groups: ['factoring'] })
  @Expose() invoiceLengthMax: number

  @Expose({ groups: ['factoring'] })
  @Expose() invoiceLengthMin: number

  @Expose() loanLength: number

  @Expose({ groups: ['leasing'] })
  loanAmountWithVat: number

  @Expose({ groups: ['leasing'] }) residualAmount: number
  @Expose({ groups: ['leasing'] }) residualAmountCalculationTypeId: number
  @Expose({ groups: ['leasing'] }) upfrontAmount: number
  @Expose({ groups: ['leasing'] }) upfrontAmountCalculationTypeId: number
}

class CHirePurchaseFields {
  @Expose() purchasedGood: string
  @Expose() purchasedGoodCost: number
}

export class CFinancialData {
  @Expose() expirationDate: Date = new Date()
  @Expose() financialDataAmount: number = 0
  @Expose() financialDataAmountPeriod: string = ''
  @Expose() financialDataType: string = ''
  @Expose() financialDataValue: string = ''
  @Expose() thirdParty: string = ''
}

class CCustomFieldValue {
  @Expose() customFieldValue: string
  @Expose() productCustomFieldId: number
  id: number
}

export class CApplication {
  // Application Fields
  @Expose({ toClassOnly: true })
  @Transform(({ obj, options }): CApplicationFields => plainToClass(
    CApplicationFields,
    obj,
    { ...options, excludeExtraneousValues: true, exposeDefaultValues: true }), { toClassOnly: true })
  applicationFields: CApplicationFields

  @Expose({ toPlainOnly: true })
  @Transform(({ obj, key }) => obj.applicationFields[key], { toPlainOnly: true })
  productId: number

  @Expose({ groups: ['leasing'], toPlainOnly: true })
  @Transform(({ obj, key }) => obj.applicationFields[key], { toPlainOnly: true })
  @Type(() => CSeller)
  sellers: CSeller[] = []

  @Expose({ groups: ['factoring'], toPlainOnly: true })
  @Transform(({ obj, key }) => obj.applicationFields[key], { toPlainOnly: true })
  @Type(() => CThirdParty)
  thirdParties: CThirdParty[] = []

  // Data Fields
  @Expose({ toClassOnly: true })
  @Transform(({ obj, options }): CApplicationDataFields => {
    return plainToClass(CApplicationDataFields, obj, { ...options, excludeExtraneousValues: true, exposeDefaultValues: true })
  }, { toClassOnly: true })
  dataFields: CApplicationDataFields

  @Expose({ toPlainOnly: true })
  @Transform(({ obj, key }) => obj.dataFields[key], { toPlainOnly: true })
  creditAmount: number

  @Expose({ groups: ['factoring'], toPlainOnly: true })
  @Transform(({ obj, key }) => obj.dataFields[key], { toPlainOnly: true })
  advanceRate: number

  @Expose({ groups: ['factoring'], toPlainOnly: true })
  @Transform(({ obj, key }) => obj.dataFields[key], { toPlainOnly: true })
  contractLimit: number

  @Expose({ toPlainOnly: true })
  @Transform(({ obj, key }) => obj.dataFields[key], { toPlainOnly: true })
  loanLength: number

  @Expose({ toPlainOnly: true, groups: ['leasing'] })
  @Transform(({ obj, key }) => obj.dataFields[key], { toPlainOnly: true })
  loanAmountWithVat: number

  @Expose({ toPlainOnly: true, groups: ['leasing'] })
  @Transform(({ obj, key }) => obj.dataFields[key], { toPlainOnly: true })
  residualAmount: number

  @Expose({ toPlainOnly: true, groups: ['leasing'] })
  @Transform(({ obj, key }) => obj.dataFields[key], { toPlainOnly: true })
  residualAmountCalculationTypeId: number

  @Expose({ toPlainOnly: true, groups: ['leasing'] })
  @Transform(({ obj, key }) => obj.dataFields[key], { toPlainOnly: true })
  upfrontAmount: number

  @Expose({ toPlainOnly: true, groups: ['leasing'] })
  @Transform(({ obj, key }) => obj.dataFields[key], { toPlainOnly: true })
  upfrontAmountCalculationTypeId: number

  @Expose({ groups: ['factoring'], toPlainOnly: true })
  @Transform(({ obj, key }) => obj.dataFields[key], { toPlainOnly: true })
  @Type(() => CFactoringInvoiceLengthInterestRate)
  invoiceLengthInterestRates: CFactoringInvoiceLengthInterestRate[]

  @Expose({ groups: ['factoring'], toPlainOnly: true })
  @Transform(({ obj, key }) => obj.dataFields[key], { toPlainOnly: true })
  rightOfRecourse: boolean = false

  @Expose({ groups: ['factoring'], toPlainOnly: true })
  @Transform(({ obj, key }) => obj.dataFields[key], { toPlainOnly: true })
  @Expose() invoiceLengthMax: number

  @Expose({ groups: ['factoring'], toPlainOnly: true })
  @Transform(({ obj, key }) => obj.dataFields[key], { toPlainOnly: true })
  @Expose() invoiceLengthMin: number

  @Expose() houseNumber: string
  @Expose() internalComment: string
  @Expose() apartmentNumber: string
  @Expose() cityPart: string
  @Expose() customerComment: string

  // Corporate customers fields
  @Expose({ groups: ['corporate'] })
  @Transform(({ value, obj, options }): CApplicationCorporateCustomer => {
    return plainToClass(CApplicationCorporateCustomer, {
      ...value,
      ...obj
    }, { ...options, excludeExtraneousValues: true, exposeDefaultValues: true })
  }, { toClassOnly: true })
  corporateApplication: CApplicationCorporateCustomer

  // Private customers fields
  @Expose({ groups: ['private'] })
  @Transform(({ value, obj, options }): CApplicationPrivateCustomer => {
    return plainToClass(CApplicationPrivateCustomer, {
      ...value,
      ...obj
    }, { ...options, excludeExtraneousValues: true, exposeDefaultValues: true })
  }, { toClassOnly: true })
  privateApplication: CApplicationPrivateCustomer

  @Expose({ toPlainOnly: true })
  @Transform(({ obj, key }) => obj.corporateApplication[key], { groups: ['corporate'], toPlainOnly: true })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'], toPlainOnly: true })
  customerId: number

  @Expose({ toPlainOnly: true })
  @Transform(({ obj, key }) => obj.corporateApplication[key], { groups: ['corporate'], toPlainOnly: true })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'], toPlainOnly: true })
  sectorId: number

  @Expose({ toPlainOnly: true })
  @Transform(({ obj, key }) => obj.corporateApplication[key], { groups: ['corporate'], toPlainOnly: true })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'], toPlainOnly: true })
  segmentId: number

  @Expose({ toPlainOnly: true })
  @Transform(({ obj, key }) => obj.corporateApplication[key], { groups: ['corporate'], toPlainOnly: true })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'], toPlainOnly: true })
  fullAddress: string

  @Expose({ toPlainOnly: true })
  @Transform(({ obj, key }) => obj.corporateApplication[key], { groups: ['corporate'], toPlainOnly: true })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'], toPlainOnly: true })
  city: string

  @Expose({ toPlainOnly: true })
  @Transform(({ obj, key }) => obj.corporateApplication[key], { groups: ['corporate'], toPlainOnly: true })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'], toPlainOnly: true })
  postalCode: string

  @Expose({ toPlainOnly: true })
  @Transform(({ obj, key }) => obj.corporateApplication[key], { groups: ['corporate'], toPlainOnly: true })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'], toPlainOnly: true })
  countryId: number

  @Expose({ toPlainOnly: true })
  @Transform(({ obj, key }) => obj.corporateApplication[key], { groups: ['corporate'], toPlainOnly: true })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'], toPlainOnly: true })
  residenceCountryId: number

  @Expose({ toPlainOnly: true })
  @Transform(({ obj, key }) => obj.corporateApplication[key], { groups: ['corporate'], toPlainOnly: true })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'], toPlainOnly: true })
  accountExternalId: string

  @Expose({ toPlainOnly: true })
  @Transform(({ obj, key }) => obj.corporateApplication[key], { groups: ['corporate'], toPlainOnly: true })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'], toPlainOnly: true })
  countyId: number

  @Expose({ toClassOnly: true })
  resolution: []

  @Expose({ toClassOnly: true })
  @Transform(({ obj, options }): CHirePurchaseFields => plainToClass(
    CHirePurchaseFields,
    obj,
    { ...options, excludeExtraneousValues: true, exposeDefaultValues: true }), { toClassOnly: true })
  purchaseFields: CHirePurchaseFields

  @Expose({ toPlainOnly: true })
  @Transform(({ obj, key }) => obj.purchaseFields[key], { toPlainOnly: true })
  purchasedGood: string

  @Expose({ toPlainOnly: true })
  @Transform(({ obj, key }) => obj.purchaseFields[key], { toPlainOnly: true })
  purchasedGoodCost: number

  @Expose()
  @Type(() => CApplicationAsset)
  applicationAsset: CApplicationAsset

  @Expose()
  @Type(() => CFinancialData)
  customerFinancialData: CFinancialData[] = []

  @Expose()
  @Type(() => CCustomFieldValue)
  customFieldValues: CCustomFieldValue[]
}
