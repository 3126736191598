<template lang="pug">
fi-modal.loan-schedule-interest-period-new-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'lg'
  lazy
  hide-footer
  @hide   = 'onHide'
)
  form.form-horizontal
    customer-new-brief(
      :private-customer = 'relatedPerson.firstName ? true : false'
      :related-person = 'relatedPerson'
      @cancel = '$refs.modal.hide()'
      @submit = '$refs.modal.hide()'
    )
</template>


<script>
import FiModal from '@/components/FiModal'
import FiMultiselect from '@/components/FiMultiselect'
import FiCustomerSelect from '@/components/FiCustomerSelect'
import CustomerNewBrief from '@/views/customers/CustomerNewBrief'

export default {
  name: 'application-new-customer-modal',

  components: { FiCustomerSelect, FiMultiselect, FiModal, CustomerNewBrief },

  props: {
    loader: {
      type: Boolean,
      default: false
    },
    relatedPerson: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    saving () {
      return this.loader
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },

  methods: {
    onHide ({ preventDefault }) {
      if (this.saving) {
        preventDefault()
      }
    }
  }
}
</script>


<i18n>
en:
  title: "Create new customer"

et:
  title: "Create new customer"

ru:
  title: "Create new customer"

</i18n>
