<template lang = "pug">
.application-list.animated.fadeIn
  .card
    .card-header {{ $t('title') }}
      application-new-button.fa-pull-right(:product-types = 'productTypesOptions')
    .card-body
      form.form-horizontal
        .row
          .form-group.col-lg-6
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-search
              input.form-control(
                type         = 'text'
                :placeholder = '$t("search")'
                v-model.trim = 'searchParams.criteria'
              )
          .form-group.col-lg-6
            range-input-date(
              :title       = '$t("date")'
              :start.sync  = 'searchParams.startDate'
              :end.sync    = 'searchParams.endDate'
            )
        .row
          .form-group.col-lg-3
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-tag
              fi-select(
                v-model.number = 'searchParams.productId'
                :options       = 'optionsFromProducts'
                :placeholder   = '$t("productPlaceholder")'
              )
          .form-group.col-lg-3
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-share
              fi-select(
                v-model.number = 'searchParams.assigneeId'
                :options       = 'optionsFromManagers'
                :placeholder   = '$t("assigneePlaceholder")'
              )
          .form-group.col-lg-3
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-user
              fi-select(
                v-model.number = 'searchParams.creditIssuerId'
                :options       = 'optionsFromCreditIssuers'
                :placeholder   = '$t("creditIssuerPlaceholder")'
              )
          .form-group.col-lg-3
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-flag
              fi-multiselect(
                v-model.number = 'statuses'
                :options       = 'optionsFromClassifier("creditApplicationStatuses")'
                :placeholder   = '$t("statusPlaceholder")'
                multiple
              )
      fi-table(
        :fields      = 'fields'
        :items       = 'applications'
        loader       = 'applications:fetch'
        :empty-label = '$t("notFound")'
        :sort.sync   = 'searchParams.sort'
        responsive
      )
        template(#default = '{ items }')
          application-list-item.animated.fadeIn(
            v-for        = 'application in items'
            :key         = 'application.id'
            :application = 'application'
          )
      fi-pagination(
        v-if    = 'applicationsPages > 1'
        :pages  = 'applicationsPages'
        v-model = 'pagination.page'
      )
</template>

<script>
import FiPagination from '@/components/FiPagination'
import FiSelect from '@/components/FiSelect'
import FiTable from '@/components/FiTable'
import FiMultiselect from '@/components/FiMultiselect'
import RangeInputDate from '@/components/RangeInputDate'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters, mapState } from 'vuex'
import ApplicationListItem from './ApplicationListItem'
import ApplicationNewButton from './ApplicationNewButton'
import pickBy from 'lodash/pickBy'
import pick from 'lodash/pick'
import { pagination } from '@/const'

const searchParams = {
  criteria: '',
  startDate: null,
  endDate: null,
  productId: '',
  assigneeId: '',
  statusId: '',
  sort: 'createdAt,desc'
}

export default {
  name: 'application-list',

  components: {
    FiTable,
    ApplicationNewButton,
    ApplicationListItem,
    RangeInputDate,
    FiPagination,
    FiSelect,
    FiMultiselect
  },

  i18nOptions: {},

  data: (vm) => ({
    searchParams: { ...searchParams, ...pick(vm.$route.query, Object.keys(searchParams)) },
    pagination: { ...pagination, ...pick(vm.$route.query, Object.keys(pagination)) },
    statuses: []
  }),

  computed: {
    ...mapState('applications', ['applications', 'applicationsPages']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'optionsFromManagers', 'optionsFromCreditIssuers']),
    ...mapGetters('products', ['optionsFromProducts']),
    ...mapState('classifiers', ['productTypes']),
    productTypesOptions () {
      return Object.values(this.productTypes)
        .filter(({ productIds, group }) => productIds?.length && !/deposit/i.test(group))
        .map(({ human, code }) => ({ text: human, value: code }))
    },
    fields () {
      return [
        {
          key: 'id',
          label: 'ID',
          sort: 'id'
        },
        {
          key: 'customer',
          label: this.$t('customer'),
          sort: ['firstName', 'lastName', 'customerName']
        },
        {
          key: 'creditIssuer',
          label: this.$t('creditIssuer'),
          sort: ['creditIssuerName']
        },
        {
          key: 'product',
          label: this.$t('product'),
          sort: 'productName'
        },
        {
          key: 'amount',
          label: this.$t('amount'),
          class: 'money'
        },
        {
          key: 'createdAt',
          label: this.$t('date'),
          sort: 'createdAt'
        },
        {
          key: 'manager',
          label: this.$t('manager'),
          sort: ['assigneeLastName', 'assigneeFirstName']
        },
        {
          key: 'status',
          label: this.$t('applicationStatus')
        },
        {
          key: 'loanStatus',
          label: this.$t('loanStatus')
        }
      ]
    },
    searchQuery () {
      return pickBy({ ...this.searchParams, ...this.pagination })
    }
  },

  watch: {
    searchParams: {
      handler () {
        this.pagination.page = 0
        this.search()
      },
      deep: true
    },
    statuses: {
      handler () {
        this.changeQueryStatsId()
      },
      deep: true
    },
    'pagination.page' () {
      this.search()
    },
    '$route.query': {
      handler (query) {
        this.loadApplications({ filters: query })
      },
      deep: true
    }
  },

  created () {
    this.loadProducts({ group: ['LOAN', 'OVERDRAFT', 'LEASING', 'GUARANTEE', 'FACTORING'] })
    this.loadManagers()
    this.loadApplications({ filters: this.searchQuery })
    if (this.$route.query.statusId) {
      let strStatuses = ''
      try {
        strStatuses = this.$route.query.statusId.split(',')
      } catch (err) {
        strStatuses = this.$route.query.statusId
      }
      const arrStatuses = this.optionsFromClassifier('creditApplicationStatuses')
      for (let i = 0; i < strStatuses.length; i++) {
        for (let j = 0; j < arrStatuses.length; j++) {
          if (strStatuses[i].trim() === `${arrStatuses[j].value}`) {
            this.statuses.push(arrStatuses[j])
          }
        }
      }
    }
  },

  methods: {
    ...mapActions('applications', ['loadApplications']),
    ...mapActions('products', ['loadProducts']),
    ...mapActions('classifiers', ['loadManagers']),
    search: debounce(function () {
      // let tempStatusId = ''
      // if (this.searchQuery.statusId?.length) {
      //   this.searchQuery.statusId.forEach(el => {
      //     if (tempStatusId === '') {
      //       tempStatusId = `${el.value}`
      //     } else {
      //       tempStatusId = `${tempStatusId}, ${el.value}`
      //     }
      //   })
      // }
      const tempStatusId = this.searchQuery.statusId
      this.searchQuery.statusId = tempStatusId
      this.$router.push({ query: this.searchQuery })
    }, 250),
    changeQueryStatsId () {
      this.searchParams.statusId = ''
      this.statuses.forEach(el => {
        if (this.searchParams.statusId === '') {
          this.searchParams.statusId = `${el.value}`
        } else {
          this.searchParams.statusId = `${this.searchParams.statusId}, ${el.value}`
        }
      })
    }
  }
}
</script>


<i18n>
en:
  title:               "Applications"
  search:              "Search"
  productPlaceholder:  "All products"
  assigneePlaceholder: "All assignees"
  statusPlaceholder:   "All application statuses"
  notFound:            "No applications found"
  customer:            "Customer"
  date:                "Date"
  product:             "Product"
  amount:              "Amount"
  manager:             "Manager"
  applicationStatus:   "Application status"
  loanStatus:          "Loan status"
  creditIssuer:        "Credit issuer"
  creditIssuerPlaceholder: "All credit issuers"
et:
  title:               "Applications"
  search:              "Search"
  productPlaceholder:  "All products"
  assigneePlaceholder: "All assignees"
  statusPlaceholder:   "All application statuses"
  notFound:            "No applications found"
  customer:            "Customer"
  date:                "Date"
  product:             "Loan product"
  amount:              "Amount"
  manager:             "Manager"
  applicationStatus:   "Application status"
  loanStatus:          "Loan status"
  creditIssuer:        "Credit issuer"
  creditIssuerPlaceholder: "All credit issuers"
ru:
  title:               "Applications"
  search:              "Search"
  productPlaceholder:  "All products"
  assigneePlaceholder: "All assignees"
  statusPlaceholder:   "All application statuses"
  notFound:            "No applications found"
  customer:            "Customer"
  date:                "Date"
  product:             "Loan product"
  amount:              "Amount"
  manager:             "Manager"
  applicationStatus:   "Application status"
  loanStatus:          "Loan status"
  creditIssuer:        "Credit issuer"
  creditIssuerPlaceholder: "All credit issuers"
</i18n>
