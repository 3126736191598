<template lang="pug">
.application-score-item
  .col
    span.font-weight-bold.float-right(v-if = 'request') {{ request.scoringResponseModel.finalScore }}
    span.font-weight-bold.float-right(v-else-if = 'requestId'): i.fa.fa-spinner.fa-pulse
    span.font-weight-bold.float-right(v-else) {{ $t('notScored') }}
  .animated.fadeIn(v-if = 'request')
    scoring-request-progress-bar(:request = 'request')
    router-link(
      v-if = 'scoringModel && scoringModel.modelType'
      :to  = '{ name: "ScoringRequest", params: { id: request.id, type: scoringModel.modelType.toLowerCase() } }'
    ) \#{{ request.id }}
    div(
      v-for = 'key in Object.keys(request.scoringResponseModel)'
      v-if  = 'showScoringResult(key)'
    )
      hr.mt-1.mb-1
      | {{ request.scoringResponseModel[key].checkDescription || key }}
      span.float-right {{ request.scoringResponseModel[key].result }}
  .form-check.mt-2.mb-0(v-if = 'bankCheckable')
    label.form-check-label
      i.fa.fa-spinner.fa-pulse.form-check-input.mr-1(v-if = 'isSaveBankCheck')
      input.form-check-input.mr-1(
        v-else
        v-model   = 'applicationData.bankStatementChecked'
        :disabled = '!rescorable || isSaveBankCheck'
        type      = 'checkbox'
        @change   = 'onBankCheckChange'
      )
      | {{ $t('bankCheck') }}
  button.btn.btn-primary.mt-3(
    v-if           = 'rescorable'
    :disabled      = 'isScoring'
    @click.prevent = 'onScore'
  )
    | {{ $t('scoreBtn') }}
    i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'isScoring')
</template>


<script>
import pick from 'lodash/pick'
import { mapActions, mapGetters, mapState } from 'vuex'
import ScoringRequestProgressBar from '@/views/scoring/ScoringRequestProgressBar'

export default {
  name: 'application-score-item',

  components: { ScoringRequestProgressBar },

  props: {
    type: {
      type: String,
      required: true
    },
    requestId: {
      type: [Number, String],
      default: null
    },
    request: {
      type: Object,
      default: null
    },
    rescorable: {
      type: Boolean,
      default: false
    },
    bankCheckable: {
      type: Boolean,
      default: false
    },
    loader: {
      type: String,
      default: ''
    }
  },

  i18nOptions: {},

  data () {
    return {
      applicationData: {
        bankStatementChecked: null
      }
    }
  },

  computed: {
    ...mapState('applications', ['application']),
    ...mapGetters('scoring', ['scoringModelById']),
    isScoring () {
      return this.$vueLoading.isLoading(this.loader)
    },
    isSaveBankCheck () {
      return this.$vueLoading.isLoading(`application:${this.application.id}:save`)
    },
    scoringModel () {
      return this.scoringModelById(this.request.scoringModelId) ?? {}
    }
  },

  created () {
    this.resetData()
  },

  methods: {
    ...mapActions('applications', ['updateApplication']),
    showScoringResult (key) {
      return key !== 'finalScore' && this.request.scoringResponseModel[key] && this.request.scoringResponseModel[key].result < 0
    },
    resetData () {
      this.applicationData = {
        ...this.applicationData,
        ...pick(this.application, Object.keys(this.applicationData))
      }
    },
    onBankCheckChange () {
      this.updateApplication({ id: this.application.id, applicationData: this.applicationData, type: 'APPLICATION_SCORING' })
    },
    async onScore () {
      this.$emit('score', { scoreType: this.type })
    }
  }
}
</script>


<style scoped lang="scss">
.form-check-input { width: 0.75rem; }
</style>


<i18n>
en:
  notScored: "Not scored"
  bankCheck: "Bank statement checked"
  scoreBtn:  "Score"
et:
  notScored: "Not scored"
  bankCheck: "Bank statement checked"
  scoreBtn:  "Score"
ru:
  notScored: "Not scored"
  bankCheck: "Bank statement checked"
  scoreBtn:  "Score"
</i18n>
