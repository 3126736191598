<template lang="pug">
.application-custom-fields-data.card
  .card-header {{ $t('title') }}
    .fa-pull-right
      .btn-group.btn-group-sm
        button.btn.btn-link(
          v-if           = '!editing'
          @click.prevent = 'toggleEditing()'
        )
          i.far.fa-edit.fa-lg
  .card-body
    form.form-horizontal(
      novalidate
      @submit.prevent = 'onSubmit'
    )
      template(v-if = 'editing')
        .row
          .col
            .input-group.input-group-sm.mb-2
              fi-select(
                v-model = 'selectedField'
                :options = 'optionsFromCustomFields'
                required
                sm
              )
              .input-group-append
                button.btn.btn-primary(
                  :disabled      = 'saving || !optionsFromCustomFields.length'
                  @click.prevent = 'addField'
                ) {{ $t('common:add') }}
                  i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        fi-custom-field-item(
          v-for     = '(customField, index) in customFieldsData'
          :key      = 'index'
          v-model   = 'customField.customFieldValue'
          :options  = 'selectedFields[customField.productCustomFieldId].customField'
          :disabled = '!selectedFields[customField.productCustomFieldId].enabled'
          :required = 'customField.required'
          editing
          @remove   = 'removeField(index)'
        )
        button.btn.btn-primary(
          :disabled = 'saving  || checkRequired'
          type      = 'submit'
        )
          |{{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'toggleEditing(false)'
        ) {{ $t('common:cancel') }}
      template(v-else)
        fi-custom-field-item(
          v-for     = '(field, index) in customFieldsData'
          :key      = 'index'
          v-model   = 'field.customFieldValue'
          :required = 'field.required'
          :options  = 'selectedFieldsOptions(field.productCustomFieldId)'
          :value    = 'field.customFieldValue'
        )
</template>

<script>
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiCustomFieldItem from '@/components/FiCustomFieldItem'
import FiDataField from '@/components/FiDataField'

export default {
  name: 'application-custom-fields-data',

  components: { FiDataField, FiCustomFieldItem, FiSelect, FiFormField },

  props: {
    customFields: {
      type: Array,
      default: () => []
    },
    loader: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    editing: false,
    selectedField: '',
    customFieldsData: []
  }),

  computed: {
    saving () {
      return this.$vueLoading.isLoading(this.loader)
    },
    checkRequired () {
      return this.customFieldsData.find(field => field.required && !field.customFieldValue)
    },
    optionsFromCustomFields () {
      if (!this.options) return
      return this.options.filter(({ enabled, required }) => enabled && !required)
        .map(({ id, customField, ...field }) => ({ ...field, ...customField, text: customField.name, value: id }))
        .filter(({ value }) => !Object.keys(this.selectedFields).includes(`${value}`))
    },
    selectedFields () {
      const customFieldList = this.customFieldsData.length ? this.customFieldsData : this.customFields
      return customFieldList
        .reduce((res, { productCustomFieldId }) => ({ ...res, [productCustomFieldId]: this.options.find(({ id }) => id === productCustomFieldId) }), {})
    }
  },

  created () {
    this.resetData()
  },

  methods: {
    toggleEditing (state = !this.editing) {
      this.resetData()
      this.editing = state
    },
    addField () {
      const [defaultValue] = this.optionsFromCustomFields.find(({ value }) => value === this.selectedField)
        ?.defaultValues?.filter(({ isEnabled }) => isEnabled)
      this.customFieldsData.push({
        productCustomFieldId: this.selectedField,
        customFieldValue: defaultValue?.classifierValue,
        required: false
      })
      this.selectedField = ''
    },
    removeField (index) {
      this.customFieldsData.splice(index, 1)
    },
    resetData () {
      Object.assign(this.$data, this.$options.data.call(this, this))
      this.customFieldsData = this.customFields.map(({ customFieldValue, productCustomFieldId, required }) => ({
        productCustomFieldId,
        customFieldValue,
        required
      }))
      this.customFieldsData = [...this.customFieldsData, ...this.options.filter(({ enabled, required }) => enabled && required)
        .filter((field) => !this.customFieldsData.some((customField) => customField.productCustomFieldId === field.id))
        .map(({ id, required }) => ({ productCustomFieldId: id, customFieldValue: undefined, required }))]
    },
    onSubmit () {
      this.$emit('submit', {
        data: this.customFieldsData.filter(({ productCustomFieldId }) => this.selectedFields[productCustomFieldId].enabled),
        done: () => {
          this.resetData()
        }
      })
    },
    selectedFieldsOptions (productCustomFieldId) {
      return this.selectedFields[productCustomFieldId]?.customField || {}
    }
  }
}
</script>


  <i18n>
  en:
    title: "Custom fields"
  et:
    title: "Custom fields"
  ru:
    title: "Custom fields"
  </i18n>
