<template lang="pug">
.application-factoring-data.card
  .card-header {{ $t('title') }}
    button.btn.btn-link.py-0.fa-pull-right.animated.fadeIn(
      v-if           = 'isEditable && !editing'
      :title         = '$t("common:edit")'
      @click.prevent = 'toggleEditing()'
    ): i.far.fa-edit.fa-lg
  .card-body
    form.form-horizontal.animated.fadeIn(
      @submit.prevent = 'onSubmit'
      v-if = 'editing'
    )
      fi-form-field(:label = '$t("contractLimit")')
        fi-money-input.form-control-sm(v-model = 'applicationData.contractLimit')

      fi-form-field(:label = '$t("period")')
        input.form-control.form-control-sm(
          v-model.number = 'applicationData.loanLength'
          type           = 'text'
        )
      fi-form-field(:label = '$t("advanceRate")')
        percent-input.form-control-sm(v-model = 'applicationData.advanceRate')
      fi-form-field(:label = '$t("invoiceLength")')
        .row
          .input-group.col.input-group-sm
            .input-group-prepend
              span.input-group-text {{ $t('common:min') }}
            input.form-control.form-control-sm.animated.fadeIn(
              type           = 'text'
              v-model.number = 'applicationData.invoiceLengthMin'
            )
          .input-group.col.input-group-sm
            .input-group-prepend
              span.input-group-text {{ $t('common:max') }}
            input.form-control.form-control-sm.animated.fadeIn(
              type           = 'text'
              v-model.number = 'applicationData.invoiceLengthMax'
            )
      fi-form-field(:label = '$t("interestRate")')
        application-factoring-interest-rate-item(
          v-for                 = '(interestRate, index) in applicationData.invoiceLengthInterestRates'
          :key                  = 'index'
          :interest-rate        = 'interestRate'
          :day-count-convention = 'productInterestRate.dayCountConvention'
          @input                = 'updateInterestRate($event, index)'
        )
          .col-2.px-1
            button.btn.btn-danger.btn-sm(
              v-if           = 'index'
              @click.prevent = 'updateInterestRate(undefined, index)'
            ): i.far.fa-trash-alt.fa-lg
            button.btn.btn-primary.btn-sm(
              v-else
              @click.prevent = 'addInterestRate'
            ): i.far.fa-plus-square.fa-lg

      .form-text.alert.alert-danger.animated.fadeIn(v-if = 'error') {{ error }}

      button.btn.btn-primary(
        type      = 'submit'
        :disabled = 'saving'
      )
        | {{ $t('common:save') }}
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
      | &nbsp;
      button.btn.btn-secondary(
        :disabled      = 'saving'
        @click.prevent = 'toggleEditing(false)'
      ) {{ $t('common:cancel') }}

    template(v-else)
      fi-data-field(:label = '$t("contractLimit")') {{ application.contractLimit | money }}
      fi-data-field(:label = '$t("period")') {{ application.loanLength }}
      fi-data-field(:label = '$t("advanceRate")') {{ application.advanceRate | percent }}
      fi-data-field(:label = '$t("invoiceLength")')
        .row
          .col {{ $t('common:min') }} {{ application.invoiceLengthMin }}
          .col {{ $t('common:max') }} {{ application.invoiceLengthMax }}
      fi-data-field(
        v-if   = 'application.invoiceLengthInterestRates.length'
        :label = '$t("interestRate")'
      )
        .row(v-for = 'interestRate in application.invoiceLengthInterestRates')
          .col-4 {{ interestRate.invoiceLength }} {{ $t('days') }}
          .col-4 {{ interestRate.periodInterestRate | percent }}
          .col-4 {{ interestRate.yearlyInterestRate | percent }} {{ $t('yearly') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import pick from 'lodash/pick'
import merge from 'lodash/merge'
import { mapActions, mapGetters, mapState } from 'vuex'
import ApplicationDataFields from '../ApplicationDataFields'
import { rules, validation, validators } from '@/validation'
import FiMoneyInput from '@/components/FiMoneyInput'
import PercentInput from '@/components/PercentInput'
import FiDataField from '@/components/FiDataField'
import ApplicationFactoringInterestRateItem
  from '@/views/applications/ApplicationFactoring/ApplicationFactoringInterestRateItem'
import Template from '@/views/admin/templates/Template'
import { UpdateTypes } from '@/models/application/CApplication.ts'

export default {
  name: 'application-factoring-data',

  components: {
    Template,
    ApplicationFactoringInterestRateItem,
    FiDataField,
    PercentInput,
    FiMoneyInput,
    FiFormField,
    ApplicationDataFields
  },

  mixins: [validation],

  props: {
    isEditable: {
      type: Boolean,
      default: false
    },
    application: {
      type: Object,
      required: true
    },
    applicationProduct: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      editing: false,
      error: null,
      applicationData: {
        contractLimit: null,
        invoiceLengthMin: null,
        invoiceLengthMax: null,
        loanLength: null,
        advanceRate: null,
        invoiceLengthInterestRates: []
      }
    }
  },

  computed: {
    ...mapState('products', ['productsList']),
    ...mapGetters('classifiers', ['classifierById']),
    saving () {
      return this.$vueLoading.isLoading(`application:${this.application.id}:save`)
    },
    productInterestRate () {
      return this.applicationProduct.attributes?.find(({ type }) => type.code === 'INTEREST_RATE')
    },
    productInvoiceLength () {
      return this.applicationProduct.attributes?.find(({ type }) => type.code === 'INVOICE_LENGTH_IN_DAYS')
    }
  },

  validations () {
    const mergedRules = { ...rules.applicationRules, ...rules.customerRules }
    return {
      applicationData: merge({}, pick(mergedRules, Object.keys(this.applicationData)), {
        loanLength: {
          gte: validators.gte(this.applicationProduct.periodMin),
          lte: validators.lte(this.applicationProduct.periodMax)
        }
      })
    }
  },

  methods: {
    ...mapActions('applications', ['updateApplication']),
    toggleEditing (state = !this.editing) {
      if (state) this.resetData()
      this.editing = state
    },
    resetData () {
      this.applicationData = {
        ...this.applicationData,
        ...pick(this.application, Object.keys(this.applicationData))
      }

      if (!this.applicationData.invoiceLengthInterestRates?.length) {
        this.applicationData.invoiceLengthInterestRates = [{
          invoiceLength: this.productInvoiceLength?.defaultValue,
          periodInterestRate: undefined,
          yearlyInterestRate: this.productInterestRate?.defaultValue
        }]
      }
      this.$v.$reset()
      this.error = null
    },
    async onSubmit () {
      if (this.validate()) {
        try {
          this.error = null
          await this.updateApplication({ id: this.application.id, applicationData: this.applicationData, type: UpdateTypes.ApplicationData })
          this.editing = false
        } catch (e) {
          this.error = e
        }
      }
    },
    updateInterestRate (value, index) {
      if (typeof value === 'undefined') {
        this.applicationData.invoiceLengthInterestRates.splice(index, 1)
      } else {
        this.applicationData.invoiceLengthInterestRates.splice(index, 1, value)
      }
    },
    addInterestRate () {
      this.applicationData.invoiceLengthInterestRates.push({
        invoiceLength: this.productInvoiceLength?.defaultValue,
        periodInterestRate: undefined,
        yearlyInterestRate: this.productInterestRate?.defaultValue
      })
    }
  }
}
</script>


<i18n>
en:
  title:         "Application data"
  acceptTerms:   "Application terms"
  contractLimit: "Contract limit"
  period:        "Contract period"
  advanceRate:   "Advance rate"
  invoiceLength: "Invoice length"
  interestRate:  "Interest rate"
  days:          "days"
  yearly:        "yearly"
et:
  title:         "Application data"
  acceptTerms:   "Application terms"
  contractLimit: "Contract limit"
  period:        "Contract period"
  advanceRate:   "Advance rate"
  invoiceLength: "Invoice length"
  interestRate:  "Interest rate"
  days:          "days"
  yearly:        "yearly"
ru:
  title:         "Application data"
  acceptTerms:   "Application terms"
  contractLimit: "Contract limit"
  period:        "Contract period"
  advanceRate:   "Advance rate"
  invoiceLength: "Invoice length"
  interestRate:  "Interest rate"
  days:          "дней"
  yearly:        "в год"
</i18n>
