<template lang="pug">
.application-overview.animated.fadeIn
  v-loading(:loader = '["application:product:fetch"]')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-4
    .row
      .col-6
        application-customer(
          v-if      = 'application'
          :customer = 'customer'
          @copy     = 'copyApplication'
      )
      .col-6
        application-factoring-data(
          v-if                 = 'workflow.isFactoring'
          :is-editable         = 'isEditable'
          :application         = 'application'
          :fee-configs          = 'feeConfigs'
          :application-product = 'applicationProduct'
        )
        application-data(
          v-else
          :fee-configs = 'feeConfigs'
          :is-editable = 'isEditable'
          :workflow    = 'workflow'
        )
        application-custom-fields-data(
          :custom-fields = 'customFieldsValues'
          :options       = 'applicationCustomFields'
          loader         = 'application:custom-fields:save'
          @submit        = 'saveCustomFields'
        )
        application-paid-taxes-list(
          v-if              = 'application && isCorporateCustomer(this.applicationCustomer)'
          :source-data      = 'application'
          :product          = 'applicationProduct'
          :paid-taxes       = 'applicationPaidTaxes'
        )
    application-related-persons-list(
      v-if              = 'application'
      :source-data      = 'application'
      :product          = 'applicationProduct'
      :related-persons-list = 'applicationRelatedPersons'
      @create           = 'addRelatedPersons'
      @edit             = 'updateRelatedPerson'
      @remove           = 'deleteRelatedPerson'
    )
    template(v-if = 'workflow.corporateCustomer')
      application-corporate-financial-data(
        :customer = 'applicationClass.corporateApplication'
        :loader   = '$vueLoading.isLoading(`application:${application.id}:save`)'
        @update   = 'updateCorporateFinancialData'
      )
      application-corporate-annual-report-list(
        v-if     = 'isCorporateCustomer(this.applicationCustomer)'
        :reports = 'applicationClass.corporateApplication.annualReports'
        @update  = 'updateAnnualReport'
        @remove  = 'removeAnnualReport'
      )
      financial-ratios-list(
        :financial-ratios = 'computedFinancialRatios'
      )
    application-financial-data-list(
      v-if       = 'financialOptions'
      :financial = 'customerFinancialData'
      :options   = 'financialOptions'
      @update    = 'saveCustomerFinancialData'
    )
    application-purchase-info(
      v-if         = 'applicationProduct && applicationProduct.hirePurchaseInfo'
      :is-editable = 'isEditable'
    )
    application-seller-info(
      v-for   = '(seller, index) in application.sellers'
      :key    = 'index'
      :seller = 'seller'
    )
    application-customer-comment
    application-offer-terms(:is-editable = 'isEditable')
      template(#default="{ editing, toggleEditing }")
        application-factoring-offer-terms(
          v-if                 = 'workflow.isFactoring'
          :application         = 'application'
          :application-product = 'applicationProduct'
          :product-fees        = 'applicationProductFees'
          :editing             = 'editing'
          @toggle-editing      = 'toggleEditing'
        )
        template(v-else)
          application-offer-terms-data.m-0.border-bottom-0(
            :application         = 'application'
            :application-product = 'applicationProduct'
            :product-fees        = 'applicationProductFees'
            :financial           = 'customerFinancialData'
            :editing             = 'editing'
            :fee-configs         = 'feeConfigs'
            @toggle-editing      = 'toggleEditing'
          )
          application-offer-terms-info.m-0.border-bottom-0(:application = 'application'
                                                           :editing     = 'editing')
    application-factoring-third-party-list(
      v-if              = 'workflow.isFactoring'
      :source-data      = 'application'
      :product          = 'applicationProduct'
      :third-party-list = 'applicationThirdParties'
      @create           = 'addThirdParty'
      @edit             = 'updateThirdParty'
      @remove           = 'deleteThirdParty'
    )
</template>


<script>
import ApplicationCustomer from '@/views/applications/ApplicationCustomer'
import ApplicationSellerInfo from '@/views/applications/ApplicationSellerInfo'
import ApplicationData from './ApplicationData'
import ApplicationPurchaseInfo from './ApplicationPurchaseInfo'
import ApplicationCustomerComment from './ApplicationCustomerComment'
import ApplicationOfferTerms from './ApplicationOfferTerms'
import { mapActions, mapGetters, mapState } from 'vuex'
import WorkflowPolicy from '@/policies/WorkflowPolicy'
import ApplicationFactoringOfferTerms from '@/views/applications/ApplicationFactoring/ApplicationFactoringOfferTerms'
import ApplicationOfferTermsData from '@/views/applications/ApplicationOfferTermsData'
import ApplicationOfferTermsInfo from '@/views/applications/ApplicationOfferTermsInfo'
import ApplicationFactoringData from '@/views/applications/ApplicationFactoring/ApplicationFactoringData'
import ApplicationFactoringThirdPartyList
  from '@/views/applications/ApplicationFactoring/ApplicationFactoringThirdPartyList'
import ApplicationRelatedPersonsList from '@/views/applications/ApplicationFactoring/ApplicationRelatedPersonsList'
import ApplicationPaidTaxesList from '@/views/applications/ApplicationFactoring/ApplicationPaidTaxesList'
import ApplicationCustomFieldsData from '@/views/applications/ApplicationCustomFieldsData'
import ApplicationCorporateAnnualReportList from '@/views/applications/ApplicationCorporateAnnualReportList'
import ApplicationCorporateFinancialDataForm from '@/views/applications/ApplicationCorporateFinancialDataForm'
import { plainToClass } from 'class-transformer'
import { CApplication, UpdateTypes } from '@/models/application/CApplication.ts'
import { CustomerFromApplicationAdapter } from '@/models/application/CustomerFromApplicationAdapter.ts'
import ApplicationCorporateFinancialData from '@/views/applications/ApplicationCorporateFinancialData'
import ApplicationFinancialDataList from '@/views/applications/ApplicationFinancialDataList'
import FinancialRatiosList from '@/views/applications/ApplicationsFinancialRatios/FinancialRatiosList'

export default {
  name: 'application-overview',

  components: {
    ApplicationFinancialDataList,
    ApplicationCorporateFinancialData,
    ApplicationCorporateFinancialDataForm,
    ApplicationCorporateAnnualReportList,
    ApplicationCustomFieldsData,
    ApplicationFactoringThirdPartyList,
    ApplicationFactoringData,
    ApplicationOfferTermsInfo,
    ApplicationOfferTermsData,
    ApplicationFactoringOfferTerms,
    ApplicationSellerInfo,
    ApplicationCustomer,
    ApplicationData,
    ApplicationPurchaseInfo,
    ApplicationCustomerComment,
    ApplicationOfferTerms,
    ApplicationRelatedPersonsList,
    ApplicationPaidTaxesList,
    FinancialRatiosList
  },

  props: {
    workflow: {
      type: WorkflowPolicy,
      required: true
    }
  },

  data: () => ({
    isLoading: true
  }),

  computed: {
    ...mapState('applications', ['application', 'applicationProduct', 'applicationCustomer', 'customFieldsValues', 'customerFinancialData', 'applicationRelatedPersons', 'applicationPaidTaxes']),
    ...mapState('factoring', ['applicationThirdParties']),
    ...mapGetters('classifiers', ['classifierById', 'creditIssuerById', 'productTypeById']),
    ...mapGetters('applications', ['applicationProductFees']),
    ...mapState('products', ['feeConfigs']),
    ...mapGetters('customers', ['isCorporateCustomer']),
    ...mapState('customFields', ['customFields']),
    customer () {
      return plainToClass(CustomerFromApplicationAdapter, this.application, { groups: this.workflow.groups, excludeExtraneousValues: true })
    },
    applicationClass () {
      return plainToClass(CApplication, this.application, { groups: this.workflow.groups })
    },
    checkCustomFieldsValues () {
      return this.customFieldsValues.length && this.applicationCustomFields.length
    },
    isNewFeature () {
      return process.env.VUE_APP_NEW_FEATURE
    },
    isEditable () {
      return ['inserted', 'inProgress', 'offerSent', 'inAnalysis'].includes(this.classifierById('creditApplicationStatuses', this.application.statusId).name)
    },
    isOverdraft () {
      return this.workflow.isOverdraft
    },
    applicationCustomFields () {
      // return [...this.applicationProduct?.customFields?.filter(({ enabledApplication }) => enabledApplication)
      //   .map(field => ({ ...field, customField: this.customFields.find(({ id }) => id === field.customFieldId) }))
      //   .filter(({ customField }) => customField?.enabled)
      //   .sort((a, b) => a.fieldOrder - b.fieldOrder), ...this.applicationProduct?.customFields?.filter(({ enabledApplication }) => enabledApplication)
      //     .map(field => ({ ...field, customField: this.customFields.find(({ id }) => id === field.customFieldId) }))
      //     .filter(({ customField }) => customField?.enabled)
      //     .sort((a, b) => a.fieldOrder - b.fieldOrder)
      //     .map(field => ({ ...field, required: true }))]
      return this.applicationProduct?.customFields?.map(field => ({ ...field, customField: this.customFields.find(({ id }) => id === field.customFieldId) }))
        .filter(({ customField }) => customField?.enabled)
        .sort((a, b) => a.fieldOrder - b.fieldOrder)
    },
    financialOptions () {
      return this.applicationProduct?.financialData?.filter(({ isEnabled }) => isEnabled)
        .reduce(
          (res, { classifierKey, classifierCode, classifierValue, id }) =>
            ({ ...res, [classifierKey]: { ...res[classifierKey] ?? {}, [classifierCode]: { code: classifierCode, human: classifierValue, id } } }),
          {}
        )
    },
    computedFinancialRatios () {
      return this.applicationClass.corporateApplication.annualReports.map(el => ({
        id: el.id,
        year: el.reportYear,
        currentRation: el.currentRatio,
        quickRation: el.quickRatio,
        workingCapitalVolume: el.workingCapitalVolume,
        workingCapitalSales: el.workingCapitalSales,
        inventoryTurnover: el.inventoryTurnoverDays,
        receivablesTurnover: el.receivableTurnoverDays,
        payablesTurnover: el.payablesTurnoverDays,
        cashConversionCycle: el.cashConversionCycleRatio,
        ebitda: el.ebitda,
        debtToEbitda: el.debtEbitdaRatio,
        debtToEbitdaWithNewLoan: el.debtEbitdaRatioNewLoan,
        debtToEquityRatio: el.equity,
        leverage: el.leverage,
        returnOnEquity: el.returnOnEquity,
        returnOnAssets: el.returnOnAssets,
        roce: el.returnOnCapitalEmployed,
        editdaMargin: el.ebitdaMargin,
        profitMargin: el.profitMargin,
        salesGrowth: el.lastYearlySalesRevenue
      }))
    }
  },

  async created () {
    await this.loadClassifiersEMTAK()
    await this.loadClassifiersNACE()

    await this.loadCustomFields()
    await this.loadApplicationCustomFields({ applicationId: this.application.id })
    if (this.workflow.isFactoring) {
      await this.loadFactoringApplicationThirdParties({ applicationId: this.application.id })
    }
    await this.loadApplicationRelatedPersons({ applicationId: this.application.customerId })
    if (this.isCorporateCustomer(this.applicationCustomer)) await this.loadApplicationPaidTaxes({ idCode: this.applicationCustomer.idCode, idCodeCountry: this.classifierById('countries', this.applicationCustomer.countryId).code.toUpperCase() })
    await this.loadEditableClassifiers()
    await this.loadCustomerFinancialData({ customerId: this.application.customerId, applicationId: this.application.id })
    this.isLoading = false
  },

  methods: {
    ...mapActions('customFields', ['loadCustomFields']),
    ...mapActions('applications', [
      'updateApplication',
      'createCustomerFinancialData',
      'loadCustomerFinancialData',
      'removeCustomerFinancialData',
      'updateCustomerFinancialData',
      'saveApplicationCustomFields',
      'loadApplicationCustomFields',
      'loadApplicationRelatedPersons',
      'loadApplicationPaidTaxes',
      'createApplicationRelatedPersons',
      'removeApplicationRelatedPerson',
      'updateApplicationRelatedPersons',
      'removeApplicationAnnualReport'
    ]),
    ...mapActions('factoring', [
      'loadFactoringApplicationThirdParties',
      'createFactoringApplicationThirdParty',
      'updateFactoringApplicationThirdParty',
      'removeFactoringApplicationThirdParty'
    ]),
    ...mapActions('classifiers', ['loadEditableClassifiers', 'loadClassifiersEMTAK', 'loadClassifiersNACE']),
    copyApplication () {
      this.$router.push({ name: 'ApplicationNew', params: { copy: true, customer: this.applicationCustomer }, query: { productType: this.workflow.productType.code } })
    },
    async addThirdParty ({ thirdParty, done }) {
      await this.createFactoringApplicationThirdParty({ applicationId: this.application.id, thirdPartyData: thirdParty })

      done && done()
    },
    async addRelatedPersons ({ relatedPersons, done }) {
      if (!relatedPersons.customerId) {
        relatedPersons.customerId = this.application.customerId
      }
      if (relatedPersons.customerName) {
        delete relatedPersons.customerName
      }
      await this.createApplicationRelatedPersons({ id: this.application.customerId, relatedPersons: relatedPersons })

      done && done()
    },
    async updateRelatedPerson ({ relatedPersons, done }) {
      if (!relatedPersons.customerId) {
        relatedPersons.customerId = this.application.customerId
      }
      // if (!relatedPersons.customerName) {
      //   relatedPersons.customerName = relatedPersons.firstName + ' ' + relatedPersons.lastName
      // }
      delete relatedPersons.customerName
      await this.updateApplicationRelatedPersons({ id: relatedPersons.id, relatedPersons: relatedPersons })

      done && done()
    },
    async deleteThirdParty ({ index }) {
      const thirdPartyId = this.applicationThirdParties[index].id
      await this.removeFactoringApplicationThirdParty({ applicationId: this.application.id, thirdPartyId })
    },
    async deleteRelatedPerson ({ index }) {
      const relatedPersonId = this.applicationRelatedPersons[index].id
      await this.removeApplicationRelatedPerson({ relatedPersonId })
    },
    async updateThirdParty ({ thirdParty, thirdPartyId, done } = {}) {
      await this.updateFactoringApplicationThirdParty({ applicationId: this.application.id, thirdPartyData: thirdParty, thirdPartyId })

      done && done()
    },
    async updateCorporateFinancialData ({ financialData, done }) {
      await this.updateApplication({ id: this.application.id, applicationData: financialData, type: UpdateTypes.CorporateFinancial })

      // eslint-disable-next-line no-unused-expressions
      done?.()
    },
    async updateAnnualReport ({
      index = -1,
      report,
      done
    } = {}) {
      if (report) {
        report.corporateCustomerId = this.applicationCustomer.id
      }
      // report.corporateCustomerId = this.applicationCustomer.id
      const newReportList = [...this.applicationClass.corporateApplication.annualReports ?? []]

      newReportList.splice.apply(newReportList, [index, ~index && 1, report].filter(i => typeof i !== 'undefined'))

      try {
        await this.updateApplication({
          id: this.application.id,
          applicationData: {
            annualReports: newReportList
          },
          type: UpdateTypes.CorporateAnnualReports
        })
        // eslint-disable-next-line no-unused-expressions
        done?.()
      } catch {}
    },
    async removeAnnualReport ({ index, report }) {
      try {
        await this.removeApplicationAnnualReport({
          reportId: report.id,
          applicationId: this.application.id
        })
        // eslint-disable-next-line no-unused-expressions
      } catch {}
    },
    async saveCustomFields ({ data, done }) {
      await this.saveApplicationCustomFields({ applicationId: this.application.id, customFieldData: data })
      done()
    },
    async saveCustomerFinancialData ({ done, id, ...financialData }) {
      if (!id) {
        await this.createCustomerFinancialData({ customerId: this.application.customerId, applicationId: this.application.id, ...financialData })
      } else if (!Object.keys(financialData).length) {
        const index = this.customerFinancialData.findIndex(el => el.id === id)
        return (await this.removeCustomerFinancialData({ customerId: this.application.customerId, applicationId: id })) ? this.customerFinancialData.splice(index, 1) : ''
      } else {
        await this.updateCustomerFinancialData({ customerId: this.application.customerId, financialDataId: id, ...financialData })
      }
      done()
    }
  }
}
</script>
