<template lang="pug">
.application-corporate-annual-report-list.card
  .card-header {{ $t('title') }}
  .card-body
    .main-row
      .main-column(:style = '{ minWidth: "310px" }')
        .main-column--cell.border-bottom {{ $t("year") }}
        .main-column--cell.font-weight-bold {{ $t("header") }}
        .gray-divider
        .main-column--cell.border-bottom {{ $t("currentRatio") }}
        .main-column--cell.border-bottom {{ $t("quickRatio") }}
        .main-column--cell.border-bottom {{ $t("workingCapitalVolume") }}
        .main-column--cell {{ $t("workingCapitalToSales") }}
        .gray-divider
        .main-column--cell.border-bottom {{ $t("inventoryTurnover") }}
        .main-column--cell.border-bottom {{ $t("receivablesTurnover") }}
        .main-column--cell.border-bottom {{ $t("payablesTurnover") }}
        .main-column--cell {{ $t("cashConversionCycle") }}
        .gray-divider
        .main-column--cell.border-bottom {{ $t("ebitda") }}
        .main-column--cell.border-bottom {{ $t("debtToEbitda") }}
        .main-column--cell {{ $t("debtToEbitdaWithNewLoan") }}
        .gray-divider
        .main-column--cell.border-bottom {{ $t("debtToEquityRatio") }}
        .main-column--cell {{ $t("leverage") }}
        .gray-divider
        .main-column--cell.border-bottom {{ $t("returnOnEquity") }}
        .main-column--cell.border-bottom {{ $t("returnOnAssets") }}
        .main-column--cell.border-bottom {{ $t("roce") }}
        .main-column--cell.border-bottom {{ $t("ebitdaMargin") }}
        .main-column--cell {{ $t("profitMargin") }}
        .gray-divider
        .main-column--cell.border-bottom {{ $t("salesGrowth") }}
      .col.overflow-x
        financial-ratios-list-item(
          v-for = 'financialRatio in financialRatios'
          :key             = 'financialRatio.id'
          :financial-ratio = 'financialRatio'
        )
</template>

<script>
import FinancialRatiosListItem from '@/views/applications/ApplicationsFinancialRatios/FinancialRatiosListItem'

export default {
  name: 'financial-ratios-list',

  components: {
    FinancialRatiosListItem
  },

  props: {
    financialRatios: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.main-column {
  display: flex;
  flex-direction: column;

  &--cell {
    width: 100%;
    min-height: 22px;
    padding-inline: 15px;
  }
}

.main-row {
  display: flex;
}

.gray-divider {
  width: 100%;
  height: 2px;
  background-color: #a4b7c1;
}

.empty-cell {
  height: 22px;
}

.overflow-x {
  display: flex;
  // overflow-x: scroll;
  overflow-x: auto;
  padding: 0;
}
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, .125);
}
.overflow-x::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  background-color: #f9f9fd;
}

.overflow-x::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #20a8d8;
}

.overflow-x::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
  border-radius: 10px;
  background-color: #f9f9fd;
}
</style>

<i18n>
en:
  title:                          "Financial ratios"
  year:                           "Year"
  header:                         "Ratios / Suhtarvud"
  currentRatio:                   "Current ratio"
  quickRatio:                     "Quick ratio"
  workingCapitalVolume:           "Working Capital volume"
  workingCapitalToSales:          "Working capital to sales"
  inventoryTurnover:              "Inventory turnover in days"
  receivablesTurnover:            "Receivables turnover in days"
  payablesTurnover:               "Payables turnover in days"
  cashConversionCycle:            "Cash Conversion Cycle (CCC)"
  ebitda:                         "EBITDA"
  debtToEbitda:                   "DEBT to EBITDA"
  debtToEbitdaWithNewLoan:        "DEBT to EBITDA with new loan"
  debtToEquityRatio:              "DEBT-to-Equity Ratio"
  leverage:                       "Leverage"
  returnOnEquity:                 "Return on Equity (ROE)"
  returnOnAssets:                 "Return on Assets (ROA)"
  roce:                           "ROCE"
  ebitdaMargin:                   "EBITDA margin"
  profitMargin:                   "Profit margin"
  salesGrowth:                    "Sales growth"
et:
  title:                          "Financial ratios"
  year:                           "Year"
  header:                         "Ratios / Suhtarvud"
  currentRatio:                   "Current ratio"
  quickRatio:                     "Quick ratio"
  workingCapitalVolume:           "Working Capital volume"
  workingCapitalToSales:          "Working capital to sales"
  inventoryTurnover:              "Inventory turnover in days"
  receivablesTurnover:            "Receivables turnover in days"
  payablesTurnover:               "Payables turnover in days"
  cashConversionCycle:            "Cash Conversion Cycle (CCC)"
  ebitda:                         "EBITDA"
  debtToEbitda:                   "DEBT to EBITDA"
  debtToEbitdaWithNewLoan:        "DEBT to EBITDA with new loan"
  debtToEquityRatio:              "DEBT-to-Equity Ratio"
  leverage:                       "Leverage"
  returnOnEquity:                 "Return on Equity (ROE)"
  returnOnAssets:                 "Return on Assets (ROA)"
  roce:                           "ROCE"
  ebitdaMargin:                   "EBITDA margin"
  profitMargin:                   "Profit margin"
  salesGrowth:                    "Sales growth"
ru:
  title:                          "Financial ratios"
  year:                           "Year"
  header:                         "Ratios / Suhtarvud"
  currentRatio:                   "Current ratio"
  quickRatio:                     "Quick ratio"
  workingCapitalVolume:           "Working Capital volume"
  workingCapitalToSales:          "Working capital to sales"
  inventoryTurnover:              "Inventory turnover in days"
  receivablesTurnover:            "Receivables turnover in days"
  payablesTurnover:               "Payables turnover in days"
  cashConversionCycle:            "Cash Conversion Cycle (CCC)"
  ebitda:                         "EBITDA"
  debtToEbitda:                   "DEBT to EBITDA"
  debtToEbitdaWithNewLoan:        "DEBT to EBITDA with new loan"
  debtToEquityRatio:              "DEBT-to-Equity Ratio"
  leverage:                       "Leverage"
  returnOnEquity:                 "Return on Equity (ROE)"
  returnOnAssets:                 "Return on Assets (ROA)"
  roce:                           "ROCE"
  ebitdaMargin:                   "EBITDA margin"
  profitMargin:                   "Profit margin"
  salesGrowth:                    "Sales growth"
</i18n>
