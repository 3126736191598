import { render, staticRenderFns } from "./FinancialRatiosList.vue?vue&type=template&id=21286065&scoped=true&lang=pug&"
import script from "./FinancialRatiosList.vue?vue&type=script&lang=js&"
export * from "./FinancialRatiosList.vue?vue&type=script&lang=js&"
import style0 from "./FinancialRatiosList.vue?vue&type=style&index=0&id=21286065&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21286065",
  null
  
)

/* custom blocks */
import block0 from "./FinancialRatiosList.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports