<template lang="pug">
  .form-row.py-1
    .input-group.col.input-group-sm.px-1
      input.form-control.form-control-sm.animated.fadeIn(
        type           = 'text'
        v-model.number = 'invoiceLength'
      )
      .input-group-append
        span.input-group-text {{ $t('days') }}
    .col-3.px-1
      percent-input.form-control.form-control-sm.animated.fadeIn(v-model.number = 'periodInterestRate')
    .input-group.col-4.input-group-sm.px-1
      percent-input.form-control.form-control-sm.animated.fadeIn(
        :value.number = 'yearlyInterestRate'
        disabled
      )
      .input-group-append
        span.input-group-text {{ $t('yearly') }}
    slot
</template>


<script>
import PercentInput from '@/components/PercentInput'
import BigNumber from 'bignumber.js'
import debounce from 'lodash/debounce'

const dayCountConventionDays = {
  E30_360: 360,
  E30_365: 365,
  ACT_360: 360,
  ACT_365: 365
}

export default {
  name: 'application-factoring-interest-rate-item',

  components: { PercentInput },

  model: {
    prop: 'interestRate'
  },

  props: {
    interestRate: {
      type: Object,
      required: true
    },
    dayCountConvention: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    invoiceLength: {
      get () {
        return Number(this.interestRate.invoiceLength)
      },
      set (invoiceLength) {
        this.updateValue({ invoiceLength: Number(invoiceLength) })
      }
    },
    periodInterestRate: {
      get () {
        return Number(this.interestRate.periodInterestRate)
      },
      set (periodInterestRate) {
        this.updateValue({ periodInterestRate: Number(periodInterestRate) })
      }
    },
    yearlyInterestRate: {
      get () {
        return Number(this.interestRate.yearlyInterestRate)
      },
      set (yearlyInterestRate) {
        this.updateValue({ yearlyInterestRate: Number(yearlyInterestRate) })
      }
    },
    dayRate () {
      return new BigNumber(this.yearlyInterestRate).dividedBy(dayCountConventionDays[this.dayCountConvention?.code]).toPrecision(3)
    }
  },

  watch: {
    invoiceLength: {
      handler (invoiceLength) {
        this.calculateRates({ invoiceLength })
      },
      immediate: true
    },
    periodInterestRate (periodInterestRate, oldValue) {
      if (typeof oldValue === 'undefined') return
      this.calculateRates({ periodInterestRate })
    }
  },

  methods: {
    calculateRates ({
      invoiceLength,
      periodInterestRate
    }) {
      if (invoiceLength) {
        this.periodInterestRate = new BigNumber(this.dayRate)
          .multipliedBy(invoiceLength)
          .toPrecision(3)
      }
      if (periodInterestRate) {
        this.yearlyInterestRate = new BigNumber(periodInterestRate)
          .dividedBy(this.invoiceLength)
          .multipliedBy(dayCountConventionDays[this.dayCountConvention?.code])
          .toPrecision(3)
      }
    },
    updateValue: debounce(
      function (value) {
        this.$emit('input', {
          ...this.interestRate,
          ...value
        })
      }, 250
    )
  }
}
</script>
