<template lang="pug">
.application-corporate-financial-data.card
  .card-header {{ $t('title') }}
    button.btn.btn-link.py-0.fa-pull-right.animated.fadeIn(
      v-if           = '!editing'
      :title         = '$t("common:edit")'
      @click.prevent = 'toggleEdit()'
    ): i.far.fa-edit.fa-lg
  .card-body(v-if = 'editing')
    form.form-horizontal(@submit.prevent = 'onSubmit')
      application-corporate-financial-data-form(:customer-data = 'customerData')
      button.btn.btn-primary(:disabled = 'loader')
        | {{ $t('common:save') }}
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loader')
      | &nbsp;
      button.btn.btn-secondary(
        :disabled      = 'loader'
        @click.prevent = 'toggleEdit'
      ) {{ $t('common:cancel') }}
  .card-body(v-else)
    //- .row
    //-   .col-lg-6
    //-     fi-data-field(:label = '$t("registeredCapital")') {{ customer.registeredCapital | money }}
    .row.mb-3
      .col-lg-3
      .col-lg-3 {{ $t('current') }}
      .col-lg-3 {{ $t('historical') }}
    .row
      .col-lg
        fi-data-field(
          :label     = '$t("paymentDefaults")'
          label-cols = '3'
        )
          .row.mb-3
            .col-lg-4 {{ customer.currentPaymentDefaults | money }}
            .col-lg-4 {{ customer.historicalPaymentDefaults | money }}
          .row
            .col-lg-4 {{ customer.currentPaymentDefaultAmount | money }}
            .col-lg-4 {{ customer.historicalPaymentDefaultsAmount | money }}
    .row
      .col-lg
        fi-data-field(
          :label     = '$t("taxDebts")'
          label-cols = '3'
        )
          .row.mb-3
            .col-lg-4 {{ customer.currentTaxDebtAmount | money }}
            .col-lg-4 {{ customer.historicalTaxDebtAmount | money }}
    .row
      .col-lg-6
        fi-data-field(:label = '$t("deferredTax")') {{ customer.deferredTaxDebtAmount | money }}
    .row
      .col-lg-6
        fi-data-field(:label = '$t("taxesLastQuarter")') {{ customer.taxesPaidLastQuarterAmount | money }}
    .row
      .col-lg-6
        fi-data-field(:label = '$t("unsubmittedTaxReports")') {{ customer.unsubmittedTaxReports }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import ApplicationCorporateFinancialDataForm from '@/views/applications/ApplicationCorporateFinancialDataForm'
import { mapActions } from 'vuex'
import pick from 'lodash/pick'
import { validation } from '@/validation'

export default {
  name: 'application-corporate-financial-data',

  components: { ApplicationCorporateFinancialDataForm, FiDataField },

  mixins: [validation],

  props: {
    customer: {
      type: Object,
      required: true
    },
    loader: {
      type: Boolean
    }
  },

  i18nOptions: {},

  data: () => ({
    editing: false,
    customerData: {
      registeredCapital: null,
      currentPaymentDefaults: null,
      currentPaymentDefaultAmount: null,
      currentTaxDebtAmount: null,
      historicalPaymentDefaults: null,
      historicalPaymentDefaultsAmount: null,
      historicalTaxDebtAmount: null,
      deferredTaxDebtAmount: null,
      unsubmittedTaxReports: null,
      taxesPaidLastQuarterAmount: null
    }
  }),

  methods: {
    ...mapActions('applications', ['updateApplication']),
    toggleEdit () {
      this.resetData()
      this.editing = !this.editing
    },
    resetData () {
      this.customerData = { ...this.customerData, ...pick(this.customer, Object.keys(this.customerData)) }
    },
    async onSubmit () {
      this.$emit('update', {
        financialData: this.customerData,
        done: () => {
          this.editing = false
        }
      })
    }
  }
}
</script>


<i18n>
en:
  title:                 "Financial data"
  registeredCapital:     "Registered capital"
  current:               "Current"
  historical:            "Historical"
  paymentDefaults:       "Payment defaults"
  taxDebts:              "Tax debts"
  deferredTax:           "Deferred tax debt"
  taxesLastQuarter:      "Taxes paid last quarter"
  unsubmittedTaxReports: "Unsubmitted tax reports"
et:
  title:                 "Financial data"
  registeredCapital:     "Registered capital"
  current:               "Current"
  historical:            "Historical"
  paymentDefaults:       "Payment defaults"
  taxDebts:              "Tax debts"
  deferredTax:           "Deferred tax debt"
  taxesLastQuarter:      "Taxes paid last quarter"
  unsubmittedTaxReports: "Unsubmitted tax reports"
ru:
  title:                 "Financial data"
  registeredCapital:     "Registered capital"
  current:               "Current"
  historical:            "Historical"
  paymentDefaults:       "Payment defaults"
  taxDebts:              "Tax debts"
  deferredTax:           "Deferred tax debt"
  taxesLastQuarter:      "Taxes paid last quarter"
  unsubmittedTaxReports: "Unsubmitted tax reports"
</i18n>

