<template lang="pug">
.application-factoring-offer-terms.card
  .card-body
    form.form-horizontal(
      v-if            = 'editing'
      @submit.prevent = 'onSubmit'
    )
      .form-row
        .col-lg-6.border-right
          fi-form-field(:label = '$t("agreementFee")')
            .form-row
              .col
                fi-select(
                  v-model = 'offerTermsData.agreementFeeMethodId'
                  :options = 'optionsFromClassifier("feeCalculationType")'
                  disabled
                  sm
                )
              .col
                percent-input(
                  v-if = 'percentageAgreementFee'
                  v-model = 'offerTermsData.agreementFee'
                  readonly
                )
                input.form-control-sm.form-control(
                  v-else
                  v-model = 'offerTermsData.agreementFee'
                  readonly
                )
          fi-form-field(:label = '$t("processingFee")')
            .form-row
              .col
                fi-select(
                  v-model = 'offerTermsData.processingFeeMethodId'
                  :options = 'optionsFromClassifier("feeCalculationType")'
                  disabled
                  sm
                )
              .col
                percent-input(
                  v-if = 'percentageProcessingFee'
                  v-model = 'offerTermsData.processingFee'
                  readonly
                )
                input.form-control-sm.form-control(
                  v-else
                  v-model = 'offerTermsData.processingFee'
                  readonly
                )
          fi-form-field(:label = '$t("administrationFee")')
            .form-row
              .col
                fi-select(
                  v-model = 'offerTermsData.administrationFeeMethodId'
                  :options = 'optionsFromClassifier("feeCalculationType")'
                  disabled
                  sm
                )
              .col
                percent-input(
                  v-if = 'percentageAdministrationFee'
                  v-model = 'offerTermsData.administrationFee'
                )
                input.form-control-sm.form-control(
                  v-else
                  v-model = 'offerTermsData.administrationFee'
                  readonly
                )
          fi-form-field(:label = '$t("penaltyRate")')
            percent-input(v-model = 'offerTermsData.penaltyInterestRate')
          fi-form-field(:label = '$t("rightOfRecourse")')
            fi-switch(v-model = 'offerTermsData.rightOfRecourse')
          fi-form-field(:label = '$t("accountExternalId")')
            input.form-control.form-control-sm(
              v-model.trim = 'offerTermsData.accountExternalId'
              type         = 'text'
            )
        .col-lg-6
          fi-form-field(:label = '$t("obligationsIncomeRatio")')
            input.form-control.form-control-sm(
              v-model.number = 'offerTermsData.obligationsIncomeRatio'
              type           = 'text'
            )
          fi-form-field(:label = '$t("specialTerms")')
            fi-rich-text-editor(v-model.trim   = 'offerTermsData.specialTerms')
      button.btn.btn-primary(
        type      = 'submit'
        :disabled = 'saving'
      )
        | {{ $t('common:save') }}
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
      | &nbsp;
      button.btn.btn-secondary(
        :disabled      = 'saving'
        @click.prevent = 'toggleEditing'
      ) {{ $t('common:cancel') }}
    template(v-else)
      .row
        .col-lg-6.border-right
          fi-data-field(:label = '$t("agreementFee")')
            .form-row
              .col(v-if   = 'percentageAgreementFee') {{ application.agreementFee | percent }}
              .col(v-else) {{ application.agreementFee | money }}
          fi-data-field(:label = '$t("processingFee")')
            .form-row
              .col(v-if   = 'percentageProcessingFee') {{ application.processingFee | percent }}
              .col(v-else) {{ application.processingFee | money }}
          fi-data-field(:label = '$t("administrationFee")')
            .form-row
              .col(v-if   = 'percentageAdministrationFee') {{ application.administrationFee | percent }}
              .col(v-else) {{ application.administrationFee | money }}
          fi-data-field(:label = '$t("penaltyRate")') {{ application.penaltyInterestRate | percent }}
          fi-data-field(:label = '$t("rightOfRecourse")')
            fi-switch(
              :value = 'application.rightOfRecourse'
              disabled
            )
          fi-data-field(:label = '$t("accountExternalId")') {{ application.accountExternalId }}
        .col-lg-6
          fi-data-field(:label = '$t("obligationsIncomeRatio")') {{ application.obligationsIncomeRatio }}
          fi-data-field(:label = '$t("specialTerms")'): span(v-html = 'sanitizedText')
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import pick from 'lodash/pick'
import FiDatepicker from '@/components/FiDatepicker'
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import PercentInput from '@/components/PercentInput'
import FiDataField from '@/components/FiDataField'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import FiRichTextEditor from '@/components/FiRichTextEditor'
import sanitizeHtml from 'sanitize-html'
import { feePercentage } from '@/helpers'
import { UpdateTypes } from '@/models/application/CApplication.ts'

export default {
  name: 'application-factoring-offer-terms',

  components: { FiRichTextEditor, FiSwitch, FiSelect, FiDataField, PercentInput, FiMoneyInput, FiFormField, FiDatepicker },

  props: {
    editing: {
      type: Boolean,
      required: true
    },
    application: {
      type: Object,
      required: true
    },
    applicationProduct: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      error: null,
      offerTermsData: {
        agreementFee: null,
        agreementFeeMethodId: '',
        administrationFee: null,
        administrationFeeMethodId: '',
        processingFee: null,
        processingFeeMethodId: '',
        penaltyInterestRate: null,
        rightOfRecourse: false,
        specialTerms: null,
        accountExternalId: null,
        obligationsIncomeRatio: null
      }
    }
  },

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById']),
    saving () {
      return this.$vueLoading.isLoading(`application:${this.application.id}:save`)
    },
    percentageAdministrationFee () {
      return feePercentage(
        this.classifierById(
          'feeCalculationType',
          this.offerTermsData.administrationFeeMethodId || this.application.administrationFeeMethodId
        ).code
      )
    },
    percentageProcessingFee () {
      return feePercentage(
        this.classifierById(
          'feeCalculationType',
          this.offerTermsData.processingFeeMethodId || this.application.processingFeeMethodId
        ).code
      )
    },
    percentageAgreementFee () {
      return feePercentage(
        this.classifierById(
          'feeCalculationType',
          this.offerTermsData.agreementFeeMethodId || this.application.agreementFeeMethodId
        ).code
      )
    },
    sanitizedText () {
      return sanitizeHtml(this.application.specialTerms || '', {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['u'])
      })
    }
  },

  watch: {
    editing (value) {
      if (value) {
        this.resetData()
      }
    }
  },

  methods: {
    ...mapActions('applications', ['updateApplication']),
    resetData () {
      this.offerTermsData = {
        ...this.offerTermsData,
        ...pick(this.application, Object.keys(this.offerTermsData))
      }
      this.error = null
    },
    toggleEditing () {
      this.$emit('toggle-editing')
    },
    async onSubmit () {
      try {
        this.error = null
        await this.updateApplication({
          id: this.application.id,
          applicationData: this.offerTermsData,
          type: UpdateTypes.OfferTerms
        })
        this.toggleEditing()
      } catch (e) {
        this.error = e
      }
    }
  }
}
</script>


<i18n>
en:
  agreementFee:           "Agreement fee"
  processingFee:          "Processing fee"
  administrationFee:      "Administration fee"
  penaltyRate:            "Penalty rate"
  rightOfRecourse:        "Right of recourse"
  accountExternalId:      "Payout bank account"
  obligationsIncomeRatio: "Obligations income ratio"
  specialTerms:           "Special terms"
et:
  agreementFee:           "Agreement fee"
  processingFee:          "Processing fee"
  administrationFee:      "Administration fee"
  penaltyRate:            "Penalty rate"
  rightOfRecourse:        "Right of recourse"
  accountExternalId:      "Payout bank account"
  obligationsIncomeRatio: "Obligations income ratio"
  specialTerms:           "Special terms"
ru:
  agreementFee:           "Agreement fee"
  processingFee:          "Processing fee"
  administrationFee:      "Administration fee"
  penaltyRate:            "Penalty rate"
  rightOfRecourse:        "Right of recourse"
  accountExternalId:      "Payout bank account"
  obligationsIncomeRatio: "Obligations income ratio"
  specialTerms:           "Special terms"
</i18n>
