<template lang="pug">
.application-scoring.animated.fadeIn
  application-score-negative-note(
    v-if         = 'isNegative'
    :is-editable = 'isEditable'
  )
  .card-deck.mb-4
    application-score-block(
      v-if       = 'workflow.corporateCustomer'
      :title     = '$t("preScoring")'
      :request   = 'applicationPreScoreRequest'
      :requestId = 'application.preScoringHistoryId'
      :rescorable = 'rescorable && !workflow.privateCustomer'
      type       = 'prescore'
      @score     = 'onCorporateScore'
    )
    application-score-block(
      v-else-if   = 'workflow.privateCustomer'
      :title      = '$t("preScoring")'
      :request    = 'applicationPreScoreRequest'
      :requestId  = 'application.preScoringHistoryId'
      :rescorable = 'rescorable'
      type        = 'prescore'
      @score      = 'onScore'
    )
    application-score-block(
      v-if        = 'workflow.privateCustomer'
      :title      = '$t("personalScoring")'
      :request    = 'applicationPersonalScoreRequest'
      :requestId  = 'application.personalScoringHistoryId'
      :rescorable = 'rescorable'
      type        = 'personal'
      @score      = 'onScore'
    )
    application-score-block(
      v-else-if  = 'workflow.corporateCustomer'
      :title = '$t("personalScoring")'
    )
      template(v-if = 'scoringTypes')
        application-corporate-members-scoring(
          v-for    = 'type in scoringTypes'
          :key     = 'type'
          :title   = 'type'
          :members = 'getScoringCustomersByType(type)'
          role     = 'MEMBER'
          @score   = 'scoreMember'
        )
      //- application-corporate-members-scoring(
      //-   v-if     = 'applicationClass.corporateApplication.boardMembers'
      //-   :title   = '$t("boardMembers")'
      //-   :members = 'applicationClass.corporateApplication.boardMembers'
      //-   role     = 'MEMBER'
      //-   @score   = 'scoreMember'
      //- )
      //- application-corporate-members-scoring(
      //-   v-if     = 'getScoringCustomersByType'
      //-   :title   = '$t("owners")'
      //-   :members = 'getScoringCustomersByType'
      //-   role     = 'OWNER'
      //-   @score   = 'scoreMember'
      //- )
    application-score-block(
      :title         = '$t("applicationScoring")'
      :request       = 'applicationScoreRequest'
      :requestId     = 'application.applicationScoringHistoryId'
      :bankCheckable = 'true'
      :rescorable    = 'rescorable'
      type           = 'application'
      @score         = 'applicationScoreMethod'
    )
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ApplicationScoreNegativeNote from './ApplicationScoreNegativeNote'
import WorkflowPolicy from '@/policies/WorkflowPolicy'
import ApplicationScoreBlock from '@/views/applications/ApplicationScoreBlock'
import ApplicationCorporateMembersScoring from '@/views/applications/ApplicationCorporateMembersScoring'
import { plainToClass } from 'class-transformer'
import { CApplication } from '@/models/application/CApplication.ts'

export default {
  name: 'application-scoring',

  components: {
    ApplicationCorporateMembersScoring,
    ApplicationScoreBlock,
    ApplicationScoreNegativeNote
  },

  props: {
    workflow: {
      type: WorkflowPolicy,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapState('applications', [
      'application',
      'applicationPreScoreRequest',
      'applicationPersonalScoreRequest',
      'applicationScoreRequest',
      'applicationCustomerBankStatements',
      'applicationMemberScoreRequests',
      'applicationOwnersScoreRequests',
      'applicationRelatedPersons'
    ]),
    ...mapGetters('classifiers', ['classifierById', 'creditIssuerById', 'productTypeById']),
    isNegativePreScoring () {
      return (this.applicationPreScoreRequest && this.applicationPreScoreRequest.finalScore < 0)
    },
    isNegativePersonalScoring () {
      return (this.applicationPersonalScoreRequest && this.applicationPersonalScoreRequest.finalScore < 0)
    },
    isNegativeScoring () {
      return (this.applicationScoreRequest && this.applicationScoreRequest.finalScore < 0)
    },
    scoringTypes () {
      const res = []
      if (this.applicationRelatedPersons) {
        this.applicationRelatedPersons.forEach(item => {
          if (!res.includes(item.relationType)) {
            res.push(item.relationType)
          }
        })
      }
      return res
    },
    isNegative () {
      return (this.isNegativePreScoring || this.isNegativePersonalScoring || this.isNegativeScoring)
    },
    isEditable () {
      return ['inserted', 'inProgress', 'offerSent', 'inAnalysis'].includes(this.classifierById('creditApplicationStatuses', this.application.statusId).name)
    },
    rescorable () {
      return this.isEditable && !this.application.negativeScoringComment
    },
    applicationClass () {
      return plainToClass(CApplication, this.application, { groups: this.workflow.groups })
    },
    applicationScoreMethod () {
      return this.workflow.privateCustomer ? this.onScore : this.onCorporateScore
    }
  },

  watch: {
    'application.preScoringHistoryId': {
      handler (val) {
        if (!val) return
        this.loadApplicationScoreRequest({ requestType: 'applicationPreScoreRequest', requestId: val, type: this.workflow.privateCustomer ? 'private' : this.workflow.corporateCustomer ? 'corporate' : '' })
      },
      immediate: true
    },
    'application.personalScoringHistoryId': {
      handler (val) {
        if (!val) return
        this.loadApplicationScoreRequest({ requestType: 'applicationPersonalScoreRequest', requestId: val, type: 'private' })
      },
      immediate: true
    },
    'application.applicationScoringHistoryId': {
      handler (val) {
        if (!val || !this.workflow.groups.length) return
        this.loadApplicationScoreRequest({ requestType: 'applicationScoreRequest', requestId: val, type: this.workflow.privateCustomer ? 'private' : this.workflow.corporateCustomer ? 'corporate' : '' })
      },
      immediate: true
    },
    // 'applicationClass.corporateApplication.boardMembersScored': {
    //   async handler (val) {
    //     if (!val) return
    //     // console.log('boardMembersScored')
    //     // console.log(val)
    //     val.forEach(request => this.loadApplicationMemberScoreRequest({ requestId: request?.scoringId, type: 'private' }))
    //     // if (val.length) {
    //     //   console.log(val[val.length - 1].scoringId)
    //     //   await this.loadApplicationMemberScoreRequest({ requestId: val[val.length - 1].scoringId, type: 'private' })
    //     // }
    //   },
    //   deep: true,
    //   immediate: true
    // },
    // 'applicationClass.corporateApplication.ownersScored': {
    //   async handler (val) {
    //     if (!val) return
    //     // console.log('ownersScored')
    //     // console.log(val)
    //     val.forEach(request => this.loadApplicationOwnerScoreRequest({ requestId: request?.scoringId, type: 'private' }))
    //     // if (val.length) {
    //     //   console.log(val[val.length - 1].scoringId)
    //     //   await this.loadApplicationOwnerScoreRequest({ requestId: val[val.length - 1].scoringId, type: 'private' })
    //     // }
    //   },
    //   deep: true,
    //   immediate: true
    // },
    'applicationClass.relatedParties': {
      async handler (val) {
        console.log(this.applicationClass)
        console.log(val)
        if (!val) return
        val.forEach(request => {
          if (request?.scoringId) {
            this.loadApplicationMemberScoreRequest({ requestId: request?.scoringId, type: 'private' })
          }
        })
      },
      deep: true,
      immediate: true
    }
  },

  async created () {
    this.loadScoringModels()
    await this.loadApplicationRelatedPersons({ applicationId: this.application.customerId })
  },

  methods: {
    ...mapActions('applications', [
      'loadApplicationScoreRequest',
      'loadApplicationCustomerBankStatement',
      'createApplicationScoreRequest',
      'createCorporateScoreRequest',
      'loadApplication',
      'scoreCorporateMember',
      'loadApplicationRelatedPersons',
      'loadApplicationMemberScoreRequest',
      'loadApplicationOwnerScoreRequest'
    ]),
    ...mapActions('scoring', ['loadScoringModels']),
    async onScore (scoreData) {
      await this.createApplicationScoreRequest(scoreData)
      this.loadApplication({ id: this.application.id })
    },
    async onCorporateScore (scoreData) {
      await this.createCorporateScoreRequest(scoreData)
      this.loadApplication({ id: this.application.id })
    },
    getScoringCustomersByType (type) {
      return this.applicationRelatedPersons.filter((val) => val.relationType === type)
    },
    // async scoreMember ({ code, name, scoreType }) {
    //   await this.scoreCorporateMember({ id: this.application.id, memberData: { idCode: code, name, companyRole: scoreType } })
    //   this.loadApplication({ id: this.application.id })
    // }
    async scoreMember (member) {
      const name = member.isCompany ? member.companyName : member.firstName + ' ' + member.lastName
      await this.scoreCorporateMember({ id: this.application.id, memberData: { idCode: member.registrationCode, name, companyRole: 'MEMBER' } })
      this.loadApplication({ id: this.application.id })
    }
  }
}
</script>


<i18n>
en:
  scoring:            "Scoring"
  preScoring:         "Pre-scoring"
  personalScoring:    "KYC scoring"
  applicationScoring: "Application scoring"
  boardMembers:       "Board members"
  owners:             "Owners"
  statementsNotFound: "Instantor customer statements not found"
et:
  scoring:            "Scoring"
  preScoring:         "Pre-scoring"
  personalScoring:    "KYC scoring"
  applicationScoring: "Application scoring"
  boardMembers:       "Board members"
  owners:             "Owners"
  statementsNotFound: "Instantor customer statements not found"
ru:
  scoring:            "Scoring"
  preScoring:         "Pre-scoring"
  personalScoring:    "KYC scoring"
  applicationScoring: "Application scoring"
  boardMembers:       "Board members"
  owners:             "Owners"
  statementsNotFound: "Instantor customer statements not found"
</i18n>
