<template lang="pug">
  tr.animated.fadeIn
    td
      fi-form-field
        fi-select(
          v-model  = 'financialData.financialDataValue'
          :options = 'options'
          required
        )
    td
      fi-form-field
        fi-select(
          v-model = 'financialData.financialDataAmountPeriod'
          :options = 'periods'
          required
        )
    td
      fi-form-field
        fi-money-input(v-model = 'financialData.financialDataAmount')
    td
      fi-form-field
        fi-datepicker(v-model = 'financialData.expirationDate')
    td
      .btn-group.fa-pull-right.animated.fadeIn
        button.btn.btn-primary(
          :disabled      = 'loader'
          @click.prevent = '$emit("input", financialData)'
        ) {{ $t('common:save') }}
        button.btn.btn-secondary(
          :disabled      = 'loader'
          @click.prevent = '$emit("cancel")'
        ) {{ $t('common:cancel') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiDatepicker from '@/components/FiDatepicker'

export default {
  name: 'application-financial-data-edit-item',

  components: { FiDatepicker, FiMoneyInput, FiSelect, FiFormField },

  props: {
    value: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    periods: {
      type: Array,
      required: true
    },
    loader: Boolean
  },

  data: (vm) => ({
    financialData: { ...vm.value }
  })
}
</script>
