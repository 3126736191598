import { Expose } from 'class-transformer'

export class Contact {
  @Expose()
  id: number

  @Expose()
  value: string

  @Expose()
  main: boolean = true
}
