<template lang="pug">
tr.application-list-item
  td: router-link(:to = '{ name: "Application", params: { id: application.id }}') {{ application.id }}
  td
    router-link(
      v-if = 'application.customerId'
      :to  = '{ name: "Customer", params: { id: application.customerId }}'
    ) {{ customerName(customer) }} ({{ application.idCode }})
    template(v-else) {{ customerName(customer) }} ({{ application.idCode }})
  td {{ application.creditIssuerName }}
  td {{ application.productName }}
  td.money {{ application.amount | money }}
  td {{ $t('common:formatDate', { value: application.createdAt }) }}
  td
    template(v-if = 'application.employeeId') {{ this.managerById(application.employeeId).name }}
    template(v-else-if = '$vueLoading.isLoading(`applications:assignee:${application.id}:save`)') {{ $t('assigning') }}
      |
      i.fa.fa-spinner.fa-pulse
    button.btn.btn-link.my-0(
      v-else-if      = 'isManager'
      @click.prevent = 'onAssign'
    ) {{ $t('assignToMe') }}
  td: .badge(:class = '`badge-${applicationStatusColor(application.statusId)}`')  {{ applicationStatus.human }}
  td: .badge(
    v-if   = "application.loanId"
    :class = '`badge-${loanStatusColor(loanStatus.name)}`'
  )  {{ loanStatus.human }}
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'application-list-item',

  props: {
    application: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('classifiers', ['classifierById', 'managerById']),
    ...mapGetters('applications', ['applicationStatusColor', 'customerFromApplication']),
    ...mapGetters('loans', ['loanStatusColor']),
    ...mapGetters('customers', ['customerName']),
    applicationStatus () {
      return this.classifierById('creditApplicationStatuses', this.application.statusId)
    },
    loanStatus () {
      return this.classifierById('loanStatuses', this.application.loanStatusId)
    },
    isManager () {
      return Boolean(this.managerById(this.user.id).customerId)
    },
    customer () {
      return this.customerFromApplication(this.application)
    }
  },

  methods: {
    ...mapActions('applications', ['setApplicationAssignee']),
    onAssign () {
      this.setApplicationAssignee({ id: this.application.id, customerId: this.user.id })
    }
  }
}
</script>


<i18n>
en:
  assignToMe: "Assign to me"
  assigning:  "Assigning..."

et:
  assignToMe: "Assign to me"
  assigning:  "Assigning..."

ru:
  assignToMe: "Assign to me"
  assigning:  "Assigning..."
</i18n>
