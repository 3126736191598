<template lang="pug">
tr.application-related-persons-list-item
  td {{ classifierById('relationTypes', relatedPerson.relationType).human }}
  td {{ relatedPerson.role }}
  td {{ relatedPerson.firstName || relatedPerson.companyName }} {{ relatedPerson.lastName }}
  td {{ relatedPerson.registrationCode }}
  td {{ classifierById('countries', relatedPerson.registrationCountry).human }}
  td {{ relatedPerson.contactPhone }}
  td {{ relatedPerson.contactEmail }}
  td {{ relatedPerson.sinceWhen }}
  td
    .btn-toolbar
      .btn-group.btn-group-sm
        button.btn.btn-link(@click.prevent = 'showEditRelatedPerson = true')
          i.far.fa-edit.fa-fw.fa-lg
        button.btn.btn-link(
          :disabled      = 'deleteLoader'
          @click.prevent = 'remove'
        )
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'deleteLoader')
          i.far.fa-trash-alt.fa-lg.text-danger(v-else)
        button.btn.btn-link(@click.prevent = 'newRelationModal = true')
          i.far.fa-save.fa-fw.fa-lg
  application-related-persons-modal(
    v-if = 'showEditRelatedPerson'
    v-model      = 'showEditRelatedPerson'
    :product     = 'product'
    :source-data = 'application'
    :related-person = 'relatedPerson'
    :loader      = 'loader'
    @submit      = '$emit("edit", $event)'
  )
  application-new-customer-modal(
    v-model   = 'newRelationModal'
    :related-person = 'relatedPerson'
  )
</template>


<script>
import ApplicationRelatedPersonsModal from '@/views/applications/ApplicationFactoring/ApplicationRelatedPersonsModal'
import ApplicationNewCustomerModal from '@/views/applications/ApplicationFactoring/ApplicationNewCustomerModal'
import CustomerRelationNewModal from '@/views/customers/CustomerRelationNewModal'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'application-related-persons-list-item',

  components: { ApplicationRelatedPersonsModal, CustomerRelationNewModal, ApplicationNewCustomerModal },

  props: {
    relatedPerson: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    application: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    showEditRelatedPerson: false,
    newRelationModal: false
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'classifierByName']),
    ...mapState('applications', ['applicationCustomer']),
    loader () {
      return this.$vueLoading.isLoading(`factoring:application:relatedPerson:${this.relatedPerson.customerId}:save`)
    },
    deleteLoader () {
      return this.$vueLoading.isLoading(`factoring:application:relatedPerson:${this.relatedPerson.customerId}:delete`)
    }
  },

  methods: {
    ...mapActions('customers', ['createCustomerRelationInApp']),
    remove () {
      this.$emit('remove', { relatedPersonId: this.relatedPerson?.customerId })
    },
    async saveRelation ({ data, done }) {
      data.customerId = this.applicationCustomer.id
      await this.createCustomerRelationInApp(data)
      // eslint-disable-next-line no-unused-expressions
      done?.()
    }
  }
}
</script>
