<template lang="pug">
.application-score-negative-note.card.border-warning
  .card-body(v-if = 'editing')
    form.row(@submit.prevent = 'onSubmit')
      .form-group.col.mb-0.animated.fadeIn
        textarea.form-control(v-model.trim = 'approvingData.comment'
                              :placeholder = '$t("commentPlaceholder")')
      .col-auto.pl-0
        button.btn.btn-primary(:disabled = 'saving'
                               type      = 'submit')
          | {{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(:disabled      = 'saving'
                                 @click.prevent = 'toggleEdit') {{ $t('common:cancel') }}
  .card-body(v-else)
    .fa-pull-left: p.comment.mb-0.animated.fadeIn {{ application.negativeScoringComment || $t('emptyCommentPlaceholder') }}
    a.float-right(v-if           = 'isEditable'
                  href           = '#'
                  @click.prevent = 'toggleEdit')
      template(v-if = 'application.negativeScoringComment') {{ $t('editNote') }}
      template(v-else) {{ $t('addNote') }}
</template>


<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'application-score-negative-note',

  props: {
    isEditable: {
      type: Boolean,
      default: false
    }
  },

  i18nOptions: {},

  data () {
    return {
      editing: false,
      approvingData: {
        comment: null
      }
    }
  },

  computed: {
    ...mapState('applications', ['application']),
    saving () {
      return this.$vueLoading.isLoading('application:negativeScoreNote:save')
    }
  },

  methods: {
    ...mapActions('applications', ['approveNegativeScoring']),
    toggleEdit () {
      if (!this.editing) this.resetData()
      this.editing = !this.editing
    },
    resetData () {
      this.approvingData.comment = this.application.negativeScoringComment
    },
    async onSubmit () {
      await this.approveNegativeScoring({ approvingData: this.approvingData })
      this.editing = false
    }
  }
}
</script>


<i18n>
en:
  emptyCommentPlaceholder: "Negative scoring result"
  commentPlaceholder:      "Enter reason for approving negative scoring"
  addNote:                 "Approve negative score"
  editNote:                "Edit approval"
et:
  emptyCommentPlaceholder: "Negative scoring result"
  commentPlaceholder:      "Enter reason for approving negative scoring"
  addNote:                 "Approve negative score"
  editNote:                "Edit approval"
ru:
  emptyCommentPlaceholder: "Negative scoring result"
  commentPlaceholder:      "Enter reason for approving negative scoring"
  addNote:                 "Approve negative score"
  editNote:                "Edit approval"
</i18n>


<style lang="scss">
p.comment { white-space: pre-line; }
</style>
