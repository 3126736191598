<template lang="pug">
.application-score-block.card
  .card-header {{ title }}
  .card-body
    slot
      application-score-item(
        v-bind  = '$attrs'
        :loader = '`application:scoreRequest:${$attrs.type}:save`'
        @score  = '$emit("score", $event)'
      )
</template>


<script>
import ApplicationScoreItem from '@/views/applications/ApplicationScoreItem'

export default {
  name: 'application-score-block',

  components: { ApplicationScoreItem },

  inheritAttrs: false,

  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
