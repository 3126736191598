<template lang="pug">
.application-data-form-fields.row.animated.fadeIn
  .col
    fi-form-field(
      :label = '$t("amount")'
      :state = '!applicationData.creditAmount.$error'
    )
      template(#error)
        .error-message(v-if = '!applicationData.creditAmount.required') {{ $t('common:required') }}
        .error-message(v-if = '!applicationData.creditAmount.gte') {{ formatRangeError('gte', applicationData.creditAmount.$params.gte.value) }}
        .error-message(v-if = '!applicationData.creditAmount.lte') {{ formatRangeError('lte', applicationData.creditAmount.$params.lte.value) }}
      v-loading(loader = 'application:product:fetch')
        template(#spinner): i.fa.fa-spinner.fa-pulse
        fi-money-input.form-control.form-control-sm(
          v-model.number = 'applicationData.creditAmount.$model'
          type           = 'text'
          :class         = '{ "is-invalid": applicationData.creditAmount.$error }'
          :disabled      = 'applicationData.creditAmount.$params.lte.value === applicationData.creditAmount.$params.gte.value'
        )
    template(v-if = 'isLeasingGroup')
      fi-form-field(
        v-if   = 'product.principalWithVat'
        :label = '$t("amountWithVat")'
      )
        v-loading(loader = 'taxation:convert:save')
          template(#spinner)
            i.fa.fa-spinner.fa-pulse
          fi-money-input.form-control.form-control-sm(
            v-model  = 'applicationData.loanAmountWithVat.$model'
            type     = 'text'
            disabled
          )

      fi-form-field(
        :label = '$t("upfrontAmount")'
        :state = '!(applicationData.upfrontAmount.$error || applicationData.upfrontAmountCalculationTypeId.$error)'
      )
        template(#error)
          .error-message(v-if = '!applicationData.upfrontAmountCalculationTypeId.required') {{ $t('common:required') }}
          .error-message(v-if = '!applicationData.upfrontAmount.required') {{ $t('common:required') }}
          .error-message(v-if = '!applicationData.upfrontAmount.gte') {{ formatRangeError('gte', applicationData.upfrontAmount.$params.gte.value, percentageUpfrontAmount) }}
          .error-message(v-if = '!applicationData.upfrontAmount.lte') {{ formatRangeError('lte', applicationData.upfrontAmount.$params.lte.value, percentageUpfrontAmount) }}
        .row
          .col
            fi-select(
              v-model.number = 'applicationData.upfrontAmountCalculationTypeId.$model'
              :options       = 'leasingCalculationTypes'
              :class         = '{ "is-invalid": applicationData.upfrontAmountCalculationTypeId.$error }'
              :disabled      = '!product.upfrontAmountCalculationTypeIdOverride'
              required
              sm
            )
          .col
            percent-input.form-control.form-control-sm(
              v-if           = 'percentageUpfrontAmount'
              v-model.number = 'applicationData.upfrontAmount.$model'
              :class         = '{ "is-invalid": applicationData.upfrontAmount.$error }'
              :disabled      = '!applicationData.upfrontAmountCalculationTypeId || !product.upfrontAmountOverride'
            )
            fi-money-input.form-control.form-control-sm(
              v-else
              v-model.number = 'applicationData.upfrontAmount.$model'
              :class         = '{ "is-invalid": applicationData.upfrontAmount.$error }'
              :disabled      = '!applicationData.upfrontAmountCalculationTypeId || !product.upfrontAmountOverride'
            )
          .col
            .form-control-plaintext(v-if = 'percentageUpfrontAmount') {{ upfrontAmountInform | money }}
            .form-control-plaintext(v-else) {{ upfrontAmountInform | percent }}

      fi-form-field(
        :label = '$t("residualAmount")'
        :state = '!(applicationData.residualAmount.$error || applicationData.residualAmountCalculationTypeId.$error)'
      )
        template(#error)
          .error-message(v-if = '!applicationData.residualAmountCalculationTypeId.required') {{ $t('common:required') }}
          .error-message(v-if = '!applicationData.residualAmount.required') {{ $t('common:required') }}
          .error-message(v-if = '!applicationData.residualAmount.gte') {{ formatRangeError('gte', applicationData.residualAmount.$params.gte.value, percentageResidualAmount) }}
          .error-message(v-if = '!applicationData.residualAmount.lte') {{ formatRangeError('lte', applicationData.residualAmount.$params.lte.value, percentageResidualAmount) }}
        .row
          .col
            fi-select(
              v-model.number = 'applicationData.residualAmountCalculationTypeId.$model'
              :options       = 'leasingCalculationTypes'
              :class         = '{ "is-invalid": applicationData.residualAmountCalculationTypeId.$error }'
              :disabled      = '!product.residualAmountCalculationTypeIdOverride'
              required
              sm
            )
          .col
            percent-input.form-control.form-control-sm(
              v-if           = 'percentageResidualAmount'
              v-model.number = 'applicationData.residualAmount.$model'
              :class         = '{ "is-invalid": applicationData.residualAmount.$error }'
              :disabled      = '!applicationData.residualAmountCalculationTypeId || !product.residualAmountOverride'
            )
            fi-money-input.form-control.form-control-sm(
              v-else
              v-model.number = 'applicationData.residualAmount.$model'
              :class         = '{ "is-invalid": applicationData.residualAmount.$error }'
              :disabled      = '!applicationData.residualAmountCalculationTypeId || !product.residualAmountOverride'
            )
          .col
            .form-control-plaintext(v-if = 'percentageResidualAmount') {{ residualAmountInform | money }}
            .form-control-plaintext(v-else) {{ residualAmountInform | percent }}

    fi-form-field(
      :label = '$t("period")'
      :state = '!applicationData.loanLength.$error'
    )
      template(#error)
        .error-message(v-if = '!applicationData.loanLength.required') {{ $t('common:required') }}
        .error-message(v-if = '!applicationData.loanLength.gte') {{ $t('gte', { value: applicationData.loanLength.$params.gte.value }) }}
        .error-message(v-if = '!applicationData.loanLength.lte') {{ $t('lte', { value: applicationData.loanLength.$params.lte.value }) }}

      v-loading(loader = 'application:product:fetch')
        template(#spinner): i.fa.fa-spinner.fa-pulse
        .row
          .col
            input.form-control.form-control-sm(
              v-model.number = 'applicationData.loanLength.$model'
              type           = 'text'
              :class         = '{ "is-invalid": applicationData.loanLength.$error }'
              :disabled      = 'applicationData.loanLength.$params.lte.value === applicationData.loanLength.$params.gte.value'
            )
          .col
            input.form-control-plaintext(
              :value = 'periodUnit'
              readonly
            )
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiMoneyInput from '@/components/FiMoneyInput'
import PercentInput from '@/components/PercentInput'
import { formatRangeError, isApplicationLeasing } from '@/mixins'
import debounce from 'lodash/debounce'
import { calculationTypes, convertFee, feePercentage } from '@/helpers'

export default {
  name: 'application-data-form-fields',

  components: {
    FiFormField,
    FiSelect,
    FiMoneyInput,
    PercentInput
  },

  mixins: [isApplicationLeasing, formatRangeError],

  props: {
    product: {
      type: Object,
      required: true
    },
    applicationData: {
      type: Object,
      required: true
    },
    feeConfigs: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'classifierByName', 'classifierList']),
    ...mapGetters('settings', ['settingByKey']),
    periodUnit () {
      const periodUnit = this.classifierById('periodUnitType', this.product.periodUnitTypeId)
      return this.$t((periodUnit.name || '').toLowerCase(), { count: this.applicationData.loanLength.$model || 0 })
    },
    upfrontAmountInform () {
      return convertFee({
        feeValue: this.applicationData.upfrontAmount.$model,
        isPercentage: !this.percentageUpfrontAmount,
        amountValue: this.applicationData.creditAmount.$model
      })
    },
    residualAmountInform () {
      return convertFee({
        feeValue: this.applicationData.residualAmount.$model,
        isPercentage: !this.percentageResidualAmount,
        amountValue: this.applicationData.creditAmount.$model
      })
    },
    percentageUpfrontAmount () {
      return feePercentage(this.classifierById('feeCalculationType', this.applicationData.upfrontAmountCalculationTypeId.$model).code)
    },
    percentageResidualAmount () {
      return feePercentage(this.classifierById('feeCalculationType', this.applicationData.residualAmountCalculationTypeId.$model).code)
    },
    leasingCalculationTypes () {
      return calculationTypes(
        { feeType: 'CUSTOM_FEE' },
        this.feeConfigs,
        this.classifierList('feeCalculationType'),
        true
      )
    }
  },

  watch: {
    async 'applicationData.creditAmount.$model' (val) {
      if (!val || !this.product.principalWithVat) {
        return
      }
      this.onAmountChange(val)
    },
    'applicationData.upfrontAmountCalculationTypeId.$model' (value, oldValue) {
      if (value === this.applicationData.upfrontAmountCalculationTypeId.$model && !oldValue) return
      this.applicationData.upfrontAmount.$model = convertFee({
        feeValue: this.applicationData.upfrontAmount.$model,
        isPercentage: this.percentageUpfrontAmount,
        amountValue: this.applicationData.creditAmount.$model
      })
    },
    'applicationData.residualAmountCalculationTypeId.$model' (value, oldValue) {
      if (value === this.applicationData.residualAmountCalculationTypeId.$model && !oldValue) return
      this.applicationData.residualAmount.$model = convertFee({
        feeValue: this.applicationData.residualAmount.$model,
        isPercentage: this.percentageResidualAmount,
        amountValue: this.applicationData.creditAmount.$model
      })
    }
  },

  methods: {
    ...mapActions('taxation', ['convertAmount']),
    onAmountChange: debounce(
      async function (amount) {
        this.applicationData.loanAmountWithVat.$model = await this.convertAmount({
          amountData: {
            amount,
            amountWithVat: false
          }
        })
      }, 500
    )
  }
}
</script>


<i18n>
en:
  amount:                          "Amount"
  period:                          "Period"
  month:                           "month"
  month_plural:                    "months"
  day:                             "day"
  day_plural:                      "days"
  gte:                             "Field value should be greater or equal to {{value}}"
  lte:                             "Field value should be less or equal to {{value}}"
  residualAmount:                  "Residual amount"
  residualAmountCalculationTypeId: "Residual amount method"
  upfrontAmount:                   "Upfront amount"
  upfrontAmountCalculationTypeId:  "Upfront amount method"
  amountWithVat:                   "Amount with VAT"
et:
  amount:                          "Amount"
  period:                          "Period"
  month:                           "month"
  month_plural:                    "months"
  day:                             "day"
  day_plural:                      "days"
  monthlyIncome:                   "Monthly income"
  monthlyObligations:              "Monthly loan obligations"
  employmentLength:                "Current employment length in months"
  documentType:                    "Document type"
  idDocPlaceholder:                "Select document type"
  documentNumber:                  "Document number"
  gte:                             "Field value should be greater or equal to {{value}}"
  lte:                             "Field value should be less or equal to {{value}}"
  residualAmount:                  "Residual amount"
  residualAmountCalculationTypeId: "Residual amount method"
  upfrontAmount:                   "Upfront amount"
  upfrontAmountCalculationTypeId:  "Upfront amount method"
  amountWithVat:                   "Amount with VAT"
  expireDate:                      "Expire date: {{ idDocExpire }}"
ru:
  amount:                          "Amount"
  period:                          "Period"
  month_0:                         "месяц"
  month_1:                         "месяца"
  month_2:                         "месяцев"
  day_0:                           "день"
  day_1:                           "дня"
  day_2:                           "дней"
  monthlyIncome:                   "Monthly income"
  monthlyObligations:              "Monthly loan obligations"
  employmentLength:                "Current employment length in months"
  documentType:                    "Document type"
  idDocPlaceholder:                "Select document type"
  documentNumber:                  "Document number"
  gte:                             "Field value should be greater or equal to {{value}}"
  lte:                             "Field value should be less or equal to {{value}}"
  residualAmount:                  "Residual amount"
  residualAmountCalculationTypeId: "Residual amount method"
  upfrontAmount:                   "Upfront amount"
  upfrontAmountCalculationTypeId:  "Upfront amount method"
  amountWithVat:                   "Amount with VAT"
  expireDate:                      "Expire date: {{ idDocExpire }}"
</i18n>
