<template lang="pug">
.application-status-row
  .card-group.mb-4
    .card
      .card-body(:class = '{ "bg-primary": isInserted }')
        h4.mb-0 {{ $t('inserted') }}
        p.mb-0 {{ $t('common:formatDate', { value: application.createdAt }) }}
    .card
      .card-body(:class = '{ "bg-primary": isInProgress }')
        template(v-if = 'historyInProgress')
          application-assign-dropdown.fa-pull-right(
            v-if         = '!isClosed'
            :application = 'application'
            :class       = '{ "colored": !isInProgress }'
            :loader      = 'assignLoader'
            no-caret
            @assign      = 'onAssign'
          )
          h4.mb-0 {{ $t('assigned') }}
          p.mb-0 {{ assignedManager }}
          p.mb-0 {{ $t('common:formatDate', { value: application.lastAssignDate }) }}
        h4.mb-0(v-else-if = 'isInserted && isManager')
          v-loading(:loader = 'assignLoader')
            template(#spinner)
              | {{ $t('assigning') }}
              |
              i.fa.fa-spinner.fa-pulse
            a(
              href           = '#'
              @click.prevent = 'onAssign({ customerId: user.id })'
            ) {{ $t('assign') }}
        h4.mb-0.text-secondary(v-else) {{ $t('assign') }}

    .card
      .card-body(:class = '{ "bg-primary": inAnalysis }')
        template(v-if = 'inAnalysis')
          h4.mb-0 {{ $t('analysis') }}
        h4.mb-0(
          v-else
          :class = '{ "text-secondary": isInserted }'
        )
          a(
            v-if         = '!negativeResolution && !isInserted && !isContractPrepared && !isClosed'
            href         = '#'
            v-fi-confirm = '{ ok: onSetAnalysis }'
          ) {{ $t('analysis') }}
          template(v-else) {{ $t('analysis') }}
          i.fa.fa-spinner.fa-pulse(v-if = '$vueLoading.isLoading("application:analysis:save")')

    .card
      .card-body(:class = '{ "bg-primary": isOfferSent }')
        h4.mb-0(:class = '{ "text-secondary": isInserted }')
          a(
            v-if           = '!negativeResolution && !isContractPrepared && !isInserted && !isClosed'
            :class         = '{ "text-white": !isInProgress && isOfferSent}'
            href           = '#'
            @click.prevent = 'showSendOfferModal = true'
          ) {{ $t('sendOffer') }}
          template(v-else) {{ $t('sendOffer') }}
        p.mb-0(v-if = 'historyOfferSent') {{ $t('offerSent') }} : {{ $t('common:formatDate', { value: historyOfferSent.createdAt }) }}

    .card
      .card-body(:class = '{ "bg-primary": isContractPrepared }')
        template(v-if = 'isContractPrepared')
          h4.mb-0 {{ $t('contract') }}
          p.mb-0 {{ $t('common:formatDate', { value: historyStatus(application.statusId).createdAt }) }}
        h4.mb-0(
          v-else
          :class = '{ "text-secondary": isInserted }'
        )
          a(
            v-if         = '!negativeResolution && !isInserted && !isClosed'
            href         = '#'
            v-fi-confirm = '{ ok: onPrepareContract, loader: "application:prepareContract:save" }'
          ) {{ $t('prepareContract') }}
          template(v-else) {{ $t('prepareContract') }}
          i.fa.fa-spinner.fa-pulse(v-if = '$vueLoading.isLoading("application:prepareContract:save")')

    .card
      .card-body(:class = '{ "bg-primary": isClosed }')
        template(v-if = 'historyClosed')
          h4.mb-0 {{ $t('closed') }}
          p.mb-0 {{ $t('common:formatDatetime', { value: historyClosed.createdAt }) }}
        h4.mb-0(v-else)
          a(
            href           = '#'
            @click.prevent = 'showCloseModal = true'
          ) {{ $t('close') }}

  application-send-offer-modal(
    v-model  = 'showSendOfferModal'
    :workflow = 'workflow'
  )
  application-close-modal(
    v-model     = 'showCloseModal'
    :is-private = 'workflow.privateCustomer'
  )
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import WorkflowPolicy from '@/policies/WorkflowPolicy'
import ApplicationSendOfferModal from './ApplicationSendOfferModal'
import ApplicationCloseModal from './ApplicationCloseModal'
import ApplicationAssignDropdown from '@/views/applications/ApplicationAssignDropdown'

export default {
  name: 'application-status-row',

  components: {
    ApplicationSendOfferModal,
    ApplicationCloseModal,
    ApplicationAssignDropdown
  },

  props: {
    workflow: {
      type: WorkflowPolicy,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      showSendOfferModal: false,
      showCloseModal: false
    }
  },

  computed: {
    ...mapState('applications', ['application', 'applicationLoanId']),
    ...mapState('auth', ['user']),
    ...mapState('classifiers', ['managers']),
    ...mapGetters('applications', ['historyStatus']),
    ...mapGetters('classifiers', ['classifierById', 'classifierByName', 'managerById', 'productTypeById']),
    negativeResolution () {
      return this.application.resolution.some(({ bootstrap }) => bootstrap.toLowerCase() === 'danger')
    },
    currentStatus () {
      return this.classifierById('creditApplicationStatuses', this.application.statusId)
    },
    isInserted () {
      return this.currentStatus.name === 'inserted'
    },
    isInProgress () {
      return this.currentStatus.name === 'inProgress'
    },
    isOfferSent () {
      return this.currentStatus.name === 'offerSent'
    },
    isContractPrepared () {
      return this.currentStatus.name === 'contractPrepared'
    },
    isClosed () {
      return this.currentStatus.name === 'closed'
    },
    inAnalysis () {
      return this.currentStatus.name === 'inAnalysis'
    },
    historyInProgress () {
      return this.historyStatus(this.classifierByName('creditApplicationStatuses', 'inProgress')?.id)
    },
    historyOfferSent () {
      return this.historyStatus(this.classifierByName('creditApplicationStatuses', 'offerSent')?.id)
    },
    historyClosed () {
      return this.historyStatus(this.classifierByName('creditApplicationStatuses', 'closed')?.id)
    },
    closeReason () {
      const classifierName = this.workflow.privateCustomer ? 'privateReasonsForClosingApp' : 'businessReasonsForClosingApp'
      return this.classifierById(classifierName, this.application.closeReasonId)?.human?.toUpperCase()
    },
    assignedManager () {
      return this.application.employeeId && (this.managerById(this.application.employeeId).name || '').toUpperCase()
    },
    assignLoader () {
      return `applications:assignee:${this.application.id}:save`
    },
    isManager () {
      return Boolean(this.managerById(this.user.id).customerId)
    }
  },

  methods: {
    ...mapActions('applications', ['setApplicationAssignee', 'prepareContract', 'setApplicationAnalysis']),
    onAssign ({ customerId }) {
      this.setApplicationAssignee({ id: this.application.id, customerId })
    },
    async onPrepareContract ({ done }) {
      await this.prepareContract()
      done()
      if (this.workflow.isFactoring) {
        this.$router.push({ name: 'FactoringContract', params: { id: this.applicationLoanId } })
      } else {
        this.$router.push({ name: 'Loan', params: { id: this.applicationLoanId } })
      }
    },
    async onSetAnalysis ({ done }) {
      await this.setApplicationAnalysis({ id: this.application.id })
      done()
    }
  }
}
</script>


<style scoped lang="scss">
p.comment {
  white-space: pre-line;
}
</style>


<i18n>
en:
  inserted:        "Inserted"
  assigned:        "Assigned"
  assigning:       "Assigning"
  assign:          "Assign"
  assignTo:        "Assign to"
  sendOffer:       "Send offer"
  offerSent:       "Offer sent"
  analysis:        "In analysis"
  prepareContract: "Prepare contract"
  contract:        "Contract"
  close:           "Close"
  closed:          "Closed"
et:
  inserted:        "Inserted"
  assigned:        "Assigned"
  assigning:       "Assigning"
  assign:          "Assign"
  assignTo:        "Assign to"
  sendOffer:       "Send offer"
  offerSent:       "Offer sent"
  analysis:        "In analysis"
  prepareContract: "Prepare contract"
  contract:        "Contract"
  close:           "Close"
  closed:          "Closed"
ru:
  inserted:        "Inserted"
  assigned:        "Assigned"
  assigning:       "Assigning"
  assign:          "Assign"
  assignTo:        "Assign to"
  sendOffer:       "Send offer"
  offerSent:       "Offer sent"
  analysis:        "In analysis"
  prepareContract: "Prepare contract"
  contract:        "Contract"
  close:           "Close"
  closed:          "Closed"
</i18n>
