<template lang="pug">
.application-data.card
  .card-header {{ $t('title') }}
    button.btn.btn-link.py-0.fa-pull-right.animated.fadeIn(
      v-if           = 'isEditable && !editing'
      :title         = '$t("common:edit")'
      @click.prevent = 'toggleEdit()'
    ): i.far.fa-edit.fa-lg
  .card-body
    form.form-horizontal(@submit.prevent = 'onSubmit')

      application-data-form-fields(
        v-if              = 'editing'
        :product          = 'applicationProduct'
        :application-data = '$v.applicationData'
        :fee-configs       = 'feeConfigs'
      )
      application-data-fields(v-else
        :application      = 'application'
        :product          = 'applicationProduct'
        :leasing          = 'isLeasingGroup'
        :fee-configs       = 'feeConfigs'
      )

      fi-form-field(
        v-if   = 'application.acceptTerms'
        :label = '$t("acceptTerms")'
      )
        p {{ application.acceptTermsText }}

      .form-text.alert.alert-danger.animated.fadeIn(v-if = 'error') {{ error }}

      template(v-if = 'editing')
        button.btn.btn-primary(
          type      = 'submit'
          :disabled = 'saving'
        )
          | {{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'toggleEdit'
        ) {{ $t('common:cancel') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import pick from 'lodash/pick'
import merge from 'lodash/merge'
import { mapActions, mapGetters, mapState } from 'vuex'
import ApplicationDataFormFields from './ApplicationDataFormFields'
import ApplicationDataFields from './ApplicationDataFields'
import { rules, validation, validators } from '@/validation'
import { isApplicationLeasing } from '@/mixins'
import { convertFeeRange, feePercentage } from '@/helpers'
import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer'
import { CApplicationDataFields, UpdateTypes } from '@/models/application/CApplication.ts'
import WorkflowPolicy from '@/policies/WorkflowPolicy'

export default {
  name: 'application-data',

  components: {
    FiFormField,
    ApplicationDataFormFields,
    ApplicationDataFields
  },

  mixins: [validation, isApplicationLeasing],

  props: {
    isEditable: {
      type: Boolean,
      default: false
    },
    feeConfigs: {
      type: Object,
      required: true
    },
    workflow: {
      type: WorkflowPolicy,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      editing: false,
      error: null,
      applicationData: plainToClass(CApplicationDataFields, {}, { groups: this.workflow.groups })
    }
  },

  computed: {
    ...mapState('applications', ['application', 'applicationProduct']),
    ...mapState('products', ['productsList']),
    ...mapGetters('classifiers', ['classifierById']),
    saving () {
      return this.$vueLoading.isLoading(`application:${this.application.id}:save`)
    },
    percentageUpfrontAmount () {
      return feePercentage(this.classifierById('feeCalculationType', this.applicationData.upfrontAmountCalculationTypeId).code)
    },
    percentageResidualAmount () {
      return feePercentage(this.classifierById('feeCalculationType', this.applicationData.residualAmountCalculationTypeId).code)
    },
    convertedResidualRange () {
      const residualAmount = {
        feeCalculationType: this.classifierById('feeCalculationType', this.applicationData.residualAmountCalculationTypeId),
        feeMin: this.applicationProduct.residualAmountMin,
        feeMax: this.applicationProduct.residualAmountMax
      }
      return convertFeeRange(residualAmount, this.applicationData.creditAmount)
    },
    convertedUpfrontRange () {
      const upfrontAmount = {
        feeCalculationType: this.classifierById('feeCalculationType', this.applicationData.upfrontAmountCalculationTypeId),
        feeMin: this.applicationProduct.upfrontAmountMin,
        feeMax: this.applicationProduct.upfrontAmountMax
      }
      return convertFeeRange(upfrontAmount, this.applicationData.creditAmount)
    }
  },

  validations () {
    const mergedRules = { ...rules.applicationRules, ...rules.customerRules }
    return {
      applicationData: merge({}, pick(mergedRules, Object.keys(this.applicationData)), {
        creditAmount: {
          gte: validators.gte(this.applicationProduct.amountMin),
          lte: validators.lte(this.applicationProduct.amountMax)
        },
        loanLength: {
          gte: validators.gte(this.applicationProduct.periodMin),
          lte: validators.lte(this.applicationProduct.periodMax)
        },
        residualAmount: {
          required: validators.requiredIf(this.isLeasingGroup),
          gte: validators.gte(this.convertedResidualRange.feeMin),
          lte: validators.lte(this.convertedResidualRange.feeMax)
        },
        residualAmountCalculationTypeId: {
          required: validators.requiredIf(this.isLeasingGroup)
        },
        upfrontAmount: {
          required: validators.requiredIf(this.isLeasingGroup),
          gte: validators.gte(this.convertedUpfrontRange.feeMin),
          lte: validators.lte(this.convertedUpfrontRange.feeMax)
        },
        upfrontAmountCalculationTypeId: {
          required: validators.requiredIf(this.isLeasingGroup)
        }
      })
    }
  },

  methods: {
    ...mapActions('applications', ['updateApplication']),
    toggleEdit () {
      this.resetData()
      this.editing = !this.editing
    },
    resetData () {
      this.applicationData = plainToClassFromExist(this.applicationData, this.application, {
        exposeUnsetFields: false,
        excludeExtraneousValues: true,
        groups: this.workflow.groups
      })
      this.$v.$reset()
      this.error = null
    },
    async onSubmit () {
      if (this.validate()) {
        try {
          this.error = null
          await this.updateApplication({ id: this.application.id, applicationData: classToPlain(this.applicationData, { groups: this.workflow.groups }), type: UpdateTypes.ApplicationData })
          this.editing = false
        } catch (e) {
          this.error = e
        }
      }
    }
  }
}
</script>


<i18n>
en:
  title:       "Application data"
  acceptTerms: "Application terms"
et:
  title:       "Application data"
  acceptTerms: "Application terms"
ru:
  title:       "Application data"
  acceptTerms: "Application terms"
</i18n>
