import { Expose, plainToClass, Transform, Type } from 'class-transformer'
import { Contact } from '@/models/Contact'
import { CAddress } from '@/models/CAddress'
import { CClassifier } from '@/models/CClassifier'

export class CustomerFromApplicationAdapter {
  @Expose({ groups: ['corporate'] })
  @Transform(({ obj }) => obj.corporateApplication.companyName, { groups: ['corporate'] })
  customerName: string

  @Expose({ groups: ['corporate'] })
  @Transform(({ obj }) => obj.corporateApplication.naceId, { groups: ['corporate'] })
  naceId: string

  @Expose({ groups: ['corporate'] })
  @Transform(({ obj }) => obj.corporateApplication.emtakId, { groups: ['corporate'] })
  emtakId: string

  @Expose({ groups: ['corporate'] })
  @Transform(({ obj }) => obj.corporateApplication.vatNumber, { groups: ['corporate'] })
  vatNumber: string

  @Expose({ groups: ['corporate'] })
  @Transform(({ obj }) => obj.corporateApplication.registeredCapital, { groups: ['corporate'] })
  registeredCapital: string

  @Expose()
  @Transform(({ obj }) => obj.corporateApplication.companyRegistrationNumber, { groups: ['corporate'] })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'] })
  idCode: string

  @Expose({ groups: ['private'] })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'] })
  firstName: string

  @Expose({ groups: ['private'] })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'] })
  lastName: string


  @Expose({ groups: ['private'] })
  @Transform(({ obj }) => obj.privateApplication.maritalStatusId, { groups: ['private'] })
  @Type(() => CClassifier)
  maritalStatus: CClassifier

  @Expose()
  sectorId: number

  @Expose()
  segmentId: number

  @Expose()
  @Type(() => Contact)
  @Transform(({ obj }) => [plainToClass(Contact, { value: obj.corporateApplication.officialPhone }, { excludeExtraneousValues: true, exposeDefaultValues: true })], { groups: ['corporate'] })
  @Transform(({ obj }) => [plainToClass(Contact, { value: obj.privateApplication.phoneNumber }, { excludeExtraneousValues: true, exposeDefaultValues: true })], { groups: ['private'] })
  phoneNumbers: Contact[] = []

  @Expose()
  @Type(() => Contact)
  @Transform(({ obj }) => [plainToClass(Contact, { value: obj.corporateApplication.officialEmail }, { excludeExtraneousValues: true, exposeDefaultValues: true })], { groups: ['corporate'] })
  @Transform(({ obj }) => [plainToClass(Contact, { value: obj.privateApplication.email }, { excludeExtraneousValues: true, exposeDefaultValues: true })], { groups: ['private'] })
  emails: Contact[] = []

  @Expose()
  @Type(() => CAddress)
  @Transform(({ obj }) => [plainToClass(CAddress, obj, { excludeExtraneousValues: true, exposeDefaultValues: true })])
  addresses: CAddress[] = []

  @Expose()
  identityAuthenticated: boolean

  @Expose()
  @Transform(({ obj }) => obj.isInBlacklist)
  isInBlacklist: boolean

  @Expose()
  @Transform(({ obj }) => obj.acceptPep)
  acceptPep: boolean

  @Expose()
  @Transform(({ obj }) => obj.existInSanctions)
  existInSanctions: boolean

  @Expose({ groups: ['private'] })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'] })
  idDocId: number

  @Expose({ groups: ['private'] })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'] })
  idDocNumber: string

  @Expose({ groups: ['private'] })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'] })
  monthlyIncome: number

  @Expose({ groups: ['private'] })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'] })
  monthlyObligations: number

  @Expose({ groups: ['private'] })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'] })
  employmentLength: number

  @Expose({ groups: ['private'] })
  @Transform(({ obj, key }) => obj.privateApplication[key], { groups: ['private'] })
  dependantNumber: number
}
