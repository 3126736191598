<template lang="pug">
.card.application-customer
  b-tabs(
    v-model.number = 'currentTab'
    card
  )
    b-tab(
      :title = '$t("applicationCustomer")'
      lazy
    )
      v-loading(:loader = '["application:customer:fetch", "customer:custom-fields:fetch"]')
        .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg
        customer-data-fields(
          :customer = 'customer'
          :compare  = 'applicationCustomer'
          details
        )
        button.btn.btn-primary(
          v-if           = '!customer.identityAuthenticated'
          @click.prevent = 'showNewCustomerModal = true'
        ) {{ $t('confirmIdentity') }}
        button.btn.btn-outline-primary(@click.prevent = '$emit("copy")') {{ $t('copyApplication') }}
      application-customer-new-modal(
        v-if         = 'showNewCustomerModal'
        v-model      = 'showNewCustomerModal'
        :application = 'application'
        :customer    = 'customer'
      )
    b-tab(
      :title = '$t("customFields")'
      lazy
    )
      fi-custom-field-item(
        v-for    = 'field in customFieldsValues'
        :key     = 'field.id'
        :options = 'field.customField'
        :value   = 'field.customFieldValue'
      )
</template>


<script>
import { mapActions, mapState } from 'vuex'
import CustomerDataFields from '@/views/customers/CustomerDataFields'
import ApplicationCustomerNewModal from './ApplicationCustomerNewModal'
import FiCustomFieldItem from '@/components/FiCustomFieldItem'

export default {
  name: 'application-customer',

  components: {
    FiCustomFieldItem,
    CustomerDataFields,
    ApplicationCustomerNewModal
  },

  props: {
    customer: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      showNewCustomerModal: false,
      currentTab: 0
    }
  },

  computed: {
    ...mapState('applications', ['application', 'applicationCustomer']),
    ...mapState('customers', ['customFieldsValues'])
  },

  async created () {
    await this.loadCustomerCustomFields({ customerId: this.application.customerId })
  },

  methods: {
    ...mapActions('customers', ['loadCustomerCustomFields'])
  }
}
</script>


<i18n>
en:
  applicationCustomer: "Customer data"
  customFields:        "Customer custom fields"
  confirmIdentity:     "Confirm customer's identity"
  copyApplication:     "Copy application"
et:
  applicationCustomer: "Customer data"
  customFields:        "Customer custom fields"
  confirmIdentity:     "Confirm customer's identity"
  copyApplication:     "Copy application"
ru:
  applicationCustomer: "Customer data"
  customFields:        "Customer custom fields"
  confirmIdentity:     "Confirm customer's identity"
  copyApplication:     "Copy application"
</i18n>
