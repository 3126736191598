<template lang="pug">
.application-communication
  v-loading(loader = 'customer:fetch')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg
    customer-communication-view(
      store-module           = 'applications'
      resources-state        = 'applicationResources'
      resources-pages-state  = 'applicationResourcesPages'
      comments-state         = 'applicationComments'
      commentable-type       = 'APPLICATION'
      :resource-action       = 'resourceAction'
      messages-state         = 'applicationMessages'
      messages-pages-state   = 'applicationMessagesPages'
      customer-history-state = 'applicationCustomerHistory'
      customer-state         = 'applicationCustomer'
    )
</template>


<script>
import CustomerCommunicationView from '@/views/customers/CustomerCommunicationView'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'application-communication',

  components: { CustomerCommunicationView },

  computed: {
    ...mapState('applications', ['application', 'applicationCustomer'])
  },

  created () {
    this.loadCustomerHistory()
  },

  methods: {
    ...mapActions('customers', ['loadCustomer']),
    ...mapActions('applications', ['createApplicationResource', 'loadCustomerHistory']),
    async resourceAction ({ resourceData }) {
      await this.createApplicationResource({ resourceData, customerId: this.applicationCustomer.id, applicationId: this.application.id })
    }
  }
}
</script>
