<template lang="pug">
fi-modal.application-close-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("close")'
  size    = 'lg'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(
    novalidate
    @submit.prevent = 'onSubmit'
  )
    fi-form-field(
      :label     = '$t("closeReason")'
      label-cols = '2'
      :state     = '!$v.formData.closeReasonId.$error'
    )
      template(#error)
        .error-message(v-if = '!$v.formData.closeReasonId.required') {{ $t('common:required') }}
      fi-select(
        v-model  = 'formData.closeReasonId'
        :options = 'closingReasonOptions'
        :class         = '{ "is-invalid": $v.formData.closeReasonId.$error }'
        required
        sm
      )
    fi-form-field(
      :label     = '$t("closeComment")'
      label-cols = '2'
    )
      textarea.form-control.form-control-sm(v-model = 'formData.comment')
  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiModal from '@/components/FiModal'
import FiSelect from '@/components/FiSelect'
import { validation, validators } from '@/validation'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'application-close-modal',

  components: {
    FiFormField,
    FiSelect,
    FiModal
  },

  mixins: [validation],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    isPrivate: {
      type: Boolean,
      default: false
    }
  },

  i18nOptions: {},

  data () {
    return {
      formData: {
        closeReasonId: '',
        comment: ''
      }
    }
  },

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    saving () {
      return this.$vueLoading.isLoading('application:close:save')
    },
    closingReasonOptions () {
      const classifierName = this.isPrivate ? 'privateReasonsForClosingApp' : 'businessReasonsForClosingApp'

      return this.optionsFromClassifier(classifierName)
    }
  },

  validations: {
    formData: {
      closeReasonId: {
        required: validators.required
      }
    }
  },

  methods: {
    ...mapActions('applications', ['close']),
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.$v.$reset()
    },
    onHide (e) {
      if (this.saving) e.preventDefault()
    },
    async onSubmit () {
      if (this.validate()) {
        await this.close(this.formData)
        this.$refs.modal.hide()
      }
    }
  }
}
</script>


<i18n>
en:
  close:        "Close"
  closeReason:  "Reason"
  closeComment: "Comment"
et:
  close:        "Close"
  closeReason:  "Reason"
  closeComment: "Comment"
ru:
  close:        "Close"
  closeReason:  "Reason"
  closeComment: "Comment"
</i18n>
