import { Expose } from 'class-transformer'

export class CSeller {
  @Expose() address: string
  @Expose() apartmentNumber: string
  @Expose() city: string
  @Expose() countryId: number
  @Expose() countyId: number
  @Expose() customerName: string
  @Expose() email: string
  @Expose() houseNumber: string
  @Expose() idCode: string
  @Expose() phoneNumber: string
  @Expose() postalCode: string
  @Expose() sectorId: number
  @Expose() segmentId: number
  @Expose() sellerId: number
  @Expose() street: string
}
