<template lang="pug">
.application
  v-loading(loader = 'application:fetch')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg
    .animated.fadeIn(v-if = 'application')
      .application-header
        h3.header
          .header-item
            router-link(
              v-if = 'applicationCustomer'
              :to  = '{ name: "Customer", params: { id: applicationCustomer.id.toString() } }'
            ) {{ customerName(applicationCustomer) }} ({{ applicationCustomer.idCode }})
          .header-item {{ workflowPolicy.productType.human }}
          .header-item(v-if = 'application.creditIssuerId') {{ creditIssuerById(application.creditIssuerId).name }}
          .header-item(v-if = 'applicationProduct') {{ applicationProduct.name }}
        .row
          .col
            ul.nav.nav-pills.mb-2
              li.nav-item: router-link.nav-link(:to = '{ name: "ApplicationOverview" }') {{ $t('overview') }}
              li.nav-item: router-link.nav-link(
                v-if = 'isScoringEnabled'
                :to  = '{ name: "ApplicationScoring" }'
              ) {{ $t('scoring') }}
              li.nav-item: router-link.nav-link(
                v-if = 'applicationProduct.warrantyInfo'
                :to  = '{ name: "ApplicationCollateral" }'
              ) {{ $t('collateral') }}
              li.nav-item: router-link.nav-link(:to  = '{ name: "ApplicationCommunication" }') {{ $t('communication') }}
              li.nav-item: router-link.nav-link(:to  = '{ name: "ApplicationCustomerHistory" }') {{ $t('customerHistory') }}
              li.nav-item: router-link.nav-link(
                v-if = 'application.loanId'
                :to  = '{ name: "Loan", params: { id: application.loanId } }'
              ) {{ $t('contract') }}
              li.nav-item: router-link.nav-link(
                v-if = 'application.factoringId'
                :to  = '{ name: "FactoringContract", params: { id: application.factoringId } }'
              ) {{ $t('contract') }}
          v-loading.col-auto(loader = 'loan:product:fetch')
            i.fa.fa-spinner.fa-pulse.fa-lg(slot = 'spinner')
            .btn-toolbar
              .btn-group
                button.animated.fadeIn.btn.btn-outline-primary(@click = 'showSendMessage("CreditRecordPDFTemplate")')
                  i.fa.fa-print.fa-fw
      application-status-row(
        v-if = 'application'
        :workflow = 'workflowPolicy'
      )
      v-loading(loader = 'application:customer:fetch')
        .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg
        customer-note-list(
          v-if = 'false'
          :comments = 'applicationCustomerComments'
        )
      application-note.border-danger(
        v-for   = 'note in application.closedReason'
        :key    = 'note.id'
        :note   = 'note'
        :reason = 'classifierById(closeReasonClassifier, application.closeReasonId).human'
      )
      application-note(
        v-for = 'note in application.internalNotes'
        :key  = 'note.id'
        :note = 'note'
        :roles = 'getUserRolesData'
        is-editable
      )
      application-note(
        is-editable
        :roles = 'getUserRolesData'
        @update = 'reloadApplication'
      )
      router-view(
        v-if="Object.keys(workflowPolicy.productType).length"
        :workflow = 'workflowPolicy'
      )
      contract-print-modal(
        v-model    = 'showSendMessageModal'
        :contract  = 'application'
        :product   = 'applicationProduct'
        :customer  = 'applicationCustomer'
        :type      = 'templateType'
      )
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import WorkflowPolicy from '@/policies/WorkflowPolicy'
import ApplicationStatusRow from './ApplicationStatusRow'
import CustomerNoteList from '@/views/customers/CustomerNoteList'
import ApplicationNote from './ApplicationNote'
import { isScoringEnabled } from '@/mixins'
import ContractPrintModal from '@/views/loans/ContractPrintModal '
import api from '@/api'

export default {
  name: 'application',

  components: {
    ApplicationStatusRow,
    CustomerNoteList,
    ApplicationNote,
    ContractPrintModal
  },

  mixins: [isScoringEnabled],

  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },

  i18nOptions: {},

  data (vm) {
    return {
      showSendMessageModal: false,
      templateType: '',
      userRoles: null
    }
  },

  computed: {
    ...mapState('auth', ['user']),
    ...mapState('applications', ['application', 'applicationProduct', 'applicationCustomer', 'applicationCustomerComments']),
    ...mapState('classifiers', ['productTypes']),
    ...mapGetters('classifiers', ['classifierById', 'creditIssuerById', 'productTypeById']),
    ...mapGetters('applications', ['customerFromApplication']),
    ...mapGetters('customers', ['customerName']),
    haveLinkedCustomer () {
      return this.application?.customerId
    },
    isEditable () {
      return ['inserted', 'inProgress', 'offerSent'].includes(this.classifierById('creditApplicationStatuses', this.application.statusId).name)
    },
    getUserRolesData () {
      return this.userRoles?.data
    },
    workflowPolicy () {
      return new WorkflowPolicy(this.productTypes[this.applicationProduct.productType])
    },
    isOverdraft () {
      return this.workflowPolicy.isOverdraft
    },
    closeReasonClassifier () {
      return this.workflowPolicy.privateCustomer ? 'privateReasonsForClosingApp' : 'businessReasonsForClosingApp'
    }
  },

  watch: {
    'application.productId': {
      async handler (productId) {
        if (!productId) return

        await this.loadApplicationProduct({ group: this.application.productGroup })
        await this.loadFeeConfig({ group: this.workflowPolicy.productType.group })
      },
      immediate: true
    },
    'application.customerId': {
      handler (id) {
        if (!id) return
        this.loadApplicationCustomerComments({ id, filters: { active: true } })
      }
    }
  },

  async created () {
    this.userRoles = await api.getCustomerRole({ customerId: this.user.id })
    await Promise.all([
      this.loadProducts(),
      this.loadManagers(),
      this.loadCreditIssuers(),
      this.loadTemplates(),
      this.loadApplication({ id: this.id })
    ])
    if (this.haveLinkedCustomer) {
      await this.loadApplicationCustomer()
    } else {
      await this.findSystemCustomer()
    }
  },

  methods: {
    ...mapActions('applications', [
      'loadApplication',
      'loadApplicationProduct',
      'loadApplicationCustomerComments',
      'loadApplicationCustomer',
      'findSystemCustomer'
    ]),
    ...mapActions('products', ['loadProducts', 'loadFeeConfig']),
    ...mapActions('classifiers', ['loadManagers', 'loadCreditIssuers']),
    ...mapActions('templates', ['loadTemplates']),
    async reloadApplication () {
      await this.loadApplication({ id: this.id })
    },
    showSendMessage (type) {
      this.templateType = type
      this.showSendMessageModal = true
    }
  }
}
</script>


<i18n>
en:
  overview:        "Overview"
  scoring:         "Scoring"
  collateral:      "Collateral"
  communication:   "Communication"
  customerHistory: "Customer history"
  contract:        "Contract"
et:
  overview:        "Overview"
  scoring:         "Scoring"
  collateral:      "Collateral"
  communication:   "Communication"
  customerHistory: "Customer history"
  contract:        "Contract"
ru:
  overview:        "Overview"
  scoring:         "Scoring"
  collateral:      "Collateral"
  communication:   "Communication"
  customerHistory: "Customer history"
  contract:        "Contract"
</i18n>


<style scoped lang="scss">
.application-header {
  top: 101px;
  background-color: #e4e5e6;
  position: sticky;
  z-index: 1000;
}
</style>
