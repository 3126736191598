<template lang="pug">
.application-corporate-members-scoring
  h5.card-title.border-bottom {{ title }}
  template(v-for = 'member in members')
    .card-subtitle {{ getMemberName(member) }} ({{ member.registrationCode }})
    .row.mb-4
      .col
        application-score-item(
          :type     = 'role'
          :request = 'getMemberScoringRequest(member)'
          @score   = '$emit("score", { ...$event, ...member })'
          rescorable
        )
</template>

<script>
import ApplicationScoreItem from '@/views/applications/ApplicationScoreItem'
import { mapState } from 'vuex'

export default {
  name: 'application-corporate-members-scoring',

  components: { ApplicationScoreItem },

  props: {
    members: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: 'OWNER'
    }
  },

  computed: {
    ...mapState('applications', ['applicationMemberScoreRequests', 'applicationOwnersScoreRequests'])
  },

  methods: {
    getMemberScoringRequest (member) {
      // return this.role === 'OWNER' ? this.applicationOwnersScoreRequests[member.registrationCode] : this.applicationMemberScoreRequests[member.registrationCode]
      // return { idCode: member.registrationCode, scoringId: member.scoringId }
      return this.applicationMemberScoreRequests[member.registrationCode]
    },
    getMemberName (member) {
      return member.isCompany ? member.companyName : member.firstName + ' ' + member.lastName
    }
  }
}
</script>
