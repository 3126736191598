<template lang="pug">
fi-modal.contract-print-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '"Print"'
  size    = 'lg'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  fi-form-field(
      :label      = '$t("language")'
      :label-cols = 3
    )
      fi-select(
        v-model.number = 'formData.languageId'
        :options       = 'languages'
        sm
      )
  fi-form-field.mt-1(
    :label      = '$t("memo")'
    :label-cols = 3
  )
    fi-select.col-11(
      v-model.number = 'formData.scheduleTemplateId'
      :options       = 'optionsFromTemplates(["CREDIT_RECORD_PDF_TEMPLATE"])'
      sm
    )
    label.col-form-label-sm.col-auto(v-if = 'formData.scheduleTemplateId')
      a(
        href           = '#'
        @click.prevent = 'previewScheduleTemplatePdf'
      )
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loadingScheduleTemplatePreview')
        i.fa.fa-print(v-else)
  template.hidden(#modal-footer)
    button.btn.btn-primary.hidden(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    )
      | {{ $t('common:send') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary.hidden(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t('common:cancel') }}
</template>


<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { validation, validators } from '@/validation'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import LoanMessageFormFields from '@/views/loans/LoanMessageFormFields'
import FiModal from '@/components/FiModal'
import { findCustomerMainContact } from '@/helpers'

export default {
  name: 'contract-print-modal',

  components: {
    LoanMessageFormFields,
    FiFormField,
    FiSelect,
    FiModal
  },

  mixins: [validation],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    storeModule: {
      type: String,
      default: 'loans'
    },
    contract: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    customer: {
      type: Object,
      default: () => ({})
    }
  },

  i18nOptions: {},

  data () {
    return {
      selectedThirdParty: '',
      formData: {
        channel: 'email',
        languageId: '',
        subject: '',
        text: '',
        recipients: null,
        infoSheetTemplateId: '',
        agreementTemplateId: '',
        scheduleTemplateId: '',
        files: [],
        scheduleId: '',
        documentCategoryId: '',
        customerEmail: null,
        customerPhoneNumber: null
      }
    }
  },

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierByName', 'classifierList']),
    ...mapGetters('templates', ['optionsFromTemplates']),
    ...mapState('templates', ['allTemplates']),
    ...mapGetters('settings', ['settingByKey']),
    // ...mapState({
    //   systemCustomersForSelect (state, getters) {
    //     return getters[`${this.storeModule}/systemCustomersForSelect`]
    //   },
    //   messageTemplate (state) {
    //     return state[this.storeModule].messageTemplate
    //   }
    // }),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    saving () {
      return this.$vueLoading.isLoading('contract:message:save')
    },
    // loadingAgreementTemplatePreview () {
    //   return this.$vueLoading.isLoading(`contract:template:${this.formData.agreementTemplateId}:fetch`)
    // },
    // loadingInfosheetTemplatePreview () {
    //   return this.$vueLoading.isLoading(`contract:template:${this.formData.infoSheetTemplateId}:fetch`)
    // },
    loadingScheduleTemplatePreview () {
      return this.$vueLoading.isLoading(`contract:template:${this.formData.scheduleTemplateId}:fetch`)
    },
    // scheduleOptions () {
    //   return this.schedules.map((schedule) => ({
    //     value: schedule.id,
    //     text: `${this.classifierByName(schedule.status.classifier, schedule.status.id)?.human} ` + (schedule.validFrom ? `(${schedule.validFrom})` : '')
    //   }))
    // },
    // isSendContract () {
    //   return /contract/ig.test(this.type)
    // },
    // channels () {
    //   return this.optionsFromClassifier('documentDeliveryChannels', true).filter(({ value }) => value !== 'call')
    // },
    // categories () {
    //   return this.optionsFromClassifier('DocumentCategoryType')
    // },
    getOptionsFromTemplates () {
      return this.optionsFromTemplates(['CREDIT_RECORD_PDF_TEMPLATE'])
    },
    languages () {
      return Object.values(this.classifierList('languages'))
        .filter(lang => this.settingByKey('supportedTemplateLanguages').includes(lang.code))
        .map(({ human, id }) => ({ text: human, value: id }))
    },
    title () {
      return this.isSendContract ? 'contractTitle' : 'notificationTitle'
    }
    // customerEmailOptions () {
    //   return this.customer?.emails?.map(({ value }) => value)
    // },
    // customerPhoneOptions () {
    //   return this.customer?.phoneNumbers?.map(({ value }) => value)
    // },
    // isEmailChannel () {
    //   return /email/i.test(this.formData.channel)
    // },
    // recipientOptions () {
    //   const { customerCoBorrower, customerWarranter } = this.customer ?? {}
    //   return [
    //     ...[
    //       customerCoBorrower,
    //       customerWarranter
    //     ].filter(item => item)
    //       .map(customer => ({
    //         text: `${customer.fullName} (${customer.idCode})`,
    //         value: customer.id,
    //         role: customer.role
    //       })),
    //     ...this.systemCustomersForSelect
    //   ]
    // }
  },

  watch: {
    'formData.channel': {
      handler: 'fillCustomerContact',
      immediate: true
    },
    'formData.languageId': 'onTemplateChange',
    // 'formData.scheduleId': 'onTemplateChange',
    messageTemplate: 'onTemplateChange'
  },

  validations: {
    messageTemplate: {},
    formData: {
      customerEmail: {},
      customerPhoneNumber: {},
      channel: {},
      languageId: {},
      recipients: {},
      infoSheetTemplateId: {},
      agreementTemplateId: {},
      scheduleTemplateId: {},
      files: {},
      scheduleId: {},
      documentCategoryId: {},
      subject: {
        required: validators.requiredUnless(function () {
          return /sms/i.test(this.formData.channel)
        })
      },
      text: {
        required: validators.required
      }
    }
  },

  // async created () {
  //   this.formData.scheduleTemplateId = this.getOptionsFromTemplates[0].value
  // },

  methods: {
    ...mapActions('templates', ['loadTemplates', 'loadTemplatePreview']),
    ...mapActions({
      loadTemplate (dispatch, payload) {
        return dispatch(this.storeModule + '/loadContractTemplate', payload)
      },
      loadMessageTemplate (dispatch, payload) {
        return dispatch(`${this.storeModule}/loadMessageTemplate`, payload)
      },
      sendMessage (dispatch, payload) {
        return dispatch(this.storeModule + '/sendContractMessage', payload)
      },
      findSystemCustomers (dispatch, payload) {
        return dispatch(`${this.storeModule}/findSystemCustomers`, payload)
      },
      createMessage (dispatch, payload) {
        return dispatch(this.storeModule + '/createMessage', payload)
      }
    }),
    ...mapMutations({
      setTemplate (commit, payload) {
        return commit(this.storeModule + '/SET_TEMPLATE', payload)
      }
    }),
    setSelectedThirdParty (parties) {
      this.selectedThirdParty = this.thirdParties.filter(el => el.customerId === parties.id)
    },
    async resetData () {
      await this.loadTemplates()
      Object.assign(this.$data, this.$options.data.apply(this))
      this.setTemplate({ template: null })
      this.formData.languageId = this.classifierByName('languages', this.settingByKey('language'))?.id
      this.formData.scheduleTemplateId = this.getOptionsFromTemplates[0].value
      this.formData.documentCategoryId = this.classifierByName('DocumentCategoryType', 'OTHER')?.id
      // if (this.isSendContract) {
      //   this.formData.agreementTemplateId = this.product.agreementTemplateId
      //   this.formData.infoSheetTemplateId = this.product.informationSheetTemplateId
      //   this.formData.scheduleTemplateId = this.product.scheduleTemplateId
      //   this.formData.scheduleId = this.schedule?.id
      // }
      this.fillCustomerContact()
      this.$v.$reset()
    },
    onHide (e) {
      if (this.saving) e.preventDefault()
    },
    async onSubmit () {
      if (this.validate()) {
        if (this.selectedThirdParty) {
          this.formData.thirdPartyId = this.selectedThirdParty[0].id
        }
        await this.sendMessage({ id: this.contract.id, data: this.formData })
        // await this.createMessage({ messageData: this.formData, customerId: this.thirdParties })
        this.$refs.modal.hide()
      }
    },
    async previewAgreementTemplatePdf () {
      this.loadTemplate({ templateId: this.formData.agreementTemplateId, contractId: this.contract.id, languageId: this.formData.languageId, scheduleId: this.formData.scheduleId, thirdPartyId: this.selectedThirdParty[0]?.id })
    },

    async previewInfosheetTemplatePdf () {
      this.loadTemplate({ templateId: this.formData.infoSheetTemplateId, contractId: this.contract.id, languageId: this.formData.languageId, scheduleId: this.formData.scheduleId })
    },

    async previewScheduleTemplatePdf () {
      this.loadTemplatePreview({
        id: this.formData.scheduleTemplateId,
        applicationId: this.contract.id,
        languageId: this.formData.languageId
      })
      // this.loadTemplate({ templateId: this.formData.scheduleTemplateId, contractId: this.contract.id, languageId: this.formData.languageId, scheduleId: this.formData.scheduleId })
    },
    getMessageTemplate (templateId) {
      if (this.selectedThirdParty[0]?.id) {
        this.loadMessageTemplate({ templateId, contractId: this.contract.id, scheduleId: this.contract.scheduleId, thirdPartyId: this.selectedThirdParty[0].id })
      } else {
        this.loadMessageTemplate({ templateId, contractId: this.contract.id, scheduleId: this.contract.scheduleId })
      }
    },
    onTemplateChange () {
      const { subject = '', content = '' } = this.messageTemplate?.content[this.formData.languageId] || {}
      this.formData.subject = subject
      this.formData.text = content
    },
    fillCustomerContact () {
      const { emails = [], phoneNumbers = [] } = this.customer ?? {}
      if (this.isEmailChannel) {
        this.formData.customerEmail = findCustomerMainContact(emails)
        this.formData.customerPhoneNumber = null
      } else {
        this.formData.customerEmail = null
        this.formData.customerPhoneNumber = findCustomerMainContact(phoneNumbers)
      }
    }
  }
}
</script>

<style lang="scss">
.hidden {
  visibility: hidden;
  display: none;
}
</style>


<i18n>
en:
  contractTitle:     "Send contract"
  notificationTitle: "Send notification"
  channel:           "Channel"
  language:          "Language"
  to:                "To"
  template:          "Template"
  subject:           "Subject"
  message:           "Message"
  selectedSchedule:  "Selected schedule"
  agreement:         "Agreement"
  schedule:          "Payment schedule"
  infoSheet:         "Info sheet"
  preview:           "Preview"
  attachments:       "Attachments"
  memo:              "Memo"
et:
  contractTitle:     "Send contract"
  notificationTitle: "Send notification"
  channel:           "Channel"
  language:          "Language"
  to:                "To"
  template:          "Template"
  subject:           "Subject"
  message:           "Message"
  selectedSchedule:  "Selected schedule"
  agreement:         "Agreement"
  schedule:          "Payment schedule"
  infoSheet:         "Info sheet"
  preview:           "Preview"
  attachments:       "Attachments"
  memo:              "Memo"
ru:
  contractTitle:     "Send contract"
  notificationTitle: "Send notification"
  channel:           "Channel"
  language:          "Language"
  to:                "To"
  template:          "Template"
  subject:           "Subject"
  message:           "Message"
  selectedSchedule:  "Selected schedule"
  agreement:         "Agreement"
  schedule:          "Payment schedule"
  infoSheet:         "Info sheet"
  preview:           "Preview"
  attachments:       "Attachments"
  memo:              "Memo"
</i18n>
