<template lang="pug">
.application-corporate-financial-data-form.animated.fadeIn
  //- .row
  //-   .col-lg-6
  //-     fi-form-field(:label = '$t("registeredCapital")')
  //-       fi-money-input(v-model = 'customerData.registeredCapital')
  .row.mb-3
    .col-lg-3
    .col-lg-3 {{ $t('current') }}
    .col-lg-3 {{ $t('historical') }}
  .row
    .col-lg
      fi-form-field(
        :label     = '$t("paymentDefaults")'
        label-cols = '3'
      )
        .row.mb-3
          .col-lg-4
            fi-money-input(v-model = 'customerData.currentPaymentDefaults')
          .col-lg-4
            fi-money-input(v-model = 'customerData.historicalPaymentDefaults')
        .row
          .col-lg-4
            fi-money-input(v-model = 'customerData.currentPaymentDefaultAmount')
          .col-lg-4
            fi-money-input(v-model = 'customerData.historicalPaymentDefaultsAmount')
  .row
    .col-lg
      fi-form-field(
        :label     = '$t("taxDebts")'
        label-cols = '3'
      )
        .row.mb-3
          .col-lg-4
            fi-money-input(v-model = 'customerData.currentTaxDebtAmount')
          .col-lg-4
            fi-money-input(v-model = 'customerData.historicalTaxDebtAmount')
  .row
    .col-lg-6
      fi-form-field(:label = '$t("deferredTax")')
        fi-money-input(v-model = 'customerData.deferredTaxDebtAmount')
  .row
    .col-lg-6
      fi-form-field(:label = '$t("taxesLastQuarter")')
        fi-money-input(v-model = 'customerData.taxesPaidLastQuarterAmount')
  .row
    .col-lg-6
      fi-form-field(:label = '$t("unsubmittedTaxReports")')
        input.form-control.form-control-sm(v-model = 'customerData.unsubmittedTaxReports')
  </template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'

export default {
  name: 'application-corporate-financial-data-form',

  components: { FiMoneyInput, FiFormField },

  props: {
    customerData: {
      type: Object,
      required: true
    }
  }
}
</script>


<i18n>
en:
  registeredCapital:     "Registered capital"
  current:               "Current"
  historical:            "Historical"
  paymentDefaults:       "Payment defaults"
  taxDebts:              "Tax debts"
  deferredTax:           "Deferred tax debt"
  taxesLastQuarter:      "Taxes paid last quarter"
  unsubmittedTaxReports: "Unsubmitted tax reports"
et:
  registeredCapital:     "Registered capital"
  current:               "Current"
  historical:            "Historical"
  paymentDefaults:       "Payment defaults"
  taxDebts:              "Tax debts"
  deferredTax:           "Deferred tax debt"
  taxesLastQuarter:      "Taxes paid last quarter"
  unsubmittedTaxReports: "Unsubmitted tax reports"
ru:
  registeredCapital:     "Registered capital"
  current:               "Current"
  historical:            "Historical"
  paymentDefaults:       "Payment defaults"
  taxDebts:              "Tax debts"
  deferredTax:           "Deferred tax debt"
  taxesLastQuarter:      "Taxes paid last quarter"
  unsubmittedTaxReports: "Unsubmitted tax reports"
</i18n>

