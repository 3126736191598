<template lang = "pug">
fi-modal.application-customer-new-modal(
  ref     = 'modal'
  v-model = 'showModal'
  size    = 'lg'
  :title  = '$t("title")'
  @hide   = 'onHide'
  @shown  = 'resetData'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(
      :label     = '$t("sector")'
      label-cols = '3'
    )
      fi-select(
        v-model.number = 'customerData.sectorId'
        :options       = 'optionsFromClassifier("sectors")'
        sm
      )
    fi-form-field(
      :label     = '$t("segment")'
      label-cols = '3'
    )
      fi-select(
        v-model.number = 'customerData.segmentId'
        :options       = 'optionsFromClassifier("segments")'
        sm
      )
    template(v-if = 'isCorporateCustomer(customerData)')
      fi-form-field(
        :label     = '$t("name")'
        label-cols = '3'
      )
        input.form-control.form-control-sm(
          v-model.trim = 'customerData.customerName'
          type         = 'text'
        )
      fi-form-field(
        :label     = '$t("regCode")'
        label-cols = '3'
      )
        input.form-control.form-control-sm(
          v-model.trim = 'customerData.idCode'
          type         = 'text'
        )
      fi-form-field(:label = '$t("nace")' label-cols = '3')
        fi-multiselect(
          track-by         = 'id'
          label            = 'human'
          v-model          = 'selectedNACEModel'
          :options         = 'Object.values(classifierList("nace"))'
          :search-callback = 'loadClassifiersNACE'
          :loading         = '$vueLoading.isLoading("classifiers:nace:fetch")'
          :custom-label    = 'codeWithHuman'
        )
          template(#option = '{ option }')  {{ option.code }} - {{ option.human }}
      fi-form-field(:label = '$t("emtak")' label-cols = '3')
        fi-multiselect(
          track-by         = 'id'
          label            = 'human'
          v-model          = 'selectedEMTAKModel'
          :options         = 'Object.values(classifierList("emtak"))'
          :search-callback = 'loadClassifiersEMTAK'
          :loading         = '$vueLoading.isLoading("classifiers:emtak:fetch")'
          :custom-label    = 'codeWithHuman'
        )
          template(#option = '{ option }')  {{ option.code }} - {{ option.human }}
      fi-form-field(:label = '$t("vatNumber")' label-cols = '3')
        input.form-control.form-control-sm(
          v-model.trim = 'corporateCustomerData.vatNumber'
          type         = 'text'
        )
      fi-form-field(:label = '$t("registeredCapital")' label-cols = '3')
        input.form-control.form-control-sm(
          v-model.number = 'corporateCustomerData.registeredCapital'
          type         = 'number'
        )
    template(v-else)
      fi-form-field(
        :label     = '$t("firstName")'
        label-cols = '3'
      )
        input.form-control.form-control-sm(
          v-model.trim = 'customerData.firstName'
          type         = 'text'
        )
      fi-form-field(
        :label     = '$t("lastName")'
        label-cols = '3'
      )
        input.form-control.form-control-sm(
          v-model.trim = 'customerData.lastName'
          type         = 'text'
        )
      fi-form-field(
        :label     = '$t("idCode")'
        label-cols = '3'
      )
        input.form-control.form-control-sm(
          v-model.trim = 'customerData.idCode'
          type         = 'text'
        )
    fi-form-field(
      :label     = '$t("phone")'
      label-cols = '3'
    )
      customer-contact-list(
        v-model    = 'customerData.phoneNumbers'
        :add-label = '$t("addPhone")'
        name       = 'phoneContact'
        allow-main
      )
        template(#input="{ contact, onInput }")
          fi-phone-input.form-control-sm.form-control.animated.fadeIn(
            @input     = 'onInput'
            :value     = 'contact'
            :countries = 'countries'
          )
    fi-form-field(
      :label     = '$t("deskPhone")'
      label-cols = '3'
    )
      customer-contact-list(
        v-model    = 'customerData.deskPhoneNumbers'
        :add-label = '$t("addPhone")'
        name       = 'phoneContact'
      )
        template(#input="{ contact, onInput }")
          fi-phone-input.form-control-sm.form-control.animated.fadeIn(
            @input     = 'onInput'
            :value     = 'contact'
            :countries = 'countries'
          )
    fi-form-field(
      :label     = '$t("email")'
      label-cols = '3'
    )
      customer-contact-list(
        v-model    = 'customerData.emails'
        :add-label = '$t("addEmail")'
        name       = 'emailContact'
        allow-main
      )
    fi-form-field(
      :label     = '$t("country")'
      label-cols = '3'
    )
      fi-select(
        v-model.number = 'addressData.countryId'
        :options       = 'optionsFromClassifier("countries")'
        sm
      )
    fi-form-field(
      :label     = '$t("county")'
      label-cols = '3'
    )
      fi-select(
        v-model.number = 'addressData.countyId'
        :options       = 'counties'
        sm
      )
    fi-form-field(
      :label     = '$t("city")'
      label-cols = '3'
    )
      input.form-control.form-control-sm(
        v-model.trim = 'addressData.city'
        type         = 'text'
      )
    fi-form-field(
      :label     = '$t("address")'
      label-cols = '3'
    )
      input.form-control.form-control-sm(
        v-model.trim = 'addressData.fullAddress'
        type         = 'text'
      )
    fi-form-field(
      :label     = '$t("postalCode")'
      label-cols = '3'
    )
      input.form-control.form-control-sm(
        v-model.trim = 'addressData.postalCode'
        type         = 'text'
      )
    template(v-if = '!isCorporateCustomer(customerData)')
      fi-form-field(
        :label     = '$t("documentType")'
        label-cols = '3'
      )
        fi-select(
          v-model.number = 'privateCustomerData.idDocId'
          :options       = 'optionsFromClassifier("idDocs")'
          sm
        )
      fi-form-field(
        :label     = '$t("documentNumber")'
        label-cols = '3'
      )
        input.form-control.form-control-sm(
          v-model.trim = 'privateCustomerData.idDocNumber'
          type         = 'text'
        )
      fi-form-field(
        :label     = '$t("maritalStatus")'
        label-cols = '3'
      )
        fi-select(
          v-model.number = 'privateCustomerData.maritalStatusId'
          :options       = 'optionsFromClassifier("maritalStatus")'
          required
          sm
        )
      fi-form-field(
        :label     = '$t("dependantNumber")'
        label-cols = '3'
      )
        input.form-control.form-control-sm(
          v-model.number = 'privateCustomerData.dependantNumber'
          type           = 'text'
        )
    fi-form-field(
      :label     = '$t("acceptPEP")'
      label-cols = '3'
    )
      fi-switch(
        v-model   = 'customerData.acceptPep'
        :on-text  = '$t("common:enabled")'
        :off-text = '$t("common:disabled")'
      )
    fi-form-field(
      :label     = '$t("sanctions")'
      label-cols = '3'
    )
      fi-switch(
        v-model   = 'customerData.existInSanctions'
        :on-text  = '$t("common:enabled")'
        :off-text = '$t("common:disabled")'
      )
    fi-form-field(
      :label     = '$t("blacklisted")'
      label-cols = '3'
    )
      fi-switch(
        v-model   = 'customerData.isInBlacklist'
        :on-text  = '$t("common:enabled")'
        :off-text = '$t("common:disabled")'
      )
  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:confirm') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>


<script>
import FiMultiselect from '@/components/FiMultiselect'
import FiFormField from '@/components/FiFormField'
import FiModal from '@/components/FiModal'
import FiPhoneInput from '@/components/FiPhoneInput'
import FiSelect from '@/components/FiSelect'
import { mapActions, mapGetters } from 'vuex'
import CustomerContactList from '@/views/customers/CustomerContactList'
import isEmpty from 'lodash/isEmpty'
import FiSwitch from '@/components/FiSwitch'

export default {
  name: 'application-customer-new-modal',

  components: {
    FiMultiselect,
    CustomerContactList,
    FiFormField,
    FiSelect,
    FiModal,
    FiPhoneInput,
    FiSwitch
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    customer: {
      type: Object,
      required: true
    },
    application: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    const { addresses, isInBlacklist, existInSanctions, acceptPep, maritalStatus, dependantNumber, idDocId, idDocNumber, phoneNumbers = [], deskPhoneNumbers = [], emails = [], ...customer } = this.customer
    return {
      customerData: {
        ...customer,
        phoneNumbers,
        deskPhoneNumbers,
        emails,
        isInBlacklist,
        existInSanctions,
        acceptPep
      },
      privateCustomerData: {
        idDocId,
        idDocNumber,
        maritalStatusId: '',
        dependantNumber
      },
      corporateCustomerData: {
        emtakId: '',
        naceId: '',
        vatNumber: '',
        registeredCapital: ''
      },
      addressData: addresses[0]
    }
  },

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierByName', 'optionsFromCountiesByCountry', 'classifierList', 'classifierById']),
    ...mapGetters('customers', ['isCorporateCustomer']),
    countries () {
      return Object.values(this.classifierList('countries')).map(({ name }) => name)
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    saving () {
      return this.$vueLoading.isLoading('application:customer:save')
    },
    counties () {
      return this.optionsFromCountiesByCountry(this.addressData.countryId)
    },
    selectedNACEModel: {
      get () {
        const model = this.classifierById('nace', this.corporateCustomerData.naceId)
        return isEmpty(model) ? null : model
      },
      set (value) {
        this.corporateCustomerData.naceId = value?.id || value
      }
    },
    selectedEMTAKModel: {
      get () {
        const model = this.classifierById('emtak', this.corporateCustomerData.emtakId)
        return isEmpty(model) ? null : model
      },
      set (value) {
        this.corporateCustomerData.emtakId = value?.id || value
      }
    }
  },

  watch: {
    'customerData.countryId': function (countryId) {
      this.addressData.countryId = countryId
    }
  },

  created () {
    // this.loadClassifiersEMTAK({ id: this.corporateCustomerData.emtakId })
    // this.loadClassifiersNACE({ id: this.corporateCustomerData.naceId })
    this.loadClassifiersEMTAK()
    this.loadClassifiersNACE()
  },

  methods: {
    ...mapActions('applications', ['updateApplicationCustomer']),
    ...mapActions('classifiers', ['loadClassifiersEMTAK', 'loadClassifiersNACE']),
    ...mapActions('customers', ['updateCustomerCorporate']),
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))

      const { maritalStatus, idDocId, idDocNumber, dependantNumber } = this.customer
      this.privateCustomerData = {
        idDocId,
        idDocNumber,
        maritalStatusId: this.classifierByName(maritalStatus?.classifier, maritalStatus?.code)?.id,
        dependantNumber
      }
      if (this.isCorporateCustomer(this.customerData)) {
        const { emtakId, naceId, vatNumber, registeredCapital } = this.application.corporateApplication
        this.corporateCustomerData = {
          emtakId,
          naceId,
          vatNumber,
          registeredCapital
        }
      }
    },
    onHide (e) {
      if (this.saving) e.preventDefault()
    },
    async onSubmit () {
      delete this.customerData.employmentLength
      const data = JSON.parse(JSON.stringify(this.customerData))
      delete data.naceId
      delete data.emtakId
      delete data.vatNumber
      delete data.registeredCapital
      const applicationCustomerData = {
        customerId: this.application.customerId,
        customerData: {
          ...data,
          enabled: true,
          identityAuthenticated: true
        },
        addressData: this.addressData
      }
      if (!this.isCorporateCustomer(this.customerData)) {
        applicationCustomerData.privateCustomerData = this.privateCustomerData
        applicationCustomerData.customerData.username = this.customer.firstName + ' ' + this.customer.lastName
      }
      if (applicationCustomerData.customerData) {
        if (applicationCustomerData.customerData.monthlyIncome || applicationCustomerData.customerData?.monthlyIncome === 0) delete applicationCustomerData.customerData.monthlyIncome
        if (applicationCustomerData.customerData.monthlyObligations || applicationCustomerData.customerData?.monthlyObligations === 0) delete applicationCustomerData.customerData.monthlyObligations
      }
      await this.updateApplicationCustomer(applicationCustomerData)
      if (this.isCorporateCustomer(this.customerData)) {
        await this.updateCustomerCorporate({ corporateCustomerData: this.corporateCustomerData, id: this.application.customerId })
      }
      this.$refs.modal.hide()
    },
    codeWithHuman ({ code, human }) {
      if (!(code && human)) {
        return
      }
      return `${code} - ${human}`
    }
  }
}
</script>


<i18n>
en:
  title:          "Confirm customer's identity"
  sector:         "Sector"
  segment:        "Segment"
  name:           "Name"
  firstName:      "First name"
  lastName:       "Last name"
  idCode:         "ID code"
  regCode:        "Reg Code"
  phone:          "Mobile phone"
  deskPhone:      "Desk phone"
  email:          "E-mail"
  country:        "Country"
  county:         "County"
  city:           "City"
  address:        "Address"
  postalCode:     "Postal code"
  documentType:   "Document type"
  documentNumber: "Document number"
  maritalStatus:  "Marital status"
  dependantNumber: "Number of dependants"
  vatNumber:       "VAT number"
  emtak:           "EMTAK"
  nace:            "NACE"
  registeredCapital:  "Registered capital"
  acceptPEP:          "Accept PEP"
  sanctions:          "Sanctions"
  blacklisted:        "Blacklisted"
et:
  title:          "Confirm customer's identity"
  sector:         "Sector"
  segment:        "Segment"
  name:           "Name"
  firstName:      "First name"
  lastName:       "Last name"
  idCode:         "ID code"
  regCode:        "Reg Code"
  phone:          "Mobile phone"
  deskPhone:      "Desk phone"
  email:          "E-mail"
  country:        "Country"
  county:         "County"
  city:           "City"
  address:        "Address"
  postalCode:     "Postal code"
  documentType:   "Document type"
  documentNumber: "Document number"
  maritalStatus:  "Marital status"
  dependantNumber: "Number of dependants"
  vatNumber:       "VAT number"
  emtak:           "EMTAK"
  nace:            "NACE"
  registeredCapital:  "Registered capital"
  acceptPEP:          "Accept PEP"
  sanctions:          "Sanctions"
  blacklisted:        "Blacklisted"
ru:
  title:          "Confirm customer's identity"
  sector:         "Sector"
  segment:        "Segment"
  name:           "Name"
  firstName:      "First name"
  lastName:       "Last name"
  idCode:         "ID code"
  phone:          "Mobile phone"
  deskPhone:      "Desk phone"
  email:          "E-mail"
  country:        "Country"
  county:         "County"
  city:           "City"
  address:        "Address"
  postalCode:     "Postal code"
  documentType:   "Document type"
  documentNumber: "Document number"
  maritalStatus:  "Marital status"
  dependantNumber: "Number of dependants"
  vatNumber:       "VAT number"
  emtak:           "EMTAK"
  nace:            "NACE"
  registeredCapital:  "Registered capital"
  acceptPEP:          "Accept PEP"
  sanctions:          "Sanctions"
  blacklisted:        "Blacklisted"
</i18n>
