<template lang="pug">
  .application-financial-data-list.card
    .card-header {{ $t('title') }}
    .card-body
      ul.list-group-flush.list-group
        li.list-group-item(v-for = '(option, key) in options') {{ key | sentenceCase | ucFirst }}
          .btn-group.btn-group-sm.fa-pull-right.animated.fadeIn(v-if = '!showAddRow')
            button.btn.btn-link(@click = 'showAddRow = key') {{ $t('common:add') }}
          fi-table.mt-2(
            :key = 'key'
            :fields = 'fields'
            :items = 'financialValuesByKey(key)'
            :empty-label ='$t("notFound")'
            fixed
          )
            template(#custom-row)
              application-financial-data-edit-item(
                v-if     = 'showAddRow === key'
                :value   = 'financialData'
                :options = 'availableOptionsCreate(option, key)'
                :periods = 'optionsFromClassifier("PaymentPeriodTypes", true)'
                :loader  = '$vueLoading.isLoading("application:customerData:save")'
                @input   = 'saveFinancialData($event, key)'
                @cancel  = 'resetData'
              )
            template(v-slot = '{ items }')
              template(v-for = '(item, index) in items')
                application-financial-data-edit-item(
                  v-if     = 'editing === item.id'
                  :value   = 'item'
                  :options = 'availableOptionsEdit(option, key, item.financialDataValue)'
                  :periods = 'optionsFromClassifier("PaymentPeriodTypes", true)'
                  :loader  = '$vueLoading.isLoading(`application:customerData:${item.id}:save`)'
                  @input   = 'updateEventEmit'
                  @cancel  = 'resetData'
                )
                tr(v-else)
                  td {{ option[item.financialDataValue] ? option[item.financialDataValue].human : '' }}
                  td {{ classifierByCode('paymentPeriodTypes', item.financialDataAmountPeriod) ? classifierByCode('paymentPeriodTypes', item.financialDataAmountPeriod).human : ''}}
                  td {{ item.financialDataAmount | money }}
                  td {{ $t('common:formatDate', { value: item.expirationDate}) }}
                  td
                    .btn-group.btn-group-sm.fa-pull-right.animated.fadeIn
                      button.btn.btn-link(@click.prevent = 'editing = item.id')
                        i.far.fa-edit.fa-lg
                      button.btn.btn-link(@click.prevent = 'removeFinancialData(item.id)')
                        i.far.fa-trash-alt.fa-lg.text-danger
              tr.top-border(v-if = 'sumCalculation(financialValuesByKey(key)) >= 0')
                td.bold Sum
                td
                td {{ sumCalculation(financialValuesByKey(key)) | money }}
                td
                td

</template>


<script>
import FiTable from '@/components/FiTable'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import { mapGetters } from 'vuex'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiDatepicker from '@/components/FiDatepicker'
import ApplicationFinancialDataEditItem from '@/views/applications/ApplicationFinancialDataEditItem'
import { CFinancialData } from '@/models/application/CApplication.ts'

export default {
  name: 'application-financial-data-list',

  components: { ApplicationFinancialDataEditItem, FiDatepicker, FiMoneyInput, FiSelect, FiFormField, FiTable },

  props: {
    financial: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },

  i18nOptions: {},

  data: () => ({
    editing: false,
    showAddRow: false,
    financialData: new CFinancialData()
  }),

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierByCode']),
    fields () {
      return [
        {
          key: 'type',
          label: this.$t('type')
        },
        {
          key: 'period',
          label: this.$t('period')
        },
        {
          key: 'amount',
          label: this.$t('amount')
        },
        {
          key: 'date',
          label: this.$t('date')
        },
        {}
      ]
    }
  },

  methods: {
    sumCalculation (data) {
      let sum = 0
      if (data.length) {
        data.forEach((current) => {
          if (current?.financialDataAmount >= 0) {
            sum = sum + +current?.financialDataAmount
          }
        })
        return sum
      }
      return sum
    },
    resetData () {
      Object.assign(this.$data, this.$options.data())
    },
    saveFinancialData (financialData, key) {
      if (this.$route.name === 'ApplicationOverview') {
        this.updateEventEmit({
          ...financialData,
          financialDataType: key
        })
      } else {
        let newId = 0
        if (this.financial.length === 0) {
          newId = 1
        } else {
          const idArray = this.financial.map(el => el.id)
          newId = Math.max.apply(null, idArray) + 1
        }
        this.updateEventEmit({
          ...financialData,
          financialDataType: key,
          id: newId
        })
      }
    },
    financialValuesByKey (key) {
      return this.financial.filter(({ financialDataType }) => financialDataType === key)
    },
    availableOptionsCreate (options, key) {
      return Object.values(options).map(({ code, human }) => ({ value: code, text: human }))
    },
    availableOptionsEdit (options, key, value) {
      const currentValue = options[value]
      return [...this.availableOptionsCreate(options, key), { value, text: currentValue?.human }]
    },
    removeFinancialData (id) {
      this.updateEventEmit({ id })
    },
    updateEventEmit (payload) {
      this.$emit('update', {
        ...payload,
        id: payload.id,
        done: () => {
          this.resetData()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.bold {
  font-weight: bold;
}
.top-border {
  border-top: 1.5px solid #a4b7c1
}

</style>


<i18n>
en:
  title:                "Customer financial data"
  CUSTOMER_INCOME:      "Customer income"
  CUSTOMER_EXPENSES:    "Customer expenses"
  CUSTOMER_LIABILITIES: "Customer liabilities"
  type:                 "Type"
  period:               "Period"
  amount:               "Amount"
  date:                 "Date"
  notFound:             "No financial data"
et:
  title:                "Customer financial data"
  CUSTOMER_INCOME:      "Customer income"
  CUSTOMER_EXPENSES:    "Customer expenses"
  CUSTOMER_LIABILITIES: "Customer liabilities"
  type:                 "Type"
  period:               "Period"
  amount:               "Amount"
  date:                 "Date"
  notFound:             "No financial data"
ru:
  title:                "Customer financial data"
  CUSTOMER_INCOME:      "Customer income"
  CUSTOMER_EXPENSES:    "Customer expenses"
  CUSTOMER_LIABILITIES: "Customer liabilities"
  type:                 "Type"
  period:               "Period"
  amount:               "Amount"
  date:                 "Date"
  notFound:             "No financial data"
</i18n>
