<template lang="pug">
.application-data-fields.row.animated.fadeIn
  .col
    fi-data-field(:label = '$t("amount")') {{ application.creditAmount | money }}
    template(v-if = 'leasing')
      fi-data-field(
        v-if   = 'product.principalWithVat'
        :label = '$t("amountWithVat")'
      ) {{ application.loanAmountWithVat | money }}
      fi-data-field(:label = '$t("upfrontAmount")')
        .row(v-if = 'upfrontAmountType')
          .col {{ application.upfrontAmount | percent }}
          .col {{ upfrontAmountMoneyInfo | money }}
        .row(v-else)
          .col {{ application.upfrontAmount | money }}
          .col {{ upfrontAmountPercentageInfo | percent }}
      fi-data-field(:label = '$t("residualAmount")')
        .row(v-if = 'residualAmountType')
          .col {{ application.residualAmount | percent }}
          .col {{ residualAmountMoneyInfo | money }}
        .row(v-else)
          .col {{ application.residualAmount | money }}
          .col {{ residualAmountPercentageInfo | percent }}
    fi-data-field(:label = '$t("period")') {{ periodUnit }}
</template>


<script>
import { mapGetters } from 'vuex'
import FiDataField from '@/components/FiDataField'


export default {
  name: 'application-data-fields',

  components: { FiDataField },

  props: {
    product: {
      type: Object,
      required: true
    },
    application: {
      type: Object,
      default: null
    },
    leasing: {
      type: Boolean,
      default: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    periodUnit () {
      const periodUnit = this.classifierById('periodUnitType', this.product.periodUnitTypeId)
      return this.$t((periodUnit.name || '').toLowerCase(), { count: this.application.loanLength || 0 })
    },
    upfrontAmountMoneyInfo () {
      return this.application.creditAmount * this.application.upfrontAmount
    },
    upfrontAmountPercentageInfo () {
      return this.application.upfrontAmount / this.application.creditAmount
    },
    residualAmountMoneyInfo () {
      return this.application.creditAmount * this.application.residualAmount
    },
    residualAmountPercentageInfo () {
      return this.application.residualAmount / this.application.creditAmount
    },
    upfrontAmountType () {
      return this.application.upfrontAmountCalculationType.code === 'PERCENTAGE_FROM_AMOUNT'
    },
    residualAmountType () {
      return this.application.residualAmountCalculationType.code === 'PERCENTAGE_FROM_AMOUNT'
    }
  }
}
</script>


<i18n>
en:
  amount:             "Amount"
  period:             "Period"
  day:                "{{count}} day"
  day_plural:         "{{count}} days"
  month:              "{{count}} month"
  month_plural:       "{{count}} months"
  monthlyIncome:      "Monthly income"
  monthlyObligations: "Monthly loan obligations"
  employmentLength:   "Current employment length"
  documentType:       "Document type"
  idDocPlaceholder:   "Select document type"
  documentNumber:     "Document number"
  residualAmount:     "Residual amount"
  upfrontAmount:      "Upfront amount"
  amountWithVat:      "Amount with VAT"
et:
  amount:             "Amount"
  period:             "Period"
  day:                "{{count}} day"
  day_plural:         "{{count}} days"
  month:              "{{count}} month"
  month_plural:       "{{count}} months"
  monthlyIncome:      "Monthly income"
  monthlyObligations: "Monthly loan obligations"
  employmentLength:   "Current employment length"
  documentType:       "Document type"
  idDocPlaceholder:   "Select document type"
  documentNumber:     "Document number"
  residualAmount:     "Residual amount"
  upfrontAmount:      "Upfront amount"
  amountWithVat:      "Amount with VAT"
ru:
  amount:             "Amount"
  period:             "Period"
  day_0:              "{{count}} день"
  day_1:              "{{count}} дня"
  day_2:              "{{count}} дней"
  month_0:            "{{count}} месяц"
  month_1:            "{{count}} месяца"
  month_2:            "{{count}} месяцев"
  monthlyIncome:      "Monthly income"
  monthlyObligations: "Monthly loan obligations"
  employmentLength:   "Current employment length"
  documentType:       "Document type"
  idDocPlaceholder:   "Select document type"
  documentNumber:     "Document number"
  residualAmount:     "Residual amount"
  upfrontAmount:      "Upfront amount"
  amountWithVat:      "Amount with VAT"
</i18n>
