<template lang="pug">
tr.application-factoring-third-party-list-item
  td {{ thirdParty.customerName }}
  td.money {{ thirdParty.limit |money }}
  td {{ thirdParty.advanceRate | percent }}
  td {{ thirdParty.invoiceLengthMin }} - {{ thirdParty.invoiceLengthMax }}
  td
    .row(v-for = 'interestRate in thirdParty.invoiceLengthInterestRates')
      .col-4 {{ interestRate.invoiceLength }} {{ $t('days') }}
      .col-4 {{ interestRate.periodInterestRate | percent }}
      .col-4 {{ interestRate.yearlyInterestRate | percent }} {{ $t('yearly') }}
  td
    .btn-toolbar
      .btn-group.btn-group-sm
        button.btn.btn-link(@click.prevent = 'showEditThirdParty = true')
          i.far.fa-edit.fa-fw.fa-lg
        button.btn.btn-link(
          :disabled      = 'deleteLoader'
          @click.prevent = 'remove'
        )
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'deleteLoader')
          i.far.fa-trash-alt.fa-lg.text-danger(v-else)
  application-factoring-third-party-modal(
    v-model      = 'showEditThirdParty'
    :product     = 'product'
    :source-data = 'application'
    :third-party = 'thirdParty'
    :loader      = 'loader'
    @submit      = '$emit("edit", $event)'
  )
</template>


<script>
import ApplicationFactoringThirdPartyModal
  from '@/views/applications/ApplicationFactoring/ApplicationFactoringThirdPartyModal'

export default {
  name: 'application-factoring-third-party-list-item',

  components: { ApplicationFactoringThirdPartyModal },

  props: {
    thirdParty: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    application: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    showEditThirdParty: false
  }),

  computed: {
    loader () {
      return this.$vueLoading.isLoading(`factoring:application:thirdParty:${this.thirdParty.id}:save`)
    },
    deleteLoader () {
      return this.$vueLoading.isLoading(`factoring:application:thirdParty:${this.thirdParty.id}:delete`)
    }
  },

  methods: {
    remove () {
      this.$emit('remove', { thirdPartyId: this.thirdParty?.id })
    }
  }
}
</script>
