<template lang="pug">
fi-modal.loan-schedule-interest-period-new-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = 'title'
  size    = 'lg'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    .form-row
      .col-lg-6
        fi-form-field(:label = '$t("customerType")')
          fi-select(
            v-model = 'customerType'
            :options       = 'customerTypes'
            sm
            required
          )
        fi-form-field(:label = '$t("type")')
          fi-select(
            v-model = 'relatedPersonsData.relationType'
            :options       = 'relationTypes'
            sm
            required
          )
        fi-form-field(v-if = 'customerType === 1' :label = '$t("role")')
          input.form-control-sm.form-control(v-model="relatedPersonsData.role")
        fi-form-field(v-if = 'customerType === 1' :label = '$t("firstName")' :state = '!isRequired')
          template(#error)
            .error-message(v-if = '!relatedPersonsData.firstName && isRequired') {{ $t('common:required') }}
          input.form-control-sm.form-control(v-model="relatedPersonsData.firstName")
        fi-form-field(v-if = 'customerType === 1' :label = '$t("lastName")')
          input.form-control-sm.form-control(v-model="relatedPersonsData.lastName")
        fi-form-field(v-if = 'customerType === 2' :label = '$t("companyName")' :state = '!isRequired')
          template(#error)
              .error-message(v-if = '!relatedPersonsData.companyName && isRequired') {{ $t('common:required') }}
          input.form-control-sm.form-control(v-model="relatedPersonsData.companyName")
        fi-form-field(:label = '$t("idCode")')
          input.form-control-sm.form-control(v-model="relatedPersonsData.registrationCode")
        fi-form-field(:label = '$t("residence")')
          fi-select.animated.fadeIn(
            v-model = 'relatedPersonsData.residenceCountry'
            :options       = 'optionsFromClassifier("countries")'
            required
            sm
          )
        fi-form-field(:label = '$t("country")')
          fi-select(
            v-model = 'relatedPersonsData.registrationCountry'
            :options       = 'optionsFromClassifier("countries")'
            required
            sm
          )
        fi-form-field(:label = '$t("sinceWhen")')
          fi-datepicker(
            v-model   = 'relatedPersonsData.sinceWhen'
          )
      .col-lg-6
        fi-form-field(:label = '$t("taxId")')
          input.form-control-sm.form-control(v-model="relatedPersonsData.tin")
        fi-form-field(:label = 'customerType === 1 ? $t("birthdate") : $t("registrationDate")')
          fi-datepicker(
            v-model   = 'relatedPersonsData.birthdate'
          )
        fi-form-field(:label = '$t("phone")')
          input.form-control-sm.form-control(v-model="relatedPersonsData.contactPhone")
        fi-form-field(:label = '$t("email")')
          input.form-control-sm.form-control(v-model="relatedPersonsData.contactEmail")
        fi-form-field(:label = '$t("percentage")')
          input.form-control-sm.form-control(v-model.number="relatedPersonsData.sharePercentage")
        fi-form-field(v-if = 'customerType === 2' :label = '$t("businessActivity")')
          input.form-control-sm.form-control(v-model.number="relatedPersonsData.businessActivity")
        fi-form-field(v-if = 'customerType === 1' :label = '$t("pep")')
          fi-switch(v-model   = 'relatedPersonsData.pep')
  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>


<script>
import FiModal from '@/components/FiModal'
import FiFormField from '@/components/FiFormField'
import FiMultiselect from '@/components/FiMultiselect'
import CustomerNewBrief from '@/views/customers/CustomerNewBrief'
import PercentInput from '@/components/PercentInput'
import FiMoneyInput from '@/components/FiMoneyInput'
import ApplicationFactoringInterestRateItem
  from '@/views/applications/ApplicationFactoring/ApplicationFactoringInterestRateItem'
import pick from 'lodash/pick'
import cloneDeep from 'lodash/cloneDeep'
import FiCustomerSelect from '@/components/FiCustomerSelect'
import { customerName } from '@/helpers'
import { mapGetters } from 'vuex'
import FiSelect from '@/components/FiSelect'
import FiDatepicker from '@/components/FiDatepicker'
import FiSwitch from '@/components/FiSwitch'

export default {
  name: 'application-related-persons-modal',

  components: { FiCustomerSelect, ApplicationFactoringInterestRateItem, FiMoneyInput, PercentInput, CustomerNewBrief, FiMultiselect, FiFormField, FiModal, FiSelect, FiDatepicker, FiSwitch },

  props: {
    loader: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    relatedPerson: {
      type: Object,
      default: null
    },
    product: {
      type: Object,
      required: true
    },
    sourceData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    createNewCustomer: false,
    isRequired: false,
    selectedCustomer: null,
    customerType: 1,
    relatedPersonsData: {
      relationType: '',
      firstName: '',
      registrationCode: '',
      residenceCountry: '',
      birthdate: '',
      contactPhone: '',
      role: '',
      sharePercentage: 0,
      lastName: '',
      registrationCountry: '',
      tin: '',
      contactEmail: '',
      sinceWhen: '',
      companyName: '',
      businessActivity: '',
      pep: false
    },
    customerTypes: [
      {
        text: 'Private person',
        value: 1
      },
      {
        text: 'Company',
        value: 2
      }
    ]
  }),

  computed: {
    ...mapGetters('customers', ['customerName', 'isCorporateCustomer']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierList']),
    saving () {
      return this.loader
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    title () {
      return this.relatedPerson ? this.$t('titleEdit') : this.$t('titleAdd')
    },
    selectedCustomerModel: {
      get () {
        return this.selectedCustomer
      },
      async set (selected) {
        this.selectedCustomer = selected
        this.relatedPersonsData = {
          ...this.relatedPersonsData,
          ...pick(selected, Object.keys(this.relatedPersonsData)),
          customerId: selected?.id,
          customerName: customerName(selected)
        }
      }
    },
    productInterestRate () {
      return this.product.attributes?.find(({ type }) => type.code === 'INTEREST_RATE') ?? {}
    },
    productInvoiceLength () {
      return this.product.attributes?.find(({ type }) => type.code === 'INVOICE_LENGTH_IN_DAYS') ?? {}
    },
    relationTypes () {
      // const privateRelation = !(this.isCorporateCustomer(this.customer) || this.isCorporateCustomer(this.selectedCustomer))
      return Object.values(this.classifierList('relationTypes'))
        // .filter(({ onlyPrivate }) => onlyPrivate === privateRelation)
        .map(({ id, human }) => ({ text: human, value: id }))
    }
  },

  methods: {
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
      if (this.relatedPerson) {
        if (this.relatedPerson.isCompany) {
          this.customerType = 2
        }
      }
      if (this.relatedPerson) {
        this.relatedPersonsData = cloneDeep(this.relatedPerson)
        const { customerName, customerId, idCode, segmentId, sectorId } = this.relatedPerson
        this.selectedCustomerModel = {
          customerName,
          customerId: customerId,
          idCode,
          segmentId,
          sectorId
        }
      }

      // if (!this.thirdPartyData.invoiceLengthInterestRates?.length) {
      //   this.thirdPartyData.invoiceLengthInterestRates = [{
      //     invoiceLength: this.productInvoiceLength?.defaultValue,
      //     periodInterestRate: undefined,
      //     yearlyInterestRate: this.productInterestRate?.defaultValue
      //   }]
      // }
    },
    onHide ({ preventDefault }) {
      if (this.saving) {
        preventDefault()
      }
    },
    async onSubmit () {
      if (this.customerType === 2 && !this.relatedPersonsData.companyName) {
        this.isRequired = true
      } else if (this.customerType === 1 && !this.relatedPersonsData.firstName) {
        this.isRequired = true
      } else {
        const data = this.relatedPersonsData
        data.isCompany = (this.customerType === 2)
        data.relationType = String(this.relatedPersonsData.relationType ? this.relatedPersonsData.relationType : '')
        data.residenceCountry = String(this.relatedPersonsData.residenceCountry ? this.relatedPersonsData.residenceCountry : '')
        data.registrationCountry = String(this.relatedPersonsData.registrationCountry ? this.relatedPersonsData.registrationCountry : '')
        this.$emit('submit', {
          relatedPersonId: this.relatedPerson?.id,
          relatedPersons: data,
          done: () => this.$refs.modal.hide()
        })
      }
    }
    // async onCreateCustomer ({ customer }) {
    //   this.createNewCustomer = false
    //   this.selectedCustomerModel = customer
    // },
    // updateInterestRate (value, index) {
    //   if (typeof value === 'undefined') {
    //     this.relatedPersonsData.invoiceLengthInterestRates.splice(index, 1)
    //   } else {
    //     this.relatedPersonsData.invoiceLengthInterestRates.splice(index, 1, value)
    //   }
    // },
    // addInterestRate () {
    //   this.relatedPersonsData.invoiceLengthInterestRates.push({
    //     invoiceLength: this.productInvoiceLength?.defaultValue,
    //     periodInterestRate: undefined,
    //     yearlyInterestRate: this.productInterestRate?.defaultValue
    //   })
    // },
    // applyToThirdParty () {
    //   const { contractLimit: limit, advanceRate, invoiceLengthMin, invoiceLengthMax, invoiceLengthInterestRates } = this.sourceData
    //   this.relatedPersonsData = {
    //     ...this.relatedPersonsData,
    //     advanceRate,
    //     limit,
    //     invoiceLengthMax,
    //     invoiceLengthMin,
    //     ...invoiceLengthInterestRates?.length ? { invoiceLengthInterestRates: cloneDeep(invoiceLengthInterestRates) } : {}
    //   }
    // }
  }
}
</script>


<i18n>
en:
  customerType: "Customer type"
  titleAdd:     "Add related person"
  titleEdit:    "Edit related person"
  email:        "Email"
  taxId:        "Tax id(TIN)"
  country:      "Country"
  lastName:     "Last name"
  percentage:   "Percentage"
  role:         "Role"
  phone:        "Phone"
  birthdate:    "Birthdate"
  residence:    "Residence"
  idCode:       "ID code"
  firstName:    "First name"
  formDate:     "From date"
  type:         "Type"
  companyName:  "Company name"
  registrationDate: "Registration date"
  businessActivity: "Business activity"
  pep: "PEP"
  sinceWhen: "Since when"

et:
  customerType: "Customer type"
  titleAdd:     "Add related person"
  titleEdit:    "Edit related person"
  email:        "Email"
  taxId:        "Tax id(TIN)"
  country:      "Country"
  lastName:     "Last name"
  percentage:   "Percentage"
  role:         "Role"
  phone:        "Phone"
  birthdate:    "Birthdate"
  residence:    "Residence"
  idCode:       "ID code"
  firstName:    "First name"
  formDate:     "From date"
  type:         "Type"
  companyName:  "Company name"
  registrationDate: "Registration date"
  businessActivity: "Business activity"
  pep: "PEP"
  sinceWhen: "Since when"

ru:
  customerType: "Customer type"
  titleAdd:     "Add related person"
  titleEdit:    "Edit related person"
  email:        "Email"
  taxId:        "Tax id(TIN)"
  country:      "Country"
  lastName:     "Last name"
  percentage:   "Percentage"
  role:         "Role"
  phone:        "Phone"
  birthdate:    "Birthdate"
  residence:    "Residence"
  idCode:       "ID code"
  firstName:    "First name"
  formDate:     "From date"
  type:         "Type"
  companyName:  "Company name"
  registrationDate: "Registration date"
  businessActivity: "Business activity"
  pep: "PEP"
  sinceWhen: "Since when"

</i18n>
