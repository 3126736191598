<template lang="pug">
.application-offer-terms-info.card
  .card-header.title-blok-form.title-text-form {{ $t('verifiedData') }}
  .card-body.card-body--btm-border.botm
    template(v-if = 'editing')
      fi-data-field(
        :label = '$t("income")'
      )
        fi-money-input.form-control.form-control-sm.text-position-end(v-model = 'application.offerTerms.verifiedData.monthlyIncome')
      fi-data-field(
        :label = '$t("obligations")'
      )
        fi-money-input.form-control.form-control-sm.text-position-end(v-model = 'application.offerTerms.verifiedData.monthlyObligations')
      fi-data-field(
        :label = '$t("expences")'
      )
        fi-money-input.form-control.form-control-sm.text-position-end(v-model = 'application.offerTerms.verifiedData.monthlyExpenses')
      fi-data-field(
          :label = '$t("obligationsIncomeRatio")'
        )
          span(v-if = 'application.offerTerms.verifiedData.obligationIncomeRatio').text-end {{ application.offerTerms.verifiedData.obligationIncomeRatio + ' %' }}
    template(v-else)
      fi-data-field(
        :label = '$t("income")'
      )
        span.text-end {{ application.offerTerms.verifiedData.monthlyIncome | money }}
      fi-data-field(
        :label = '$t("obligations")'
      )
        span.text-end {{ application.offerTerms.verifiedData.monthlyObligations | money }}
      fi-data-field(
        :label = '$t("expences")'
      )
        span.text-end {{ application.offerTerms.verifiedData.monthlyExpenses | money }}
      fi-data-field(
          :label = '$t("obligationsIncomeRatio")'
        )
          span(v-if = 'application.offerTerms.verifiedData.obligationIncomeRatio').text-end {{ application.offerTerms.verifiedData.obligationIncomeRatio + ' %' }}

  .card-body.card-body--btm-border.botm
    fi-data-field(
      :label = '$t("maxLoanAmount")'
    )
      span.text-end {{ application.offerTerms.verifiedData.creditAmountMax | money }}
    fi-data-field(
      :label = '$t("maxPeriod")'
    )
      span.text-end {{ application.offerTerms.verifiedData.periodMax }}
    fi-data-field(
      :label = '$t("maxMonthlyPayment")'
    )
      span.text-end {{ application.offerTerms.verifiedData.monthlyPaymentMax | money }}
  .card-header.title-blok-form.title-text-form
  .card-body
    fi-data-field(
      v-if   = '!isDayPeriod'
      :label = '$t("obligationsIncomeRatio")'
    ) {{ application.offerTerms.applicationData.obligationsIncomeRatio | percent }}
    fi-data-field(:label = '$t("annualPercentageRate")') {{ application.annualPercentageRate | percent }}
    fi-data-field(:label = '$t("resolution")')
      p.p-2.border(
        v-for  = 'item in application.resolution'
        :class = '`border-${item.bootstrap.toLowerCase()}`'
      ) {{ item.message }}

</template>


<script>
import { mapGetters } from 'vuex'
import FiDataField from '@/components/FiDataField'
import FiMoneyInput from '@/components/FiMoneyInput'

export default {
  name: 'application-offer-terms-info',

  components: {
    FiDataField,
    FiMoneyInput
  },

  props: {
    application: {
      type: Object,
      required: true
    },
    editing: {
      type: Boolean,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    isDayPeriod () {
      return /DAY/i.test(this.classifierById('periodUnitType', this.application.periodUnitTypeId).code)
    }
  }

}
</script>

<style scoped lang="scss">
.title-text-form {
  font-weight: 700;
  background-color: #ffff;
  height: 49px;
  & /deep/ label {font-weight: 700;}
}
.title-blok-form {
  border-bottom: 2px solid #a4b7c1;
  border-top: 2px solid #a4b7c1;
}
.botm {
  border-bottom: none;
}

</style>

<i18n>
en:
  obligationsIncomeRatio: "Obligations Income ratio"
  annualPercentageRate:   "Cost of credit (APR)"
  resolution:             "Resolution"
  verifiedData:           "Verified data"
  income:                 "Income"
  obligations:            "Obligations"
  expences:               "Expences"
  maxLoanAmount:          "Maximum loan amount"
  maxPeriod:              "Maximum period"
  maxMonthlyPayment:      "Maximum monthly payment"
et:
  obligationsIncomeRatio: "Obligations Income ratio"
  annualPercentageRate:   "Cost of credit (APR)"
  resolution:             "Resolution"
  verifiedData:           "Verified data"
  income:                 "Income"
  obligations:            "Obligations"
  expences:               "Expences"
  maxLoanAmount:          "Maximum loan amount"
  maxPeriod:              "Maximum period"
  maxMonthlyPayment:      "Maximum monthly payment"
ru:
  obligationsIncomeRatio: "Obligations Income ratio"
  annualPercentageRate:   "Cost of credit (APR)"
  resolution:             "Resolution"
  verifiedData:           "Verified data"
  income:                 "Income"
  obligations:            "Obligations"
  expences:               "Expences"
  maxLoanAmount:          "Maximum loan amount"
  maxPeriod:              "Maximum period"
  maxMonthlyPayment:      "Maximum monthly payment"
</i18n>
