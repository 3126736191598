<template lang="pug">
.application-collateral(v-if="application")
  loan-collateral(
    store-module  = 'applications'
    assets-state  = 'applicationAssets'
    product-state = 'applicationProduct'
    :mortgage     = 'false'
    :seller       = 'seller'
  )
</template>


<script>
import LoanCollateral from '@/views/loans/LoanCollateral'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'application-collateral',

  components: { LoanCollateral },

  computed: {
    ...mapState('applications', ['application']),
    seller () {
      return this.application?.sellers?.[0] || {}
    }
  },

  created () {
    this.loadCustomer({ id: this.application.customerId })
  },

  methods: {
    ...mapActions('customers', ['loadCustomer'])
  }
}
</script>
