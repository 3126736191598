<template lang="pug">
.financial-ratios-list-item.content-column
  .cell.border-bottom.font-weight-bold  {{ financialRatio.year }}
  .cell  -
  .gray-divider
  .cell.border-bottom  {{ financialRatio.currentRation }}
  .cell.border-bottom  {{ financialRatio.quickRation }}
  .cell.border-bottom  {{ financialRatio.workingCapitalVolume }}
  .cell  {{ financialRatio.workingCapitalSales + '%' }}
  .gray-divider
  .cell.border-bottom  {{ financialRatio.inventoryTurnover }}
  .cell.border-bottom  {{ financialRatio.receivablesTurnover }}
  .cell.border-bottom  {{ financialRatio.payablesTurnover }}
  .cell  {{ financialRatio.cashConversionCycle }}
  .gray-divider
  .cell.border-bottom  {{ financialRatio.ebitda }}
  .cell.border-bottom  {{ financialRatio.debtToEbitda }}
  .cell  {{ financialRatio.debtToEbitdaWithNewLoan }}
  .gray-divider
  .cell.border-bottom  {{ financialRatio.debtToEquityRatio }}
  .cell  {{ financialRatio.leverage + '%' }}
  .gray-divider
  .cell.border-bottom  {{ financialRatio.returnOnEquity + '%' }}
  .cell.border-bottom  {{ financialRatio.returnOnAssets + '%' }}
  .cell.border-bottom  {{ financialRatio.roce + '%' }}
  .cell.border-bottom  {{ financialRatio.editdaMargin + '%' }}
  .cell  {{ financialRatio.profitMargin + '%' }}
  .gray-divider
  .cell.border-bottom  {{ financialRatio.salesGrowth + '%' }}
</template>


<script>
export default {
  name: 'financial-ratios-list-item',

  props: {
    financialRatio: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.content-column {
  display: flex;
  flex-direction: column;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
.cell {
  min-height: 22px;
  padding-inline: 15px;
  margin-inline: 15px;
}
.gray-divider {
  width: 100%;
  height: 2px;
  background-color: #a4b7c1;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, .125);
}
</style>
