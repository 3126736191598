<template lang = "pug">
fi-modal.application-send-offer-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'lg'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(
    v-if            = 'applicationProduct'
    @submit.prevent = 'onSubmit'
  )
    fi-form-field(
      :label      = '$t("language") '
      :label-cols = 2
    )
      fi-select(
        v-model.number = 'formData.languageId'
        :options       = 'languageOptions'
        sm
      )
    fi-form-field(
      :label      = ' $t("template") '
      :label-cols = 2
    )
      template(#label)
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loadingOfferTemplate')
      fi-select(
        v-model.number = 'templateId'
        :disabled      = '!applicationProduct.offerTemplateIdOverride && !!applicationProduct.offerTemplateId'
        :options       = ' optionsFromTemplates(["OFFER_EMAIL_TEMPLATE"])'
        sm
      )
    fi-form-field(
      :label     = '$t("to")'
      label-cols = 2
    )
      fi-select(
        v-model.number = 'formData.customerEmail'
        :options       = 'customerEmailOptions'
        required
        sm
      )
    fi-form-field(
      :label      = '$t("sendTo") '
      :label-cols = 2
    )
      fi-multiselect(
        v-model          = 'selectedRecipientsModel'
        :options         = 'systemCustomersForSelect'
        :search-callback = 'findSystemCustomers'
        :loading         = '$vueLoading.isLoading("loans:systemCustomers:fetch")'
        :placeholder     = '$t("common:selectBlank")'
        multiple
      )
        template(#option='{ option }') {{ option.text}}
          .small(v-if = 'option.role') {{ option.role }}
    fi-form-field(
      :label      = '$t("subject")'
      :label-cols = 2
      :state = '!$v.formData.subject.$error'
    )
      template(#error)
        .error-message(v-if = '!$v.formData.subject.required') {{ $t('common:required') }}
      template(#label)
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loadingOfferTemplate')
      input.form-control.form-control-sm.animated.fadeIn(
        v-model.trim = '$v.formData.subject.$model'
        type         = 'text'
        :disabled    = 'loadingOfferTemplate'
        :class       = '{ "is-invalid": $v.formData.subject.$error }'
      )
    fi-form-field(
      :label      = '$t("message")'
      :label-cols = 2
      :state = '!$v.formData.text.$error'
    )
      template(#error)
        .error-message(v-if = '!$v.formData.text.required') {{ $t('common:required') }}
      template(#label)
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loadingOfferTemplate')
      fi-rich-text-editor(
        v-model   = '$v.formData.text.$model'
        :disabled = 'loadingOfferTemplate'
        :class    = '{ "is-invalid": $v.formData.text.$error }'
      )
    fi-form-field.mt-1(
      :label      = '$t("attachments")'
      :label-cols = 2
      :state      = '!$v.formData.documentCategoryId.$error'
    )
      template(#error)
        .error-message(v-if = '!$v.formData.documentCategoryId.required') {{ $t('common:required') }}
      .form-row
        .col-auto
          .input-group
            .input-group-prepend
              span.input-group-text
                i.fa.fa-file-alt
            fi-select(
              v-model.number = '$v.formData.documentCategoryId.$model'
              :options       = 'categories'
              :placeholder   = '$t("category")'
              :class         = '{ "is-invalid": $v.formData.documentCategoryId.$error }'
              required
            )
        .col
          fi-file-input(
            v-model = 'formData.files'
            multiple
          )
    fi-form-field.mt-1(
      v-if        = '!workflow.isFactoring'
      :label      = '$t("infoSheet")'
      :label-cols = 2
    )
      fi-select.col-11(
        v-model.number = 'formData.infoSheetId'
        :disabled      = '!applicationProduct.informationSheetTemplateIdOverride && !!applicationProduct.informationSheetTemplateId'
        :options       = 'optionsFromTemplates(["INFO_SHEET_PDF_TEMPLATE"])'
        sm
      )
      label.col-form-label-sm.col-auto(v-if = 'formData.infoSheetId')
        button.btn.btn-link.px-0(@click.prevent = 'onInfosheetPreview')
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loadingInfosheetTemplatePreview')
          i.fa.fa-print(v-else)
    fi-form-field.mt-1(
      :label      = '$t("agreement")'
      :label-cols = 2
    )
      fi-select.col-11(
        v-model.number = 'formData.template'
        :options       = 'optionsFromTemplates(["AgreementPdfTemplate"])'
        sm
      )
      label.col-form-label-sm.col-auto(v-if = 'formData.template')
        button.btn.btn-link.px-0(@click.prevent = 'onAgreementPreview')
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loadingAgreementTemplatePreview')
          i.fa.fa-print(v-else)
  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'loadingOfferTemplate || saving'
      @click.prevent = 'onSubmit'
    ) {{ $t('send') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(
      :disabled      = 'loadingOfferTemplate || saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t('common:cancel') }}
</template>


<script>
import FiFileInput from '@/components/FiFileInput'
import FiFormField from '@/components/FiFormField'
import FiModal from '@/components/FiModal'
import FiRichTextEditor from '@/components/FiRichTextEditor'
import FiSelect from '@/components/FiSelect'
import { rules, validation, validators } from '@/validation'
import Template from '@/views/admin/templates/Template'
import { mapActions, mapGetters, mapState } from 'vuex'
import FiMultiselect from '@/components/FiMultiselect'
import WorkflowPolicy from '@/policies/WorkflowPolicy'
import { findCustomerMainContact } from '@/helpers'

export default {
  name: 'application-send-offer-modal',

  components: {
    FiMultiselect,
    Template,
    FiRichTextEditor,
    FiFileInput,
    FiFormField,
    FiSelect,
    FiModal
  },

  mixins: [validation],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    workflow: {
      type: WorkflowPolicy,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      selectedRecipients: [],
      templateId: '',
      formData: {
        languageId: '',
        subject: '',
        text: '',
        infoSheetId: '',
        files: [],
        documentCategoryId: '',
        recipients: null,
        template: '',
        customerEmail: null
      }
    }
  },

  computed: {
    ...mapState('applications', ['application', 'applicationProduct', 'applicationCustomer', 'applicationOfferTemplate']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierByName', 'productTypeById', 'classifierList']),
    ...mapGetters('templates', ['optionsFromTemplates']),
    ...mapGetters('applications', ['systemCustomersForSelect']),
    ...mapGetters('settings', ['settingByKey']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    loadingOfferTemplate () {
      return this.$vueLoading.isLoading('application:offerTemplate:load')
    },
    saving () {
      return this.$vueLoading.isLoading('application:offer:save')
    },
    loadingInfosheetTemplatePreview () {
      return this.$vueLoading.isLoading(`template:${this.formData.infoSheetId}:preview:fetch`)
    },
    loadingAgreementTemplatePreview () {
      return this.$vueLoading.isLoading(`template:${this.formData.template}:preview:fetch`)
    },
    categories () {
      return this.optionsFromClassifier('DocumentCategoryType')
    },
    selectedRecipientsModel: {
      get () {
        return this.selectedRecipients
      },
      set (selected) {
        this.selectedRecipients = selected
        this.formData.recipients = selected.map(item => item.value) || []
      }
    },
    customerEmailOptions () {
      return this.applicationCustomer?.emails.map(({ value }) => value)
    },
    languageOptions () {
      return Object.values(this.classifierList('languages'))
        .filter(lang => this.settingByKey('supportedTemplateLanguages').includes(lang.code))
        .map(({ human, id }) => ({ text: human, value: id }))
    }
  },

  watch: {
    async templateId (value) {
      if (value) await this.loadOfferTemplate({ templateId: value, objectId: this.application.id })
      this.onTemplateChange()
    },
    'formData.languageId' () {
      this.onTemplateChange()
    }
  },

  validations: {
    formData: {
      customerEmail: {},
      subject: rules.applicationRules.subject,
      text: rules.applicationRules.text,
      documentCategoryId: {
        required: validators.requiredIf(function () {
          return this.formData.files.length > 0
        })
      }
    }
  },

  methods: {
    ...mapActions('applications', ['sendOffer', 'loadOfferTemplate', 'findSystemCustomers']),
    ...mapActions('templates', ['loadTemplatePreview']),
    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
      this.$nextTick(() => {
        if (this.applicationCustomer && this.applicationCustomer.languageId) {
          this.formData.languageId = this.applicationCustomer.languageId
        } else {
          this.formData.languageId = this.classifierByName('languages', this.settingByKey('language'))?.id
        }
        this.formData.customerEmail = findCustomerMainContact(this.applicationCustomer?.emails)
        if (this.applicationProduct.offerTemplateId) this.templateId = this.applicationProduct.offerTemplateId
        if (this.applicationProduct.informationSheetTemplateId) this.formData.infoSheetId = this.applicationProduct.informationSheetTemplateId
        if (this.applicationProduct.agreementTemplateId) this.formData.template = this.applicationProduct.agreementTemplateId

        this.formData.documentCategoryId = this.classifierByName('DocumentCategoryType', 'OTHER')?.id
      })
      this.$v.$reset()
    },
    onHide (e) {
      if (this.saving) e.preventDefault()
    },
    onTemplateChange () {
      let content
      if (this.applicationOfferTemplate && this.applicationOfferTemplate.id === this.templateId) content = this.applicationOfferTemplate.content[this.formData.languageId]
      if (content) {
        this.formData.subject = content.subject
        this.formData.text = content.content
      } else {
        this.formData.subject = ''
        this.formData.text = ''
      }
    },
    async onSubmit () {
      if (this.validate()) {
        await this.sendOffer({ formData: this.formData })
        this.$refs.modal.hide()
      }
    },
    onInfosheetPreview () {
      this.loadTemplatePreview({
        id: this.formData.infoSheetId,
        applicationId: this.application.id,
        languageId: this.formData.languageId
      })
    },
    onAgreementPreview () {
      this.loadTemplatePreview({
        id: this.formData.template,
        applicationId: this.application.id,
        languageId: this.formData.languageId
      })
    }
  }
}
</script>


<i18n>
en:
  title:       "Send offer"
  language:    "Language"
  template:    "Template"
  to:          "To"
  subject:     "Subject"
  sendTo:      "CC"
  message:     "Message"
  infoSheet:   "Info sheet"
  preview:     "Preview"
  attachments: "Attachments"
  send:        "Send"
  category:    "Category"
  agreement:   "Agreement"
et:
  title:       "Send offer"
  language:    "Language"
  template:    "Template"
  to:          "To"
  subject:     "Subject"
  sendTo:      "CC"
  message:     "Message"
  infoSheet:   "Info sheet"
  preview:     "Preview"
  attachments: "Attachments"
  send:        "Send"
  category:    "Category"
  agreement:   "Agreement"
ru:
  title:       "Send offer"
  language:    "Language"
  template:    "Template"
  to:          "To"
  subject:     "Subject"
  sendTo:      "CC"
  message:     "Message"
  infoSheet:   "Info sheet"
  preview:     "Preview"
  attachments: "Attachments"
  send:        "Send"
  category:    "Category"
  agreement:   "Agreement"
</i18n>
