<template lang="pug">
.application-purchase-info.card
  .card-header {{ $t('title') }}
  .card-body
    form.form-horizontal(@submit.prevent = 'onSubmit')
      application-purchase-info-form-fields(
        :editing          = 'editing'
        :application-data = '$v.applicationData'
        :application      = 'application'
      )
      template(v-if = 'editing')
        button.btn.btn-primary(
          type      = 'submit'
          :disabled = 'saving || $v.$anyError'
        ) {{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'toggleEdit'
        ) {{ $t('common:cancel') }}
      button.btn.btn-primary(
        v-else-if      = 'isEditable'
        @click.prevent = 'toggleEdit'
      ) {{ $t('common:edit') }}
</template>


<script>
import pick from 'lodash/pick'
import { mapState, mapActions } from 'vuex'
import ApplicationPurchaseInfoFormFields from './ApplicationPurchaseInfoFormFields'
import { rules, validation } from '@/validation'

export default {
  name: 'application-purchase-info',

  components: { ApplicationPurchaseInfoFormFields },

  mixins: [validation],

  props: {
    isEditable: {
      type: Boolean,
      default: false
    }
  },

  i18nOptions: {},

  data () {
    return {
      editing: false,
      applicationData: {
        purchasedGood: null,
        purchasedGoodCost: null
      }
    }
  },

  computed: {
    ...mapState('applications', ['application']),
    saving () {
      return this.$vueLoading.isLoading(`application:${this.application.id}:save`)
    }
  },

  validations () {
    return {
      applicationData: pick(rules.applicationRules, Object.keys(this.applicationData))
    }
  },

  methods: {
    ...mapActions('applications', ['updateApplication']),
    toggleEdit () {
      if (!this.editing) this.resetData()
      this.editing = !this.editing
    },
    resetData () {
      this.$v.$reset()
      this.applicationData = pick(this.application, Object.keys(this.applicationData))
    },
    async onSubmit () {
      if (this.validate()) {
        await this.updateApplication({ id: this.application.id, applicationData: this.applicationData })
        this.editing = false
      }
    }
  }
}
</script>


<i18n>
en:
  title: "Hire purchase info"

et:
  title: "Hire purchase info"

ru:
  title: "Hire purchase info"
</i18n>
