<template lang='pug'>
.application-seller-info.card
  .card-header {{ $t('title') }}
  .card-body
    .row
      .col-6
        fi-data-field(:label = '$t("name")') {{ seller.customerName }}
        fi-data-field(:label = '$t("idCode")') {{ seller.idCode }}
        fi-data-field(:label = '$t("sector")') {{ classifierById('sectors', seller.sectorId).human }}
      .col-6
        fi-data-field(:label = '$t("phone")') {{ seller.phoneNumber }}
        fi-data-field(:label = '$t("email")') {{ seller.email }}
        fi-data-field(:label = '$t("address")') {{ fullAddress }}

</template>


<script>
import FiDataField from '@/components/FiDataField'
import { mapGetters } from 'vuex'

export default {
  name: 'application-seller-info',

  components: { FiDataField },

  props: {
    seller: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    fullAddress () {
      const { address, city, countryId } = this.seller
      return [address, city, this.classifierById('countries', countryId).human].filter(Boolean).join(', ')
    }
  }
}
</script>


<i18n>
en:
  title:   "Seller info"
  name:    "Name"
  idCode:  "ID Code"
  sector:  "Sector"
  phone:   "Phone"
  email:   "Email"
  address: "Address"
et:
  title:   "Seller info"
  name:    "Name"
  idCode:  "ID Code"
  sector:  "Sector"
  phone:   "Phone"
  email:   "Email"
  address: "Address"
ru:
  title:   "Seller info"
  name:    "Name"
  idCode:  "ID Code"
  sector:  "Sector"
  phone:   "Phone"
  email:   "Email"
  address: "Address"
</i18n>
