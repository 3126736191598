<template lang="pug">
fi-modal.loan-schedule-interest-period-new-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = 'title'
  size    = 'lg'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(
      :label     = '$t("existingCustomer")'
      label-cols = 3
    )
      fi-customer-select(
        v-model      = 'selectedCustomerModel'
        :placeholder = '$t("selectCustomer")'
      )
    .form-group
      .col-sm-9.offset-sm-3
        .my-2 - {{ $t('or') }} -
        customer-new-brief(
          v-if    = 'createNewCustomer'
          @cancel = 'createNewCustomer = false'
          @submit = 'onCreateCustomer'
        )
        button.btn.btn-link.px-0(
          v-else
          @click.prevent = 'createNewCustomer = true'
        ) {{ $t('createCustomer') }}

    fi-form-field(
      :label     = '$t("advanceRate")'
      label-cols = 3
    )
      percent-input.form-control-sm(v-model = 'thirdPartyData.advanceRate')
    fi-form-field(
      :label     = '$t("contractLimit")'
      label-cols = 3
    )
      fi-money-input.form-control-sm(v-model = 'thirdPartyData.limit')
    fi-form-field(
      :label     = '$t("invoiceLength")'
      label-cols = 3
    )
      .row
        .input-group.col.input-group-sm
          .input-group-prepend
            span.input-group-text {{ $t('common:min') }}
          input.form-control.form-control-sm.animated.fadeIn(
            type           = 'text'
            v-model.number = 'thirdPartyData.invoiceLengthMin'
          )
        .input-group.col.input-group-sm
          .input-group-prepend
            span.input-group-text {{ $t('common:max') }}
          input.form-control.form-control-sm.animated.fadeIn(
            type           = 'text'
            v-model.number = 'thirdPartyData.invoiceLengthMax'
          )
    fi-form-field(
      :label     = '$t("interestRate")'
      label-cols = 3
    )
      application-factoring-interest-rate-item(
        v-for                 = '(interestRate, index) in thirdPartyData.invoiceLengthInterestRates'
        :key                  = 'index'
        :interest-rate        = 'interestRate'
        :day-count-convention = 'productInterestRate.dayCountConvention'
        @input                = 'updateInterestRate($event, index)'
      )
        .col-2.px-1
          button.btn.btn-danger.btn-sm(
            v-if           = 'index'
            @click.prevent = 'updateInterestRate(undefined, index)'
          ): i.far.fa-trash-alt.fa-lg
          button.btn.btn-primary.btn-sm(
            v-else
            @click.prevent = 'addInterestRate'
          ): i.far.fa-plus-square.fa-lg
  template(#modal-footer)
    button.btn.btn-warning(@click = 'applyToThirdParty') {{ $t('applyFromApplication') }}
    button.btn.btn-primary(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>


<script>
import FiModal from '@/components/FiModal'
import FiFormField from '@/components/FiFormField'
import FiMultiselect from '@/components/FiMultiselect'
import CustomerNewBrief from '@/views/customers/CustomerNewBrief'
import PercentInput from '@/components/PercentInput'
import FiMoneyInput from '@/components/FiMoneyInput'
import ApplicationFactoringInterestRateItem
  from '@/views/applications/ApplicationFactoring/ApplicationFactoringInterestRateItem'
import pick from 'lodash/pick'
import cloneDeep from 'lodash/cloneDeep'
import FiCustomerSelect from '@/components/FiCustomerSelect'
import { customerName } from '@/helpers'

export default {
  name: 'application-factoring-third-party-modal',

  components: { FiCustomerSelect, ApplicationFactoringInterestRateItem, FiMoneyInput, PercentInput, CustomerNewBrief, FiMultiselect, FiFormField, FiModal },

  props: {
    loader: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    thirdParty: {
      type: Object,
      default: null
    },
    product: {
      type: Object,
      required: true
    },
    sourceData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    createNewCustomer: false,
    selectedCustomer: null,
    thirdPartyData: {
      advanceRate: null,
      customerId: '',
      customerName: null,
      idCode: null,
      invoiceLengthInterestRates: [],
      invoiceLengthMax: null,
      invoiceLengthMin: null,
      limit: null,
      segmentId: '',
      sectorId: ''
    }
  }),

  computed: {
    saving () {
      return this.loader
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    title () {
      return this.thirdParty ? this.$t('titleEdit') : this.$t('titleAdd')
    },
    selectedCustomerModel: {
      get () {
        return this.selectedCustomer
      },
      async set (selected) {
        this.selectedCustomer = selected
        this.thirdPartyData = {
          ...this.thirdPartyData,
          ...pick(selected, Object.keys(this.thirdPartyData)),
          customerId: selected?.id,
          customerName: customerName(selected)
        }
      }
    },
    productInterestRate () {
      return this.product.attributes?.find(({ type }) => type.code === 'INTEREST_RATE') ?? {}
    },
    productInvoiceLength () {
      return this.product.attributes?.find(({ type }) => type.code === 'INVOICE_LENGTH_IN_DAYS') ?? {}
    }
  },

  methods: {
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
      if (this.thirdParty) {
        this.thirdPartyData = cloneDeep(this.thirdParty)
        const { customerName, customerId, idCode, segmentId, sectorId } = this.thirdParty
        this.selectedCustomerModel = {
          customerName,
          id: customerId,
          idCode,
          segmentId,
          sectorId
        }
      }

      if (!this.thirdPartyData.invoiceLengthInterestRates?.length) {
        this.thirdPartyData.invoiceLengthInterestRates = [{
          invoiceLength: this.productInvoiceLength?.defaultValue,
          periodInterestRate: undefined,
          yearlyInterestRate: this.productInterestRate?.defaultValue
        }]
      }
    },
    onHide ({ preventDefault }) {
      if (this.saving) {
        preventDefault()
      }
    },
    async onSubmit () {
      this.$emit('submit', {
        thirdPartyId: this.thirdParty?.id,
        thirdParty: this.thirdPartyData,
        done: () => this.$refs.modal.hide()
      })
    },
    async onCreateCustomer ({ customer }) {
      this.createNewCustomer = false
      this.selectedCustomerModel = customer
    },
    updateInterestRate (value, index) {
      if (typeof value === 'undefined') {
        this.thirdPartyData.invoiceLengthInterestRates.splice(index, 1)
      } else {
        this.thirdPartyData.invoiceLengthInterestRates.splice(index, 1, value)
      }
    },
    addInterestRate () {
      this.thirdPartyData.invoiceLengthInterestRates.push({
        invoiceLength: this.productInvoiceLength?.defaultValue,
        periodInterestRate: undefined,
        yearlyInterestRate: this.productInterestRate?.defaultValue
      })
    },
    applyToThirdParty () {
      const { contractLimit: limit, advanceRate, invoiceLengthMin, invoiceLengthMax, invoiceLengthInterestRates } = this.sourceData
      this.thirdPartyData = {
        ...this.thirdPartyData,
        advanceRate,
        limit,
        invoiceLengthMax,
        invoiceLengthMin,
        ...invoiceLengthInterestRates?.length ? { invoiceLengthInterestRates: cloneDeep(invoiceLengthInterestRates) } : {}
      }
    }
  }
}
</script>


<i18n>
en:
  titleAdd:             "Add third party"
  titleEdit:            "Edit third party"
  existingCustomer:     "Existing customer"
  selectCustomer:       "Select customer"
  createCustomer:       "Create customer"
  contractLimit:        "Limit"
  advanceRate:          "Advance rate"
  invoiceLength:        "Invoice length"
  interestRate:         "Interest rate"
  days:                 "days"
  yearly:               "yearly"
  applyFromApplication: "Apply from application data"
et:
  titleAdd:             "Add third party"
  titleEdit:            "Edit third party"
  existingCustomer:     "Existing customer"
  selectCustomer:       "Select customer"
  createCustomer:       "Create customer"
  contractLimit:        "Limit"
  advanceRate:          "Advance rate"
  invoiceLength:        "Invoice length"
  interestRate:         "Interest rate"
  days:                 "days"
  yearly:               "yearly"
  applyFromApplication: "Apply from application data"
ru:
  titleAdd:             "Add third party"
  titleEdit:            "Edit third party"
  existingCustomer:     "Existing customer"
  selectCustomer:       "Select customer"
  createCustomer:       "Create customer"
  contractLimit:        "Limit"
  advanceRate:          "Advance rate"
  invoiceLength:        "Invoice length"
  interestRate:         "Interest rate"
  days:                 "days"
  yearly:               "yearly"
  applyFromApplication: "Apply from application data"
</i18n>
