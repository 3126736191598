import { Expose } from 'class-transformer'

export class CThirdParty {
  @Expose()
  advanceRate: number;

  @Expose()
  customerId: number;

  @Expose()
  customerName: string;

  @Expose()
  id: number;

  @Expose()
  idCode: string;

  @Expose()
  invoiceLengthInterestRates: number

  @Expose()
  invoiceLengthMax: number

  @Expose()
  invoiceLengthMin: number

  @Expose()
  limit: number
}
