export default class WorkflowPolicy {
  constructor (productType = {}) {
    this.productType = productType
  }

  get groups () {
    const { privateCustomer, corporateCustomer, productType } = this
    return [
      privateCustomer && 'private', corporateCustomer && 'corporate', productType?.group?.toLowerCase()
    ].filter(Boolean)
  }

  get privateCustomer () {
    return this.productType?.privateCustomer
  }

  get corporateCustomer () {
    return this.productType?.corporateCustomer
  }

  get productIds () {
    return this.productType?.productIds
  }

  get isOverdraft () {
    return /overdraft|guarantee/i.test(this.productType?.code)
  }

  get isLeasing () {
    return /leasing/i.test(this.productType?.code)
  }

  get isFactoring () {
    return /factoring/i.test(this.productType?.code)
  }

  get allowNewCustomer () {
    return true
  }
}
