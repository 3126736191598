<template lang="pug">
.application-related-persons-list.card
  .card-header {{ $t('title') }}
    button.btn.btn-link.fa-pull-right.py-0.animated.fadeIn(
      :title         = '$t("common:add")'
      @click.prevent = 'showAddThirdParty = true'
    ): i.fas.fa-user-plus.fa-lg
  .card-body
    fi-table(
      :fields      = 'fields'
      :items       = 'relatedPersonsList'
      :empty-label = '$t("notFound")'
    )
      template(#default = '{ items }')
        application-related-persons-list-item(
          v-for        = '(item, index) in items'
          :key         = 'index'
          :product     = 'product'
          :application = 'sourceData'
          :related-person = 'item'
          @edit        = '$emit("edit", { ...$event, index })'
          @remove      = '$emit("remove", { ...$event, index })'
        )
  application-related-persons-modal(
    v-model      = 'showAddThirdParty'
    :product     = 'product'
    :source-data = 'sourceData'
    :loader      = '$vueLoading.isLoading(`factoring:application:thirdParty:save`)'
    @submit      = '$emit("create", $event)'
  )
</template>


<script>
import FiTable from '@/components/FiTable'
import ApplicationRelatedPersonsModal
  from '@/views/applications/ApplicationFactoring/ApplicationRelatedPersonsModal'
import ApplicationRelatedPersonsListItem
  from '@/views/applications/ApplicationFactoring/ApplicationRelatedPersonsListItem'

export default {
  name: 'application-related-persons-list',

  components: { ApplicationRelatedPersonsListItem, ApplicationRelatedPersonsModal, FiTable },

  props: {
    relatedPersonsList: {
      type: Array,
      default: () => []
    },
    sourceData: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    showAddThirdParty: false
  }),

  computed: {
    fields () {
      return [
        {
          key: 'type',
          label: this.$t('type')
        },
        {
          key: 'role',
          label: this.$t('role')
        },
        {
          key: 'name',
          label: this.$t('name')
        },
        {
          key: 'idCode',
          label: this.$t('idCode')
        },
        {
          key: 'country',
          label: this.$t('country')
        },
        {
          key: 'phone',
          label: this.$t('phone')
        },
        {
          key: 'email',
          label: this.$t('email')
        },
        {
          key: 'sinceWhen',
          label: this.$t('sinceWhen')
        },
        { class: 'w-28' }
      ]
    }
  }
}
</script>


<i18n>
en:
  title:         "Related persons"
  customerName:  "Customer name"
  limit:         "Limit"
  advanceRate:   "Advance rate"
  invoiceLength: "Invoice length (days)"
  interestRate:  "Interest rate"
  notFound:      "No related persons"
  type:          "Type"
  role:          "Role"
  name:          "Name"
  idCode:        "ID code"
  country:       "Country"
  phone:         "Phone"
  email:         "E-mail"
  sinceWhen: "Since when"
et:
  title:         "Related persons"
  customerName:  "Customer name"
  limit:         "Limit"
  advanceRate:   "Advance rate"
  invoiceLength: "Invoice length (days)"
  interestRate:  "Interest rate"
  notFound:      "No related persons"
  sinceWhen: "Since when"
ru:
  title:         "Related persons"
  customerName:  "Customer name"
  limit:         "Limit"
  advanceRate:   "Advance rate"
  invoiceLength: "Invoice length (days)"
  interestRate:  "Interest rate"
  notFound:      "No related persons"
  sinceWhen: "Since when"
</i18n>
