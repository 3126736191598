import { Expose } from 'class-transformer'

export class CAddress {
  @Expose()
  countryId: number

  @Expose()
  countyId: number

  @Expose()
  city: string

  @Expose()
  fullAddress: string

  @Expose()
  postalCode: string

  @Expose()
  isDefault: boolean = true
}
