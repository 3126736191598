<template lang="pug">
.application-offer-terms.card.border-0.border-bottom
  .card-header.border.border-bottom-0 {{ $t('title') }}
    button.btn.btn-link.py-0.fa-pull-right.animated.fadeIn(
      v-if           = 'isEditable && !editing'
      :title         = '$t("common:edit")'
      @click.prevent = 'toggleEditing()'
    ): i.far.fa-edit.fa-lg
  .card-group
    slot(
      :editing = 'editing'
      :toggleEditing = 'toggleEditing'
    )
</template>


<script>
export default {
  name: 'application-offer-terms',

  props: {
    isEditable: {
      type: Boolean,
      default: false
    }
  },

  i18nOptions: {},

  data: () => ({
    editing: false
  }),

  methods: {
    toggleEditing () {
      this.editing = !this.editing
    }
  }
}
</script>


<i18n>
en:
  title: "Offer terms"
et:
  title: "Offer terms"
ru:
  title: "Offer terms"
</i18n>
