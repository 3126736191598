import { CClassifier } from '@/models/CClassifier'
import { Expose, Type } from 'class-transformer'

export class CApplicationPrivateCustomer {
  @Expose()
  acceptPensionAccount: boolean

  @Expose()
  @Type(() => CClassifier)
  dependant: CClassifier

  @Expose()
  dependantNumber: number

  @Expose()
  deskPhoneNumber: string

  @Expose()
  @Type(() => CClassifier)
  educationType: CClassifier

  @Expose()
  email: string

  @Expose()
  employmentLength: number

  @Expose()
  @Type(() => CClassifier)
  employmentType: CClassifier

  @Expose()
  firstName: string

  @Expose()
  idCode: string

  @Expose()
  idDocId: number

  @Expose()
  idDocNumber: string

  @Expose()
  lastName: string

  @Expose()
  maritalStatusId: number

  @Expose()
  monthlyIncome: number

  @Expose()
  monthlyObligations: number

  @Expose()
  @Type(() => CClassifier)
  netSalary: CClassifier

  @Expose()
  occupation: string

  @Expose()
  phoneNumber: string

  @Expose()
  @Type(() => CClassifier)
  residenceTypeId: CClassifier

  @Expose()
  @Type(() => CClassifier)
  sourceOfIncome: CClassifier

  @Expose({ toClassOnly: true })
  customerId: number;

  @Expose({ toClassOnly: true })
  sectorId: number;

  @Expose({ toClassOnly: true })
  segmentId: number;

  @Expose({ toClassOnly: true })
  countryId: number;

  @Expose({ toClassOnly: true })
  countyId: number;

  @Expose({ toClassOnly: true })
  residenceCountryId: number;

  @Expose({ toClassOnly: true })
  city: string;

  @Expose({ toClassOnly: true })
  fullAddress: string;

  @Expose({ toClassOnly: true })
  postalCode: string;

  @Expose({ toClassOnly: true })
  accountExternalId: string;
}
